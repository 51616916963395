const date = new Date();
date.setDate(date.getDate() - 30);

const initialState = {
  currentCases: {
    caseData: []
  },
  archivedCases: {
    caseData: []
  },
  coverageAreasCases: [],
  currentCasesPaging: {
    pageCount: 1,
    totalCasesCount: 0,
    currentPage: 1
  },
  archivedCasesPaging: {
    pageCount: 1,
    totalCasesCount: 0,
    currentPage: 1
  },
  caseDetails: null,
  credentials: {},
  user: null,
  login: false,
  //displayedCases:[],
  logotype: null,
  mapTiles: [],
  mapMarkers: [],
  polygonArea: null,
  mapSettings: null,
  archivedCasesFilter: {
    caseTypeFilter: 'Ärendetyp', 
    startDateFilter: date, 
    endDateFilter: new Date(), 
    searchString: '', 
    sortDirection: 'Sortering'
  },
  currentCasesFilter: {
    caseTypeFilter: 'Ärendetyp', 
    searchString: '', 
    sortDirection: 'Sortering'
  },
  loading: false,
  mapLoading: false,
  status: {
    eventFeed: []
  }
};

export default initialState;