import React from 'react';
import Navbar from './components/navbar/navbar';
import Main from './components/main/main';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';


function App() {
  return (
    <div className="App">
      <Navbar />
      <Main />
      <ToastContainer 
				position="top-right"
				autoClose={5000} 
				hideProgressBar={true}
				newestOnTop={true}
				closeOnClick={true}
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover={true}
				transition={Slide}
			/>
    </div>
  );
}

export default App;