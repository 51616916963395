import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Container, Row } from 'reactstrap';
import * as coverageAreasCasesActions from '../../redux/actions/coverageareascasesactions';
//import MapContainer from '../../components/map/mapcontainer/mapcontainer';
import SideInfo from './sideinfo/sideinfo';
import './coverageareas.scss';
// import { markerCaseTypes } from '../../assets/icons/icons';
// import { addCasePolygonGroup } from '../../components/map/geojson/geojson';
// import { geoJsonStyleTypes } from '../../assets/geojsonstyles';
import ScrollableLeftPanel from '../../components/common/scrollableleftpanel';


function CoverageAreas(props) {
  const [searchValue, setSearchValue] = useState('');
  const [caseTypeValue, setCaseTypeValue] = useState('Ärendetyp');
  const placeholder = 'Sök bland bevakningsområden...';

  useEffect(() => {
    const { actions, coverageAreasCases } = props;
    if (searchValue !== undefined && searchValue !== '') {
      
      actions.searchInCoverageAreasCases(searchValue).catch(error => {
        console.log(error);
        toast.error('Sökningen misslyckades.');
      })
    }

		if (caseTypeValue !== undefined  && caseTypeValue.toLowerCase() !== "ärendetyp"  && caseTypeValue !== '') {
			
			actions.filterCoverageAreasCases(caseTypeValue).catch(error => {
				console.error(error);
				toast.error("Filtreringen misslyckades.")
			})
		}
    else if (coverageAreasCases.length === 0) {
      actions.loadCoverageAreasCases().catch(error => {
        console.log(error);
        toast.error('Det gick inte att hämta bevakningsärenden.');
      });
    }
  }, [caseTypeValue, props, searchValue, setCaseTypeValue]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  }

  const handleTypeChange = (event) => {
    setCaseTypeValue(event.target.innerText);
  }
  // 
  return (
    <Container fluid>
      <Row>
        <ScrollableLeftPanel>
          <SideInfo
            coverageAreasCases={props.coverageAreasCases}
            handleSearchChange={handleSearchChange}
            placeholder={placeholder}
            searchValue={searchValue}
            CaseTypeValue={caseTypeValue}
            handleTypeChange={handleTypeChange}
          />
        </ScrollableLeftPanel>
        {/* <MapContainer markers={bicycleRental} /> */}
      </Row>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    coverageAreasCases: state.coverageAreasCases
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadCoverageAreasCases: bindActionCreators(coverageAreasCasesActions.loadCoverageAreasCases, dispatch),
      filterCoverageAreasCases: bindActionCreators(coverageAreasCasesActions.filterCoverageAreasCases, dispatch),
      searchInCoverageAreasCases: bindActionCreators(coverageAreasCasesActions.searchInCoverageAreasCases, dispatch),
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoverageAreas);
