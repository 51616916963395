import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import './casecard.scss'


function CaseCard(props) {
  const [archived, setArchived] = useState(false);
  let navigate = useNavigate();

  function onClick() {
    navigate({pathname: '/arenden/' + props.Case.number, archived});
  }

  useEffect(()=>{
    if (props.Case.archived) {
      setArchived(props.Case.archived);
    }
  }, []);

  return (
    <div className={`case-card-wrapper case-type-${props.Case.caseType}`} onClick={onClick}>

      <div className="case-card-item-wrapper">
        <span>Ärendenummer</span>
        <span>{props.Case.number}</span>
        <span>{props.Case.creationDate}</span>
      </div>

      <div className="case-card-item-wrapper">
        <span>Ärendetyp</span>
        <span>{props.Case.caseType}</span>
        <span>{props.Case.region}</span>
      </div>

      <div className={`case-card-item-wrapper ${!archived && "case-card-wide-item-wrapper"}`} >
        <span>Beställare</span>
        <span>{props.Case.inquirer}</span>
        <span></span>
      </div>

      {archived && (
        <div className="case-card-item-wrapper">
          <span>Åtgärd i Ledningskollen</span>
          <span>{props.Case.answerDescription}</span>
          <span>{props.Case.answerTime ? props.Case.answerTime : ""}</span>
        </div>
      )}

    </div>
  )
}

export default CaseCard