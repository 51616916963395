import React, { Fragment, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import TextInput from "components/common/textinput";
import SpinnerButton from "components/common/spinnerbutton";
import * as adminActions from "redux/actions/adminactions";

function LoginLK(props) {
  const defaultPassword = "********";
  const [cableOwner, setCableOwner] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [savingSettings, setSavingSettings] = useState(false);
  const { actions } = props;

  useEffect(() => {
    if (props.ledningskollenSettings) {
      setCableOwner(props.ledningskollenSettings.cableOwner);
      setUsername(props.ledningskollenSettings.ledningskollenUsername);
      setPassword(props.ledningskollenSettings.ledningskollenPassword.length > 0 ? defaultPassword : "");
      setSavingSettings(false);
    }
  }, [props.ledningskollenSettings]);

  function onCableOwnerChange(event) {
    setCableOwner(event.target.value);
  }

  function onUsernameChange(event) {
    setUsername(event.target.value);
  }

  function onPasswordChange(event) {
    setPassword(event.target.value);
  }

  const onPasswordFieldFocus = (event) => {
    event.target.value = "";
  };

  const onPasswordFieldFocusOut = (event) => {
    if (event.target.value === "") {
      event.target.value = password;
    }
  };

  function handleSaveClick() {
    setSavingSettings(true);
    let ledningskollenSettings = { ledningskollenSettings: props.ledningskollenSettings };
    var updatedSettings = JSON.parse(JSON.stringify(ledningskollenSettings));
    updatedSettings.ledningskollenSettings.cableOwner = cableOwner;
    updatedSettings.ledningskollenSettings.ledningskollenUsername = username;
    updatedSettings.ledningskollenSettings.ledningskollenPassword =
      password !== defaultPassword ? password : props.ledningskollenSettings.ledningskollenPassword;

    actions
      .saveSettings(updatedSettings)
      .then(function () {
        toast.success("Inställningarna har sparats.");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Det gick inte att spara inställningarna.");
      })
      .then(() => setSavingSettings(false));
  }

  if (props.ledningskollenSettings) {
    return (
      <Fragment>
        <h4 className="settings-title">Inloggning Ledningskollen</h4>

        <TextInput
          name="cableOwner"
          label="Ledningsägare"
          value={cableOwner}
          onChange={onCableOwnerChange}
          placeholder={""}
          error={""}
          wrapperClass="settings-label"
        />

        <TextInput
          name="username"
          label="Användarnamn"
          value={username}
          onChange={onUsernameChange}
          placeholder={""}
          error={""}
          wrapperClass="settings-label"
        />

        <TextInput
          inputType="password"
          name="password"
          label="Lösenord"
          value={password}
          onChange={onPasswordChange}
          onClick={onPasswordFieldFocus}
          onBlur={onPasswordFieldFocusOut}
          placeholder={""}
          error={""}
          wrapperClass="settings-label"
        />

        <SpinnerButton onClick={handleSaveClick} loading={savingSettings} buttonClassName="button-primary-large">
          Spara
        </SpinnerButton>
      </Fragment>
    );
  } else {
    return <span>Det gick inte att hämta inställningar.</span>;
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveSettings: bindActionCreators(adminActions.saveSettings, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginLK);
