import React from 'react';
//import { answerTypes } from '../../../../../assets/enums';
import './actselector.scss'


// const getNextButtonText = (caseModel, selectedMeasure) => {
//   caseModel.availableMeasures.forEach(am => {
//     if(am.id = selectedMeasure) {
//       return am.sendPdf || am.sendGisFile ? "Nästa" : "Ok"
//     }
//   });
// }

export default function ActSelector(props) {
  return (
    <div className='case-acts-selector-wrapper'>
      <div className='case-acts-selector-content'>
        <span className="case-acts-selector-label">Åtgärd i Ledningskollen</span>
        {props.Case.availableMeasures.map(am => 
        <div key={am.id}>
          <label className="case-acts-selector-item" >{am.shortDescription}
            <input
              type="radio"
              name="measure"
              value={am.id}
              checked={props.measure === am.id}
              onChange={props.handleMeasureChanged}
            />
            <span className="case-acts-selector-item-checkmark"></span>
          </label>
        </div>)}
        <span className="case-acts-selector-label">Kommentar</span>
        <textarea
          value={props.comment}
          onChange={props.handleCommentChange}
        />
        <div className='case-acts-selector-buttons-wrapper'>
          <button className='button-secondary button-row-margin'
            onClick={props.handleActSelectorCancelClick}>
            Avbryt
          </button>
          <button className='button-primary button-row-margin'
            onClick={props.handleActSelectorNextClick}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
}