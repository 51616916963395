import { baseGetAll, basePost, basePostFormData, baseGet, baseGetFile, baseGetCsvFile } from "./apiutils";

/*
 ***API Requests for Files on cases
 */

export async function getPdf(id) {
  const response = await baseGetFile(id, "/case/", "/pdf", "", "application/pdf");
  return Promise.resolve(response);
}

export async function getDxf(id) {
  const response = await baseGetFile(id, "/case/", "/dxf");
  return Promise.resolve(response);
}

export async function getArchivedFile(id, caseMaterialEmailId, archivedFileName) {
  const queryString = `?caseMaterialEmailId=${caseMaterialEmailId}&fileName=${encodeURIComponent(archivedFileName)}`;
  const response = await baseGetFile(id, "/case/archived/", "", queryString);
  return Promise.resolve(response);
}

export async function getAttachment(id, fileName) {
  const response = await baseGetFile(id, "/case/", "/attachment/" + encodeURIComponent(fileName));
  return Promise.resolve(response);
}

/*
 ***API Requests for Cases
 */

export async function getCaseDetails(id) {
  const response = await baseGet(id, "/case/details/");
  return Promise.resolve(response);
}

export async function getCases(caseListFilter) {
  const response = await basePost(caseListFilter, "/caselist/");
  return Promise.resolve(response);
}

export async function answerCase(caseAnswer, files) {
  var formData = createFormData(caseAnswer, files);

  try {
    const response = await basePostFormData(formData, "/case/answer/");
    return Promise.resolve(response);
  } catch (error) {
    console.error(`Failed to answer case ${caseAnswer.caseNumber}`, error);
    throw error;
  }
}

export async function resendCase(caseAnswer, files) {
  var formData = createFormData(caseAnswer, files);

  try {
    const response = await basePostFormData(formData, "/case/resend/");
    return Promise.resolve(response);
  } catch (error) {
    console.error(`Failed to resend case ${caseAnswer.caseNumber}`, error);
    throw error;
  }
}

export async function resendManualMark(caseAnswer, files) {
  var formData = createFormData(caseAnswer, files);

  try {
    const response = await basePostFormData(formData, "/case/resendmanualmark/");
    return Promise.resolve(response);
  } catch (error) {
    console.error(`Failed to resend case ${caseAnswer.caseNumber} for manual mark`, error);
    throw error;
  }
}

function createFormData(caseAnswer, files) {
  var formData = new FormData();
  formData.append("caseanswermodel", JSON.stringify(caseAnswer));
  files.forEach((file) => {
    formData.append("files[]", file);
  });
  return formData;
}

export async function checkLKStatus(caseNumber) {
  const response = await baseGet(caseNumber, "/case/checkstatus/");
  return Promise.resolve(response);
}

export async function getArchivedCases(caseListFilter) {
  const response = await basePost(caseListFilter, "/caselist/archive/");
  return Promise.resolve(response);
}

export async function getArchivedCasesExport(caseListFilter) {
  const response = await baseGetCsvFile(caseListFilter, "/caselist/exportarchive/");
  return Promise.resolve(response);
}

export async function getEventFeed() {
  const response = await baseGetAll("/caselist/eventhistory");
  return Promise.resolve(response);
}
