import * as types from '../actions/actiontypes';
import initialState from './initialstate';

type CurrentCasesPagingActionType = typeof types.SET_CURRENT_CASE_LIST_TOTAL_CASES_COUNT_SUCCESS | typeof types.SET_CURRENT_CASE_LIST_PAGE_COUNT_SUCCESS | typeof types.SET_CURRENT_CASE_LIST_CURRENT_PAGE;

export type CurrentCasesPagingAction = {
  type: CurrentCasesPagingActionType;
  totalCasesCount: number;
	pageCount: number;
	currentPage: number;
};

const currentCasesPagingReducer = (state: any = initialState.currentCasesPaging, action: CurrentCasesPagingAction): CurrentCasesPagingActionType => {
	switch (action.type) {
		case types.SET_CURRENT_CASE_LIST_TOTAL_CASES_COUNT_SUCCESS:
      return {
				...state,
				totalCasesCount: action.totalCasesCount
			}
    case types.SET_CURRENT_CASE_LIST_PAGE_COUNT_SUCCESS:
			return {
				...state,
				pageCount: action.pageCount
			}
		case types.SET_CURRENT_CASE_LIST_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.currentPage
			}
		default:
			return state;
	}
} 

export default currentCasesPagingReducer;