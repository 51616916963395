import * as types from '../actions/actiontypes';


export function loading(state = true, action) {
	switch(action.type) {
		case types.SHOW_LOADING:
			return true;
		case types.HIDE_LOADING:
			return false;
		default:
			return state;
	}
}

export function mapLoading(state = true, action) {
	switch(action.type) {
		case types.SHOW_MAP_LOADING:
			return true;
		case types.HIDE_MAP_LOADING:
			return false;
		default:
			return state;
	}
}