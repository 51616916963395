import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import './sortdirection.scss';


export default function SortDirection(props) {

  document.addEventListener('click', function(e) {
    if (e.target.closest(".side-sort-dropdown-content") || e.target.closest(".side-sort-dropdown")) {
      return;
    }
    else {
      try {
        document.getElementsByClassName('side-sort-dropdown-content')[0].style.display = 'none';
      }
      catch (e) {  
      }
    } 
  })

  const toggleSortMenu = (e) => {
    let target = e.currentTarget.querySelector('.side-sort-dropdown-content');
    
    if (e.target.className === 'side-sort-dropdown-item' || e.target.className === 'side-sort-dropdown-item-span') {
      target.style.display = 'none';
      props.handleSortDirectionChange(e);
    }
    else {
      if (target.style.display === 'none' || target.style.display === '') {
        target.style.display = 'block';
      }
      else if (target.style.display === 'block') {
        target.style.display = 'none';
      }
    }
  }

  return (
    <div className="side-sort-dropdown-wrapper">
      <div className="side-sort-dropdown" onClick={e => toggleSortMenu(e)}>
        <div className="side-sort-dropdown-header"><span>{props.sortDirectionValue}</span></div>
        <FiChevronDown className='side-sort-dropdown-icon' />
        <div className="side-sort-dropdown-content"> 
          <div className='side-sort-dropdown-item'><span className='side-sort-dropdown-item-span'>Nyaste ärenden överst</span></div>
          <div className='side-sort-dropdown-item'><span className='side-sort-dropdown-item-span'>Äldsta ärenden överst</span></div>
        </div>
      </div>
    </div>
  )
}