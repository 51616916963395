import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { Container, Row } from 'reactstrap';
import * as caseActions from '../../redux/actions/caseactions';
import MapContainer from '../../components/map/mapcontainer/mapcontainer';
import SideInfo from './sideinfo/sideinfo';
import ScrollableLeftPanel from '../../components/common/scrollableleftpanel';
import Spinner from "components/common/spinner";

function Case(props) {
  const [caseNumber, setCaseNumber] = useState(null);
  //const [ArchivedCase, setArchivedCase] = useState(false);
  let {id: paramCaseId} = useParams();
  let isFetching = useRef(true);
  let navigate = useNavigate();

  const { actions } = props;

  useEffect(() => {
    if (paramCaseId !== caseNumber) {
      setCaseNumber(paramCaseId);
      actions.loadCaseDetails(paramCaseId, isFetching)
          .catch(error => {
            console.error(error);
            const msAutoCloseTime = 3000;
            const options = {autoClose: msAutoCloseTime}
            toast.error('Det gick inte att hämta ärendet.', options);
            isFetching.current = false;

            setTimeout(() => {
              navigate("/AktuellaArenden");
            }, msAutoCloseTime);  
          });
    }
    
    // if user navigates directly here, (i.e paste http://localhost:3000/arenden/55546-001 in address bar) we don't find any Case because redux store is empty.
    // redux store will be populated first when CurrentCases component mounts. 
    // TODO: fetch directly from server with Case number.

    return () => {
      //Cleanup
      isFetching.current = false;
    };
  }, [actions, caseNumber, paramCaseId, navigate]);

  const loading = useSelector((state) => state.loading);
  if (loading) {
    return (
      <div>
        <Spinner containerClassName="side-info-spinner-container" />
        <Spinner containerClassName="map-spinner-container" />
      </div>
    );
  }
  
  // TODO: if no CaseId redirect to another page, probably CurrentCases
  return (
    <Container fluid>
      {(props.caseDetails && (paramCaseId === props.caseDetails.case.number)) &&
        <Row>
          <ScrollableLeftPanel>
            <SideInfo
              Case={props.caseDetails}
            />
          </ScrollableLeftPanel>
          <MapContainer id={paramCaseId} 
            caseDetails={props.caseDetails} 
          />
        </Row>
      }
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    caseDetails: state.caseDetails,
    mapTiles: null
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadCaseDetails: bindActionCreators(caseActions.loadCaseDetails, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Case);
