import React, { Fragment } from 'react';
import { useSelector } from 'react-redux'
import { connect } from 'react-redux';
import { FaRedo } from "react-icons/fa";
import CaseCard from '../../../components/common/casecard';
import SearchFilter from '../../../components/common/searchfilter';
import TypeFilter from '../../../components/common/typefilter';
import SortDirection from '../../../components/common/sortdirection';
import Spinner from "../../../components/common/spinner";
import './sideinfo.scss';


function SideInfo(props) {
  const loading = useSelector((state) => state.loading);

  let cases;
  if (props.currentCases.length > 0) {
    cases = props.currentCases.map(ce => <CaseCard key={ce.number} Case={ce} />);
  }
  else if (props.currentCases.length === 0) {
    cases = <div className='empty-case-list'><span>Det finns inga ärenden att hantera.</span></div>
  }
  else {
    // Not yet finished loading data.
    cases = <div className='empty-case-list'><span></span></div>
  }

  return (
    <Fragment>
      <div className="side-header">
        <div className="side-title">
          Aktuella ärenden
        </div>
        <div className="filter-container">
          <div className="filter-search-container">
            <SearchFilter
              searchString={props.searchValue}
              placeholder={props.placeholder}
              handleSearchChange={props.handleSearchChange}
            />
          </div>
          <TypeFilter
            handleTypeChange={props.handleTypeChange}
            CaseTypeValue={props.CaseTypeValue}
          />
        </div>
        <div className="clear-filter-container">
          <button className='button-secondary' 
            onClick={props.handleClearFilterClick}>
            Rensa filter
          </button>
        </div>
      </div>
      <div className='case-card-list-header-wrapper'>
        <button className='button-secondary button-column-margin button-icon' 
          onClick={props.handleRefreshClick}>
          <FaRedo />
          Uppdatera
        </button>
        {props.currentCases.length > 0 && 
          <SortDirection 
            handleSortDirectionChange={props.handleSortDirectionChange}
            sortDirectionValue={props.SortDirectionValue}
          />
        }
      </div>

      {loading ? (
        <Spinner 
          containerClassName='side-info-spinner-container'
        /> 
      ) : ( 
        <div className="case-card-list-container">
          {cases}
          <div className='paging-container'>
            {props.currentCases.length > 0 && 
              <span>{`Visar ${props.currentCases.length} av ${props.currentCasesPaging.totalCasesCount} ärenden`}</span>
            }
            {(props.currentCases.length > 0 && props.currentCasesPaging.currentPage < props.currentCasesPaging.pageCount) &&
              <button className='button-secondary'
                onClick={props.handleLoadMoreCasesClick}>
                Ladda fler
              </button>
            }
          </div>
        </div>
      )}
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    currentCasesPaging: state.currentCasesPaging
  };
}

export default connect(
  mapStateToProps,
)(SideInfo);
