import * as types from "./actiontypes";
import { showLoading, hideLoading } from "./loadingactions";
import * as api from "api/authapi";


export const login = (username, password) => {
  return function (dispatch) {
    dispatch(showLoading());

    return api.logIn(username, password)
      .then(loggedIn => {
        if (loggedIn) {
          dispatch({type: types.LOGIN_SUCCESS, loggedIn});
          dispatch(hideLoading());
        }      
      })
      .catch(error => {
        dispatch(hideLoading());
        throw error;
      });
  }
}
