import React from "react";
import { Button } from "react-bootstrap";
import { FaFile } from "react-icons/fa";
import { toast } from "react-toastify";
import { getAttachment, getArchivedFile } from "redux/actions/caseactions";
import "./fileinforow.scss";

export default function FileInfoRow(props) {
  function handleAttachmentClick(fileName) {
    getAttachment(props.caseNumber, fileName).catch(() => {
      toast.error(`Filen ${fileName} kunde inte hämtas`);
    });
  }

  function handleArchivedFileClick(fileName, caseMaterialEmailId) {
    getArchivedFile(props.caseNumber, caseMaterialEmailId, fileName).catch(() => {
      toast.error(`Filen ${fileName} kunde inte hämtas`);
    });
  }

  return (
    <div className={"case-file-info-wrapper"}>
      <span className={"case-file-info-label"}>
        {!props.label || props.label === "" ? "Bifogade filer" : props.label}
      </span>
      {(props.files.length === 1 && props.files[0].name === "-") || props.files.length === 0 ? (
        // No attachments included in the case.
        <span>-</span>
      ) : (
        props.files.map((f, index) => (
          <div key={index} className="case-file-info-item-wrapper">
            <FaFile />
            <Button
              variant="link"
              className="button-link case-file-info-item"
              href="#"
              onClick={() =>
                props.isArchived ? handleArchivedFileClick(f.name, props.caseMaterialEmailId) : handleAttachmentClick(f.name)
              }
            >
              {f.name}
            </Button>
          </div>
        ))
      )}
    </div>
  );
}
