import * as types from "./actiontypes";
import * as api from "../../api/caseapi";


// const searchOptions = {
  
//   keys: [
//     "number",
//     "type",
//     "orderer",
//     "status"
//   ]
// };

export function loadCoverageAreasCases() {
  return function (dispatch) {
    return api.getCases()
      .then(Cases => {
        dispatch(loadCoverageAreasCasesSuccess(Cases));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function filterCoverageAreasCases(filter) {
  return function (dispatch) {
    return api.getCases()
    .then(cases => {
			let key;
			const filteredCases = [];
			if (filter ==='alla' || filter === 'Alla') {
				dispatch(loadCoverageAreasCasesSuccess(cases));
			}
			else {
				for (key in cases) {
					if (cases.hasOwnProperty(key)) {
							if (cases[key].type === filter) {
								filteredCases.push(cases[key]);
							}
					}
				}
				dispatch(loadCoverageAreasCasesSuccess(filteredCases));
			}
    })
  }
}

export function searchInCoverageAreasCases(searchString) {
  return function(dispatch) {
    return api.getCases()
			.then(cases => {
				let key;
				const matches = [];
				for (key in cases) {
					if (cases.hasOwnProperty(key)) {
						if (Object.values(cases[key]).toString().toLowerCase().includes(searchString.toLowerCase())) {
							matches.push(cases[key]);
						}
					}
				}
				if (matches.length > 0) {
					dispatch(loadCoverageAreasCasesSuccess(matches));
				}
				else {
					dispatch(loadCoverageAreasCasesSuccess(cases));
				}
			})
  }
}

export function loadCoverageAreasCasesSuccess(Cases) {
  return { type: types.LOAD_COVERAGE_AREAS_CASES_SUCCESS, Cases };
}