import React from 'react';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from '@material-ui/core/styles';


export default function Toggler(props) {
  const Toggler = withStyles({
    switchBase: {
      color: '#ebf7f9',
      '&$checked': {
        color: '#3eb1c8',
      },
      '&$checked + $track': {
        backgroundColor: '#3eb1c8',
      },
    },
    checked: {},
    track: {},
  })(Switch);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Toggler 
            checked={props.checked} 
            onChange={props.onChange} 
            name="toggler" 
          />
        }
        label={props.label}
      />
    </FormGroup>
  )
}