import * as types from '../actions/actiontypes';
import initialState from './initialstate';


export default function currentCasesReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_CURRENT_CASES_SUCCESS:
      //const returnedTarget = Object.assign({}, state.CurrentCases, action.cases);
      return action.cases;
    /* const returnedTarget = Object.assign({}, state.CurrentCases, action.cases);
      return returnedTarget;*/
    case types.LOAD_MORE_CURRENT_CASES_SUCCESS:
      return state.concat(action.cases);
    case types.SEARCH_IN_CURRENT_CASES:
			return Object.assign({}, state.CurrentCases, { 
				CurrentCases: action.cases
			});
    case types.FILTER_CURRENT_CASES:
   /*    return Object.assign({}, state, { 
          CurrentCases: action.cases
        });*/
			return applicationCache.cases;
    default:
      return state;
  }
}
