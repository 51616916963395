import React from 'react';
import Spinner from "./spinner";
import './spinnerbutton.scss';


export default function SpinnerButton(props) {
	return (
		<button type="submit" className={props.buttonClassName} onClick={props.onClick}>
      {props.loading ? (
        <Spinner 
          containerClassName='button-spinner-container'
          spinnerClassName={props.secondaryButton ? 'spinner-dark' : 'spinner-light'}
        />
      ) : (
        <span>{props.children}</span>
      )}
    </button>
	);
}
