import { SettingsType } from '../../types/settings';
import * as types from '../actions/actiontypes';
import initialState from './initialstate';

type SettingsActionType = typeof types.LOAD_SETTINGS_SUCCESS | typeof types.SAVE_SETTINGS_SUCCESS;

export type SettingsAction = {
  type: SettingsActionType;
  settings: SettingsType;
};

const settingsReducer = (state: any = initialState, action: SettingsAction): SettingsType => {
  switch (action.type) {
    case types.LOAD_SETTINGS_SUCCESS:
      return action.settings;
    case types.SAVE_SETTINGS_SUCCESS:
      return {
				...state, 
				...action.settings
			}
    default:
      return state;
  }
}

export default settingsReducer;