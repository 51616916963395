//Actiontypes for login and settings (User).
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_USER_CREDENTIALS = 'SET_USER_CREDENTIALS';
export const GET_USER_CREDENTIALS = 'GET_USER_CREDENTIALS';

//Actiontypes for current cases
export const LOAD_CURRENT_CASES_SUCCESS = 'LOAD_CURRENT_CASES_SUCCESS';
export const LOAD_MORE_CURRENT_CASES_SUCCESS = 'LOAD_MORE_CURRENT_CASES_SUCCESS';
export const FILTER_CURRENT_CASES = 'FILTER_CURRENT_CASES_SUCCESS';
export const SEARCH_IN_CURRENT_CASES = 'SEARCH_IN_CURRENT_CASES';
//Actiontypes for current cases - filter
export const SET_SEARCH_VALUE_CURRENT_CASES_FILTER = 'SET_SEARCH_VALUE_CURRENT_CASES_FILTER';
export const SET_CASE_TYPE_CURRENT_CASES_FILTER = 'SET_CASE_TYPE_CURRENT_CASES_FILTER';
export const SET_SORT_DIRECTION_CURRENT_CASES_FILTER = 'SET_SORT_DIRECTION_CURRENT_CASES_FILTER';
export const CLEAR_CURRENT_CASES_FILTER = 'CLEAR_CURRENT_CASES_FILTER';

//Actiontypes for caseDetails
export const LOAD_CASE_DETAILS_SUCCESS = 'LOAD_CASE_DETAILS_SUCCESS';
export const LOAD_ARCHIVED_CASE_DETAILS_SUCCESS = 'LOAD_ARCHIVED_CASE_DETAILS_SUCCESS'

//Actiontypes for archived cases
export const LOAD_ARCHIVED_CASES_SUCCESS = 'LOAD_ARCHIVED_CASES_SUCCESS';
export const LOAD_MORE_ARCHIVED_CASES_SUCCESS = 'LOAD_MORE_ARCHIVED_CASES_SUCCESS';
export const FILTER_ARCHIVED_CASES_SUCCESS = 'FILTER_ARCHIVED_CASES';
export const SEARCH_IN_ARCHIVED_CASES = 'SEARCH_IN_ARCHIVED_CASES';
//Actiontypes for archived cases - filter
export const SET_START_DATE_ARCHIVED_CASES_FILTER = 'SET_START_DATE_ARCHIVED_CASES_FILTER';
export const SET_END_DATE_ARCHIVED_CASES_FILTER = 'SET_END_DATE_ARCHIVED_CASES_FILTER';
export const SET_SEARCH_VALUE_ARCHIVED_CASES_FILTER = 'SET_SEARCH_VALUE_ARCHIVED_CASES_FILTER';
export const SET_CASE_TYPE_ARCHIVED_CASES_FILTER = 'SET_CASE_TYPE_ARCHIVED_CASES_FILTER';
export const SET_SORT_DIRECTION_ARCHIVED_CASES_FILTER = 'SET_SORT_DIRECTION_ARCHIVED_CASES_FILTER';
export const CLEAR_ARCHIVED_CASES_FILTER = 'CLEAR_ARCHIVED_CASES_FILTER';

//Actiontypes for Coverage Areas
export const LOAD_COVERAGE_AREAS_CASES_SUCCESS = 'LOAD_COVERAGE_AREAS_CASES_SUCCESS';
export const FILTER_COVERAGE_AREAS = 'FILTER_COVERAGE_AREAS';
export const SEARCH_IN_COVERAGE_AREAS = 'SEARCH_IN_COVERAGE_AREAS';

//Actiontypes for current case list paging
export const SET_CURRENT_CASE_LIST_TOTAL_CASES_COUNT_SUCCESS = 'SET_CURRENT_CASE_LIST_TOTAL_CASES_COUNT_SUCCESS';
export const SET_CURRENT_CASE_LIST_PAGE_COUNT_SUCCESS = 'SET_CURRENT_CASE_LIST_PAGE_COUNT_SUCCESS';
export const SET_CURRENT_CASE_LIST_CURRENT_PAGE = 'SET_CURRENT_CASE_LIST_CURRENT_PAGE';

//Actiontypes for archived case list paging
export const SET_ARCHIVED_CASE_LIST_TOTAL_CASES_COUNT_SUCCESS = 'SET_ARCHIVED_CASE_LIST_TOTAL_CASES_COUNT_SUCCESS';
export const SET_ARCHIVED_CASE_LIST_PAGE_COUNT_SUCCESS = 'SET_ARCHIVED_CASE_LIST_PAGE_COUNT_SUCCESS';
export const SET_ARCHIVED_CASE_LIST_CURRENT_PAGE = 'SET_ARCHIVED_CASE_LIST_CURRENT_PAGE';

//Actiontypes for MapLayer.
export const LOAD_MAP_SETTINGS = 'LOAD_MAP_SETTINGS';
export const LOAD_MAP_POLYGON = 'LOAD_MAP_POLYGON';
export const LOAD_MAP_MARKERS = 'LOAD_MAP_MARKERS';
export const LOAD_MAP_SETTINGS_SUCCESS = 'LOAD_MAP_SETTINGS_SUCCESS';
export const LOAD_MAPTILES_SUCCESS = 'LOAD_MAPTILES_SUCCESS';
export const LOAD_MAP_POLYGON_SUCCESS = 'LOAD_MAP_POLYGON_SUCCESS';
export const LOAD_MAP_MARKERS_SUCCESS = 'LOAD_MAP_MARKERS_SUCCESS';
export const FILTER_MARKERS = 'FILTER_MARKERS';
export const SET_BOUNDS = 'SET_BOUNDS';

// Actiontypes for Admin.
export const LOAD_SETTINGS_SUCCESS = 'LOAD_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
export const SAVE_LOGOTYPE_FILE_SUCCESS = 'SAVE_LOGOTYPE_FILE_SUCCESS';
export const LOAD_LOGOTYPE_FILE_SUCCESS = 'LOAD_LOGOTYPE_FILE_SUCCESS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

// Actiontypes for Status
export const LOAD_EVENT_FEED_SUCCESS = 'LOAD_EVENT_FEED_SUCCESS';

// Actiontypes for Loading
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SHOW_MAP_LOADING = 'SHOW_MAP_LOADING';
export const HIDE_MAP_LOADING = 'HIDE_MAP_LOADING';