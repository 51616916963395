import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaChevronCircleLeft } from "react-icons/fa";
import CaseHeader from "./caseheader/caseheader";
import CaseBody from "./casebody/casebody";
import Spinner from "components/common/spinner";
import "./sideinfo.scss";

function SideInfo(props) {
  const [backClick, setBackClick] = useState(false);
  const [actSelectorActive, setActSelectorActive] = useState(false);
  const [sendAgainActive, setSendAgainActive] = useState(false);
  const [viewSentAnswerActive, setViewSentAnswerActive] = useState(false);
  const [sendAnswerByMailActive, setSendAnswerByMailActive] = useState(false);
  const [sendManualMarkActive, setSendManualMarkActive] = useState(false);

  let navigate = useNavigate();

  function handleBackClick() {
    if (actSelectorActive || sendAgainActive || viewSentAnswerActive || sendAnswerByMailActive || sendManualMarkActive) {
      setBackClick(true);
    } else {
      if (props.Case.isArchived) {
        navigate("/ArendeHistorik", { state: { previousPage: "caseDetailsPage" } });
      }
      else {
        navigate("/AktuellaArenden", { state: { previousPage: "caseDetailsPage" } });
      }
    }
  }

  const loading = useSelector((state) => state.loading);
  if (loading) {
    return <Spinner containerClassName="side-info-spinner-container" />;
  }

  return (
    <Fragment>
      <div className="side-back-button-wrapper" onClick={handleBackClick}>
        <span className="side-back-button">
          <FaChevronCircleLeft />
          {props.Case && !(actSelectorActive || sendAgainActive || viewSentAnswerActive || sendAnswerByMailActive || sendManualMarkActive) ? (
            props.Case.isArchived ? (
              <span>Tillbaka till ärendehistorik</span>
            ) : (
              <span>Tillbaka till aktuella ärenden</span>
            )
          ) : (
            <span>Tillbaka</span>
          )}
        </span>
      </div>
      {props.Case ? (
        <div>
          <CaseHeader Case={props.Case} />
          <CaseBody
            Case={props.Case}
            backClick={backClick}
            setBackClick={setBackClick}
            setActSelectorActive={setActSelectorActive}
            sendAgainActive={sendAgainActive}
            setSendAgainActive={setSendAgainActive}
            viewSentAnswerActive={viewSentAnswerActive}
            setViewSentAnswerActive={setViewSentAnswerActive}
            sendAnswerByMailActive={sendAnswerByMailActive}
            setSendAnswerByMailActive={setSendAnswerByMailActive}
            sendManualMarkActive={sendManualMarkActive}
            setSendManualMarkActive={setSendManualMarkActive}
          />
        </div>
      ) : (
        <div className="no-cases-error">Inget ärende hittades</div>
      )}
    </Fragment>
  );
}

export default SideInfo;
