import React, { Fragment } from "react";
import InfoRow from "./inforow/inforow";
import FileInfoRow from "./fileinforow/fileinforow";
import GeometryRow from "./geometryrow/geometryrow";
import ArchivedCaseInfo from "../archivedcaseinfo/archivedcaseinfo";
import "./caseinfo.scss";

export default function CaseInfo(props) {
  let geometries = null;
  if (props.Case.coordinateFields.length) {
    geometries = props.Case.coordinateFields.map((g, index) => <GeometryRow geometries={g} key={index} />);
  }

  return (
    <Fragment>
      {props.Case.complementaryFields.find((c) => c.key === "UBL ärende") &&
      props.Case.complementaryFields.find((c) => c.key === "UBL ärende").value === "true" ? (
        <div className="info-box-wrapper">
          <p className="info-box-header">Information om utbyggnadslagen (2016:534)</p>
          <p>
            Ledningskollen har bedömt att er organisation är nätinnehavare enligt utbyggnadslagen. Detta ärende är
            kategoriserat som "bredbandsnät, utbyggnad" i Ledningskollen, därmed kan detta projekt omfattas av
            utbyggnadslagen (2016:534).
          </p>
          <p>
            Enligt lagen har bredbandsutbyggare rätt att få information om er befintliga infrastruktur inom det
            arbetsområde som ärendet avser för att senare även kunna begära tillträde till denna infrastruktur.
          </p>
          <p>
            Ert svar på detta ärende ska därför även enligt lagen innehålla passiva nätdelar såsom kanalisationer,
            master, tillsynsbrunnar, manhål, kopplingsskåp, byggnader, ingångar till byggnader, antenninstallationer,
            torn och stolpar.
          </p>
          <p>
            Läs mer på{" "}
            <a
              href="https://utbyggnadsportalen.pts.se/"
              target="_blank"
              rel="noopener noreferrer"
              title="Utbyggnadsportalen (öppnas i nytt fönster)"
            >
              utbyggnadsportalen.pts.se
            </a>
          </p>
        </div>
      ) : null}

      {props.Case.isArchived && 
        <ArchivedCaseInfo 
          caseAnswerInLK={props.Case.caseAnswer} // First answer - in LK.
          latestInCheckAnswer={props.Case.caseMaterialEmails?.length > 0 ? props.Case.caseMaterialEmails[0] : null} // Latest answer - in InCheck.
        />
      }
      
      <div className="case-body-info-wrapper">
        <InfoRow
          infoObjects={[
            { label: "Benämning", info: `${props.Case.overviewFields.find((c) => c.key === "Benämning").value}` },
          ]}
        />
        <InfoRow
          infoObjects={[
            {
              label: "Planerat startdatum",
              info: `${props.Case.overviewFields.find((c) => c.key === "Planerat startdatum").value}`,
            },
            {
              label: "Planerat slutdatum",
              info: `${props.Case.overviewFields.find((c) => c.key === "Planerat slutdatum").value}`,
            },
            {
              label: "Ärendet skapades",
              info: `${props.Case.overviewFields.find((c) => c.key === "Ärendet skapades").value}`,
            },
          ]}
        />
        <InfoRow
          infoObjects={[
            {
              label: "Arbetstyp(er)",
              info: `${props.Case.overviewFields.find((c) => c.key === "Arbetstyp(er)").value}`,
            },
            {
              label: "Kategorisering av arbete",
              info: `${props.Case.overviewFields.find((c) => c.key === "Kategorisering av arbete").value}`,
            },
          ]}
        />
        <InfoRow
          infoObjects={[
            { label: "Grävdjup", info: `${props.Case.overviewFields.find((c) => c.key === "Grävdjup").value}` },
            { label: "Regioner", info: `${props.Case.overviewFields.find((c) => c.key === "Regioner").value}` },
          ]}
        />
        <InfoRow
          infoObjects={[
            { label: "Kommentar", info: `${props.Case.complementaryFields.find((c) => c.key === "Kommentar").value}` },
          ]}
        />
        <div className={"case-body-info-creator-file-wrapper"}>
          <div className={"case-body-info-creator"}>
            <InfoRow
              infoObjects={[
                {
                  label: "Skapat av",
                  info: `${
                    props.Case.creatorFields.find((c) => c.key === "Avsändarens organisation").value +
                    "\n" +
                    props.Case.creatorFields.find((c) => c.key === "Avsändare").value
                  }`,
                },
              ]}
            />
            <InfoRow
              infoObjects={[
                {
                  label: "Skaparens benämning",
                  info: `${props.Case.creatorFields.find((c) => c.key === "Avsändarens benämning på ärendet").value}`,
                },
              ]}
            />
            <InfoRow
              infoObjects={[
                {
                  label: "Telefon",
                  info: `${props.Case.creatorFields.find((c) => c.key === "Avsändarens telefon").value}`,
                },
                {
                  label: "Mobiltelefon",
                  info: `${props.Case.creatorFields.find((c) => c.key === "Avsändarens mobiltelefon").value}`,
                },
              ]}
            />
            <InfoRow
              infoObjects={[
                {
                  label: "Adress",
                  info: `${
                    props.Case.creatorFields.find((c) => c.key === "Avsändarens adress").value +
                    "\n" +
                    props.Case.creatorFields.find((c) => c.key === "Avsändarens epostadress").value
                  }`,
                },
              ]}
            />
            {props.Case.overviewFields.find((c) => c.key === "Gatuadress") ? (
              // Utsättningsadress is not present for all case types.
              <InfoRow
                infoObjects={[
                  {
                    label: "Utsättningsadress",
                    info: `${props.Case.overviewFields.find((c) => c.key === "Gatuadress").value}`,
                  },
                ]}
              />
            ) : null}
          </div>
          <div className={"case-body-info-files"}>
            <FileInfoRow
              caseNumber={props.Case.case.number}
              files={[{ name: `${props.Case.complementaryFields.find((c) => c.key === "Bifogade filer").value}` }]}
            />
          </div>
        </div>
        {geometries}
      </div>
    </Fragment>
  );
}
