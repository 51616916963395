import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux'
import { connect } from 'react-redux';
import { FaRedo, FaFileDownload } from "react-icons/fa";
import CaseCard from '../../../components/common/casecard';
import SearchFilter from '../../../components/common/searchfilter';
import TypeFilter from '../../../components/common/typefilter';
import SortDirection from '../../../components/common/sortdirection';
import './sideinfo.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';
//import Toggler from '../../../components/common/toggler';
import Spinner from "../../../components/common/spinner";
import SpinnerButton from "components/common/spinnerbutton";


registerLocale('sv', sv);

function SideInfo(props) {
  const loading = useSelector((state) => state.loading);

  let cases;
  if (props.archivedCases.length > 0) {
    cases = props.archivedCases.map(ce => <CaseCard key={ce.number} Case={ce} />);
  }
  else if (props.archivedCases.length === 0) {
    cases = <div className='empty-case-list'><span>Det finns ingen ärendehistorik.</span></div>
  }
  else {
    // Not yet finished loading data.
    cases = <div className='empty-case-list'><span></span></div>
  }

  return (
    <Fragment>
      <div className="side-header">
        <div className="side-title">
          Ärendehistorik
        </div>
        <div className="archived-filter-container">
          <div className="filter-search-container">
            <SearchFilter
              searchString={props.searchValue}
              placeholder={props.placeholder}
              handleSearchChange={props.handleSearchChange}
            />
          </div>
          <TypeFilter
            handleTypeChange={props.handleTypeChange}
            CaseTypeValue={props.CaseTypeValue}
          />
        </div>
        <div className="archived-filter-container">
          <div className="archived-filter-date-container">
            <div className='archived-date-wrapper'>
              <label htmlFor="start-date-date-picker" className="archived-start-date-label">Skapat från</label>
              <DatePicker
                name="start-date-date-picker"
                selected={props.startDate}
                onChange={props.handleStartDateChange}
                selectsStart
                startDate={props.startDate}
                endDate={props.endDate}
                dateFormat="yyyy-MM-dd"
                className='archived-start-date-input'
                locale='sv'
              />
            </div>
            <div className='archived-date-wrapper'>
              <label htmlFor="end-date-date-picker" className="archived-end-date-label">Skapat till</label>
              <DatePicker
                name="end-date-date-picker"
                selected={props.endDate}
                onChange={props.handleEndDateChange}
                selectsEnd
                startDate={props.startDate}
                endDate={props.endDate}
                minDate={props.startDate}
                dateFormat="yyyy-MM-dd"
                className='archived-end-date-input'
                locale='sv'
              />
            </div>
          </div>
          <button className='button-secondary button-column-margin' 
            onClick={props.handleClearFilterClick}>
            Rensa filter
          </button>
          {/* <Toggler
            name='archived-case-toggler'
            label='Filtrera listan med kartvyn'
          /> */}
        </div>
      </div>
      <div className='case-card-list-header-wrapper'>
        <div className='case-card-list-header-button-wrapper'>
          <button className='button-secondary button-column-margin button-icon' 
            onClick={props.handleRefreshClick}>
            <FaRedo />
            Uppdatera
          </button>
          {props.archivedCases.length > 0 &&
            <SpinnerButton
              onClick={props.handleExportClick}
              loading={props.exporting}
              buttonClassName="button-secondary button-icon"
              secondaryButton={true}
            >
              <FaFileDownload />
              Exportera CSV
            </SpinnerButton>
          }
        </div>
        {props.archivedCases.length > 0 &&
          <SortDirection 
            handleSortDirectionChange={props.handleSortDirectionChange}
            sortDirectionValue={props.SortDirectionValue}
          />
        }
      </div>

      {loading ? (
        <Spinner 
          containerClassName='side-info-spinner-container'
        /> 
      ) : ( 
        <div className="case-card-list-container">
          {cases}
          <div className='paging-container'>
            {props.archivedCases.length > 0 && 
              <span>{`Visar ${props.archivedCases.length} av ${props.archivedCasesPaging.totalCasesCount} ärenden`}</span>
            }
            {(props.archivedCases.length > 0 && props.archivedCasesPaging.currentPage < props.archivedCasesPaging.pageCount) &&
              <button className='button-secondary'
                onClick={props.handleLoadMoreCasesClick}>
                Ladda fler
              </button>
            }
          </div>
        </div>
      )}
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    archivedCasesPaging: state.archivedCasesPaging
  };
}

export default connect(
  mapStateToProps,
)(SideInfo);
