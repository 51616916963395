import * as types from '../actions/actiontypes';
import initialState from './initialstate';


export default function caseDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_CASE_DETAILS_SUCCESS:
      return action.caseDetails;
    default:
      return state;
  }
}
