import * as types from '../actions/actiontypes';


export default function statusReducer(state = null, action) {
  switch (action.type) {
    case types.LOAD_EVENT_FEED_SUCCESS:
      return action.eventFeed;
    default:
      return state;
  }
}
