import React, { useState, useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { Container, Row } from 'reactstrap';
import * as currentCasesActions from '../../redux/actions/currentcasesactions';
import MapContainer from '../../components/map/mapcontainer/mapcontainer';
import SideInfo from './sideinfo/sideinfo';
import ScrollableLeftPanel from '../../components/common/scrollableleftpanel';


function CurrentCases(props) {
  const placeholder = 'Sök bland aktuella ärenden...';
  const location = useLocation();

  const { actions } = props;

  const handleSearchChange = (event) => {
    actions.setSearchValueCurrentCasesFilter(event.target.value);
  }

  const handleTypeChange = (event) => {
    actions.setCaseTypeCurrentCasesFilter(event.target.innerText);
  }

  const handleSortChange = (event) => {
    actions.setSortDirectionCurrentCasesFilter(event.target.innerText);
  }

  const handleClearFilterClick = () => {
    actions.setClearCurrentCasesFilter();
  }

  const handleRefreshClick = () => {
    actions.filterAndSearchCurrentCases(props.currentCasesFilter, 1)
      .then(function () {
        toast.success('Listan med aktuella ärenden har uppdaterats.');
      })
			.catch(error => {
				console.log(error);
				toast.error('Det gick inte att hämta aktuella ärenden.');
			})
  }

  const handleLoadMoreCasesClick = () => {
    actions.filterAndSearchCurrentCases(props.currentCasesFilter, props.currentCasesPaging.currentPage + 1)
      .catch(error => {
        console.log(error);
        toast.error('Det gick inte att hämta aktuella ärenden.');
    })
  }

  const filterAndSearchCurrentCases = useCallback(() => {
    actions.filterAndSearchCurrentCases(props.currentCasesFilter, 1)
			.catch(error => {
				console.log(error);
				toast.error('Det gick inte att hämta aktuella ärenden.');
			})
  }, [props.currentCasesFilter])

  useEffect(() => {
    if (location.state && location.state.previousPage === "caseDetailsPage") {
      location.state = null;
    }
    else {
      filterAndSearchCurrentCases();
    }
	}, [actions, filterAndSearchCurrentCases])

  return (
    <Container fluid>
      <Row>
        <ScrollableLeftPanel>
          <SideInfo
            currentCases={props.currentCases}
            handleLoadMoreCasesClick={handleLoadMoreCasesClick}
            handleSearchChange={handleSearchChange}
            placeholder= {placeholder}
            searchValue={props.currentCasesFilter.searchString}
            CaseTypeValue={props.currentCasesFilter.caseTypeFilter}
            handleTypeChange={handleTypeChange}
            SortDirectionValue={props.currentCasesFilter.sortDirection}
            handleSortDirectionChange={handleSortChange}
            handleClearFilterClick={handleClearFilterClick}
            handleRefreshClick={handleRefreshClick}
          />
        </ScrollableLeftPanel>
        <MapContainer 
          markers={props.currentCases}
        />
      </Row>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    currentCases: state.currentCases,
    currentCasesFilter: state.currentCasesFilter,
    currentCasesPaging: state.currentCasesPaging
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      filterAndSearchCurrentCases: bindActionCreators(currentCasesActions.filterAndSearchCurrentCases, dispatch),
      setSearchValueCurrentCasesFilter: bindActionCreators(currentCasesActions.setSearchValueCurrentCasesFilter, dispatch),
      setCaseTypeCurrentCasesFilter: bindActionCreators(currentCasesActions.setCaseTypeCurrentCasesFilter, dispatch),
      setSortDirectionCurrentCasesFilter: bindActionCreators(currentCasesActions.setSortDirectionCurrentCasesFilter, dispatch),
      setClearCurrentCasesFilter: bindActionCreators(currentCasesActions.setClearCurrentCasesFilter, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentCases);
