import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaUser, FaCog } from "react-icons/fa";
import { RiLogoutBoxRFill } from "react-icons/ri";
import { getUser, isLoggedIn } from "utils/auth";
import { logOut } from "api/authapi";
import "./navbar.scss";

export default function Navbar() {
  let navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loggedInUserName, setLoggedInUserName] = useState("");

  function handleLogOut() {
    if (isLoggedIn()) {
      logOut().then(() => {
        navigate("/");
      });
    } else {
      navigate("/");
    }
  }

  function activeStyle({ isActive }) {
    return { textDecoration: isActive ? "underline" : "none" };
  }

  function isUserAdministrator() {
    const user = getUser();
    if (user) {
      setIsAdmin(user.administrator);
      setLoggedInUserName(user.userName);
    }
  }

  useEffect(() => {
    isUserAdministrator();
  }, []);

  return (
    <div className="flex-container">
      <div className="left-container">
        <div>
          <NavLink to="/AktuellaArenden" className="nav-incheck" style={{ textDecoration: "none" }}> InCheck </NavLink>
        </div>
        <div>
          <NavLink to="/AktuellaArenden" className="nav-current-cases" style={activeStyle}> Aktuella ärenden </NavLink>
        </div>
        {/* <div>
          <NavLink to="/Bevakningsomraden" className="nav-coverage-areas" style={activeStyle}> Bevakningsområden </NavLink>
        </div> */}
        <div>
          <NavLink to="/ArendeHistorik" className="nav-archived-cases" style={activeStyle}> Ärendehistorik </NavLink>
        </div>
        <div>
          <NavLink to="/Status" className="nav-status" style={activeStyle}> Händelselogg </NavLink>
        </div>
        {/* <div>
          <NavLink to="/Status" className="nav-status" style={activeStyle}> Status </NavLink>
        </div> */}
      </div>

      <div className="right-container">
        {isAdmin ? (
          <div className="right-container-item-wrapper">
            <NavLink to="/Installningar" style={activeStyle} className="nav-settings">
              <FaCog className="nav-settings-icon" />
              <span>Inställningar</span>
            </NavLink>
          </div>
        ) : null}
        {loggedInUserName !== "" ? (
          <div className="right-container-item-wrapper">
            <div>
              <FaUser className="nav-user-icon" />
              <span>{loggedInUserName}</span>
            </div>
          </div>
        ) : null}
        <div className="right-container-item-wrapper">
          <button onClick={handleLogOut} className="nav-logout">
            <RiLogoutBoxRFill className="nav-logout-icon" />
            <span>Logga ut</span>
          </button>
        </div>
      </div>
    </div>
  );
}
