import L from 'leaflet';
import bluemarker from './svg/bluemarker.svg';
import lightbluemarker from './svg/lightbluemarker.svg';
import redmarker from './svg/redmarker.svg';
import yellowmarker from './svg/yellowmarker.svg';

const blueIcon = new L.Icon({
	iconUrl: bluemarker,
	shadowUrl: require('./images/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const lightBlueIcon = new L.Icon({
	iconUrl: lightbluemarker,
	shadowUrl: require('./images/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const redIcon = new L.Icon({
	iconUrl: redmarker,
	shadowUrl: require('./images/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const yellowIcon = new L.Icon({
	iconUrl: yellowmarker,
	shadowUrl: require('./images/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const markerCaseTypes = {
    'Ledningsanvisning': redIcon,
    'Projektering': yellowIcon,
    'Samhällsplanering': blueIcon,
    'Samordning-Bevakning': lightBlueIcon
};
