import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CurrentCases from '../../pages/currentcases/currentcases';
import ArchivedCases from '../../pages/archivedcases/archivedcases';
import CoverageAreas from '../../pages/coverageareas/coverageareas';
import Settings from '../../pages/settings/settings';
import MapContainer from '../map/mapcontainer/mapcontainer';
import Case from '../../pages/case/case';
import Status from '../../pages/status/status';
import PageNotFound from '../common/pagenotfound';
import RequireAdmin from '../requireadmin';
import RequireAuth from '../requireauth';


function Main() {
  return (
    <Routes>
      <Route path="/Map" element={<RequireAuth><MapContainer/></RequireAuth>} />
      <Route path="/AktuellaArenden" element={<RequireAuth><CurrentCases/></RequireAuth>} />
      <Route path="/Bevakningsomraden" element={<RequireAuth><CoverageAreas/></RequireAuth>} />
      <Route path="/ArendeHistorik" element={<RequireAuth><ArchivedCases/></RequireAuth>} />
      <Route path="/Installningar" element={<RequireAuth><RequireAdmin><Settings/></RequireAdmin></RequireAuth>} />
      <Route path="/Status" element={<RequireAuth><Status/></RequireAuth>} />
      <Route path="/arenden/:id" element={<RequireAuth><Case/></RequireAuth>} />
      <Route path="*" element={<RequireAuth><PageNotFound/></RequireAuth>} />
    </Routes>
  );
}

export default Main;

