import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { Table, Container } from "reactstrap";
import { FaTrashAlt } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { toast } from "react-toastify";
import Spinner from "components/common/spinner";
import * as adminActions from "redux/actions/adminactions";
import { NotFoundError } from "api/responseerrors";
import "./users.scss";
import { UserModel } from "types/usermodel";

type AppProps = {
  actions: any;
  users: UserModel[];
  onEditUser: (user: UserModel) => void;
};

const Users = ({ actions, users, onEditUser }: AppProps) => {

  const handleDeleteClick = (index: number) => {
    users = [...users];
    let deleteUser = users.splice(index, 1);
    if (deleteUser.length > 0) {
      actions
        .deleteUser(deleteUser[0].user.id)
        .then(() => {
          toast.success(`Användaren '${deleteUser[0].user.username}' har tagits bort.`);
        })
        .catch((error: Error) => {
          console.error(error);
          if (error instanceof NotFoundError) {
            toast.error(`Användaren '${deleteUser[0].user.username}' saknas.`);
          } else {
            toast.error(`Det gick inte att ta bort användaren '${deleteUser[0].user.username}'.`);
          }
        });
    }
    // TODO: Make sure that the GUI is updated when the user has been removed.
  };

  useEffect(() => {
    actions.loadUsers().catch((error: Error) => {
      console.error(error);
      toast.error("Det gick inte att hämta användare.");
    });
  }, [actions]);

  const loading = useSelector((state: any) => state.loading);
  if (loading) {
    return <Spinner />;
  }

  if (users && users.length) {
    return (
      <Container>
        <h4 className="settings-title">Användare</h4>
        <Table hover>
          <thead>
            <tr>
              <th>Namn</th>
              <th>Användarnamn</th>
              <th>E-post</th>
              <th>Administratör</th>
              <th>Hantera</th>
            </tr>
          </thead>
          <tbody>
            {users.map((userInfo, index) => (
              <tr className="table-row" key={index}>
                <td>{userInfo.user.name}</td>
                <td>{userInfo.user.username}</td>
                <td>{userInfo.user.email}</td>
                <td>{userInfo.isAdministrator ? "Ja" : "Nej"}</td>
                <td className="no-wrap">
                  <FaTrashAlt style={{ color: "red", margin: "0 0.5em" }} onClick={() => handleDeleteClick(index)} />
                  <MdEdit
                    style={{ color: "#272525", width: "1.3em", height: "1.3em", margin: "0 0.5em" }}
                    onClick={() => {
                      onEditUser(users[index]);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    );
  } else {
    return <span>Det gick inte att hämta användare.</span>;
  }
};

function mapStateToProps(state: any) {
  return {
    users: state.users,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: {
      loadUsers: bindActionCreators(adminActions.loadUsers, dispatch),
      deleteUser: bindActionCreators(adminActions.deleteUser, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
