import React from 'react';
import moment from "moment";
import './caseheader.scss'


function CaseHeader(props) {
  return (
    <div className={`case-header-wrapper case-type-${props.Case.overviewFields.find(c => c.key === "Ärendetyp").value}`} >

      <div className="case-header-item-wrapper">
        <span>Ärendenummer</span>
        <span>{props.Case.case.number}</span>
        <span>{moment(props.Case.overviewFields.find(c => c.key === "Ärendet skapades").value).format("YYYY-MM-DD")}</span>
      </div>

      <div className="case-header-item-wrapper">
        <span>Ärendetyp</span>
        <span>{props.Case.overviewFields.find(c => c.key === "Ärendetyp").value}</span>
        <span>{props.Case.overviewFields.find(c => c.key === "Regioner").value}</span>
      </div>

      <div className={`case-header-item-wrapper ${!props.Case.isArchived && "case-header-wide-item-wrapper"}`} >
        <span>Beställare</span>
        <span>{props.Case.case.inquirer.organization + ' (' + props.Case.case.inquirer.name + ')'}</span>
        <span></span>
      </div>

      {props.Case.isArchived && (
        <div className="case-header-item-wrapper">
          <span>Åtgärd i Ledningskollen</span>
          <span>{props.Case.caseAnswer.answerDescription}</span>
          <span>{moment(props.Case.caseAnswer.answerTime).format("YYYY-MM-DD HH:mm")}</span>
          <span></span>
        </div>
      )}
    </div>
  )
}

export default CaseHeader;