import React from 'react';
import { useNavigate } from 'react-router-dom';
import './statuschanged.scss';


function StatusChanged(props) {
  let navigate = useNavigate();

  const handleCurrentCasesClick = () => {
    navigate('/AktuellaArenden');
  }

  return (
    <div className='case-acts-status-changed-wrapper'>
      <span>{props.statusMessage}</span>
      <button className='button-primary-large'
        onClick={handleCurrentCasesClick}
      >
        Aktuella ärenden
      </button>
    </div>
  )
}

export default StatusChanged;