import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Stepper from "./stepper/stepper";
import CaseInfo from "./caseinfo/caseinfo";
import ActSelector from "./actselector/actselector";
import SendMaterialByEmail from "./sendmaterial/sendmaterialbyemail";
import SendMaterialByMail from "./sendmaterial/sendmaterialbymail";
import ViewSentMaterialEmails from "./sendmaterial/viewsentmaterialemails";
import UpdateCase from "./updatecase/updatecase";
import StatusChanged from "./statuschanged/statuschanged";
import { getFile, answerCase, resendCase, checkLKStatus, resendManualMark } from "redux/actions/caseactions";
import SpinnerButton from "components/common/spinnerbutton";
import AttachmentValidation from "utils/attachmentvalidation";
import "./casebody.scss";

function CaseBody(props) {
  const [actStep, setActStep] = useState(0);
  const [measure, setMeasure] = useState(0);
  const [comment, setComment] = useState("");
  const [emailTo, setEmailTo] = useState("");
  const [emailCc, setEmailCc] = useState("");
  const [emailFrom, setEmailFrom] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [emailMessageIsHtml, setEmailMessageIsHtml] = useState(false);
  const [emailSubject, setEmailSubject] = useState("");
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [manualAttachedFiles, setManualAttachedFiles] = useState([]);
  const [sendPdf, setSendPdf] = useState(false);
  const [sendGisFile, setSendGisFile] = useState(false);
  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const [downloadingDxf, setDownloadingDxf] = useState(false);
  const [caseArchived, setCaseArchived] = useState(false);
  const [lkStatusMessage, setLKStatusMessage] = useState("");
  const [loadingSelectActPage, setLoadingSelectActPage] = useState(false);
  const [isManualMarkEmail, setIsManualMarkEmail] = useState(false);

  const visitWillOccur = "visit will occur";
  
  let navigate = useNavigate();
  let isMounted = useRef(true);

  function handleManualAttachments(newAttachments) {
    if (newAttachments.length === 0) {
      setManualAttachedFiles(newAttachments);
    } else {
      const manualAttachments = [...manualAttachedFiles, ...newAttachments];

      const validation = new AttachmentValidation(props.Case.attachmentSettings);
      let duplicateWarnings = validation.checkDuplicates(manualAttachedFiles, newAttachments);
      if (duplicateWarnings.length > 0) {
        duplicateWarnings.forEach((duplicateWarning) => {
          toast.warning(duplicateWarning);
        });
        return;
      }
      let validationErrors = validation.validateFiles(manualAttachments);
      if (validationErrors.length > 0) {
        validationErrors.forEach((validationError) => {
          toast.error(validationError);
        });
        return;
      }

      setManualAttachedFiles((prevManualAttachents) => [...prevManualAttachents, ...newAttachments]);
    }
  }

  function handleSelectActClick(e) {
    setLoadingSelectActPage(true);

    checkLKStatus(props.Case.case.number)
      .then(function (lkStatus) {
        if (!isMounted.current) return null;

        if (lkStatus.success) {
          if (!props.Case.manualInquirerEmailTemplate) {
            // Inquirer selected normal mail as preferred contact.
            props.setSendAnswerByMailActive(true);
            setActStep(6);
          } else {
            setMeasure(props.Case.defaultMeasure.id);
            setActStep(1);
            props.setActSelectorActive(true);
          }
        } else {
          // Status was already updated in Ledningskollen.
          setLKStatusMessage(lkStatus.message);
          setActStep(5);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(`Det gick inte att besvara ärende '${props.Case.case.number}'.`);
      })
      .then(() => setLoadingSelectActPage(false));
  }

  function handleActSelectorNextClick(e) {
    var sendEmail = false;
    var isManualMark = false;
    var caseModel = props.Case;
    props.Case.availableMeasures.forEach((am) => {
      if (measure === am.id) {
        sendEmail = am.sendPdf || am.sendGisFile;
        isManualMark = am.measureType === visitWillOccur;
      }
    });

    if (sendEmail) {
      setAttachedFiles(
        props.Case.exportDxfOnReply
          ? [{ name: props.Case.case.number + ".pdf" }, { name: props.Case.case.number + ".dxf" }]
          : [{ name: props.Case.case.number + ".pdf" }]
      );

      var materialEmail = isManualMark ? props.Case.manualMarkerEmailTemplate : props.Case.manualInquirerEmailTemplate;
      setEmailTo(materialEmail.rcptTo);
      setEmailCc(materialEmail.cc);
      setEmailFrom(materialEmail.mailFrom);
      setEmailSubject(materialEmail.mailSubject);
      setEmailMessage(materialEmail.mailBody);
      setEmailMessageIsHtml(materialEmail.mailBodyIsHtml);
      setSendPdf(true);
      setSendGisFile(props.Case.exportDxfOnReply && (!props.Case.isRecipientPrivatePerson || isManualMark));
      setIsManualMarkEmail(isManualMark);
      setActStep(2);
    } else {
      var answerCaseModel = {
        caseNumber: caseModel.case.number,
        measureId: measure,
        comment: comment,
        shouldSendPdf: sendPdf,
        shouldSendGisFile: sendGisFile,
        sendEmail: sendEmail,
      };
      setActStep(4);
      answerCase(answerCaseModel, manualAttachedFiles)
        .then(function () {
          toast.success(`Ärendet '${caseModel.case.number}' har besvarats.`);
          setCaseArchived(true);
        })
        .catch(() => {
          toast.error(`Det gick inte att besvara ärende '${props.Case.case.number}'.`);
          props.setActSelectorActive(false);
          setActStep(0);
        });
    }
  }

  function handleActSelectorCancelClick(e) {
    setMeasure(0);
    setActStep(0);
    props.setActSelectorActive(false);
  }

  function handleSendMaterialNextClick(e) {
    const caseModel = props.Case;
    let manualInquirerEmail = Object.assign({}, caseModel.manualInquirerEmailTemplate);

    manualInquirerEmail.rcptTo = emailTo;
    manualInquirerEmail.cc = emailCc;
    manualInquirerEmail.mailSubject = emailSubject;
    manualInquirerEmail.mailBody = emailMessage;
    manualInquirerEmail.mailBodyIsHtml = emailMessageIsHtml;
    let answerCaseModel = {
      caseNumber: caseModel.case.number,
      measureId: measure,
      comment: comment,
      shouldSendPdf: sendPdf,
      shouldSendGisFile: sendGisFile,
      sendEmail: true,
      caseMaterialEmail: manualInquirerEmail,
    };
    setActStep(4);
    (!props.Case.isArchived
      ? answerCase(answerCaseModel, manualAttachedFiles)
      : isManualMarkEmail
      ? resendManualMark(answerCaseModel, manualAttachedFiles)
      : resendCase(answerCaseModel, manualAttachedFiles) 
    )
      .then(function () {
        if (!props.Case.isArchived) {
          toast.success(`Ärendet '${caseModel.case.number}' har besvarats.`);
        } else {
          toast.success(`Nytt svar har skickats för ärende '${caseModel.case.number}'.`);
        }
        setCaseArchived(true);
      })
      .catch(() => {
        toast.error(`Det gick inte att besvara ärende '${props.Case.case.number}'.`);
        props.setActSelectorActive(false);
        setActStep(0);
      });
  }

  function handleSendMaterialCancelClick() {
    setActStep(0);
    props.setViewSentAnswerActive(false);
    props.setSendAgainActive(false);
    props.setSendAnswerByMailActive(false);
    props.setSendManualMarkActive(false);
  }

  function handleMeasureChanged(e) {
    setMeasure(Number(e.target.value));
  }

  function handleCommentChange(e) {
    setComment(e.target.value);
  }

  function handleEmailToChange(e) {
    setEmailTo(e.target.value);
  }

  function handleEmailCcChange(e) {
    setEmailCc(e.target.value);
  }

  function handleEmailFromChange(e) {
    setEmailFrom(e.target.value);
  }

  function handleEmailSubjectChange(e) {
    setEmailSubject(e.target.value);
  }

  function handleEmailMessageChange(e) {
    setEmailMessage(e.target.value);
  }

  function handleSendAgainClick(e) {
    if (!props.Case.manualInquirerEmailTemplate) {
      // Inquirer selected normal mail as preferred contact.
      props.setSendAnswerByMailActive(true);
      setActStep(6);
    } else {
      setAttachedFiles(
        props.Case.exportDxfOnReply
          ? [{ name: props.Case.case.number + ".pdf" }, { name: props.Case.case.number + ".dxf" }]
          : [{ name: props.Case.case.number + ".pdf" }]
      );
      setEmailTo(props.Case.manualInquirerEmailTemplate.rcptTo);
      setEmailCc(props.Case.manualInquirerEmailTemplate.cc);
      setEmailFrom(props.Case.manualInquirerEmailTemplate.mailFrom);
      setEmailSubject(props.Case.manualInquirerEmailTemplate.mailSubject);
      setEmailMessage(props.Case.manualInquirerEmailTemplate.mailBody);
      setEmailMessageIsHtml(props.Case.manualInquirerEmailTemplate.mailBodyIsHtml);
      setSendPdf(true);
      setSendGisFile(props.Case.exportDxfOnReply && !props.Case.isRecipientPrivatePerson);
      setManualAttachedFiles([]); //User needs to attach files again in case they don't exist on disk
      setIsManualMarkEmail(false);
      setActStep(2);
      props.setSendAgainActive(true);
    }
  }

  function handleViewSentAnswerClick() {
    setActStep(3);
    props.setViewSentAnswerActive(true);
  }

  function handleSendManualMark() {
    setAttachedFiles(
      props.Case.exportDxfOnReply
        ? [{ name: props.Case.case.number + ".pdf" }, { name: props.Case.case.number + ".dxf" }]
        : [{ name: props.Case.case.number + ".pdf" }]
    );

    setEmailTo(props.Case.manualMarkerEmailTemplate.rcptTo);
    setEmailCc(props.Case.manualMarkerEmailTemplate.cc);
    setEmailFrom(props.Case.manualMarkerEmailTemplate.mailFrom);
    setEmailSubject(props.Case.manualMarkerEmailTemplate.mailSubject);
    setEmailMessage(props.Case.manualMarkerEmailTemplate.mailBody);
    setEmailMessageIsHtml(props.Case.manualMarkerEmailTemplate.mailBodyIsHtml);
    setSendPdf(true);
    setSendGisFile(props.Case.exportDxfOnReply);
    setManualAttachedFiles([]); //User needs to attach files again in case they don't exist on disk
    setIsManualMarkEmail(true);
    setActStep(2);
    props.setSendManualMarkActive(true);
  }

  function handleSendPdfChanged(selected) {
    setSendPdf(selected);
  }

  function handleSendGisFileChanged(selected) {
    setSendGisFile(selected);
  }

  function getStepRelevantComponent() {
    switch (actStep) {
      case 0:
        return <CaseInfo Case={props.Case} />;
      case 1:
        return (
          <ActSelector
            Case={props.Case}
            measure={measure}
            handleMeasureChanged={handleMeasureChanged}
            comment={comment}
            handleCommentChange={handleCommentChange}
            handleActSelectorNextClick={handleActSelectorNextClick}
            handleActSelectorCancelClick={handleActSelectorCancelClick}
          />
        );
      // Send material.
      case 2:
        return (
          <SendMaterialByEmail
            caseNumber={props.Case.case.number}
            isPrivateUser={props.Case.isRecipientPrivatePerson}
            isArchived={props.Case.isArchived}
            isManualMarkEmail={isManualMarkEmail}
            emailTo={emailTo}
            handleEmailToChange={handleEmailToChange}
            emailCc={emailCc}
            handleEmailCcChange={handleEmailCcChange}
            emailFrom={emailFrom}
            handleEmailFromChange={handleEmailFromChange}
            emailSubject={emailSubject}
            handleEmailSubjectChange={handleEmailSubjectChange}
            emailMessage={emailMessage}
            emailMessageIsHtml={emailMessageIsHtml}
            handleEmailMessageChange={handleEmailMessageChange}
            attachedFiles={attachedFiles}
            handleSendMaterialNextClick={handleSendMaterialNextClick}
            handleSendMaterialCancelClick={handleSendMaterialCancelClick}
            handleSendFilesChanged={[handleSendPdfChanged, handleSendGisFileChanged]}
            manualAttachedFiles={manualAttachedFiles}
            handleManualAttachmentsChanged={handleManualAttachments}
            attachmentSettings={props.Case.attachmentSettings}
          />
        );
      // View sent material.
      case 3:
        return <ViewSentMaterialEmails caseMaterialEmails={props.Case.caseMaterialEmails} />;
      case 4:
        return <UpdateCase resendCase={props.Case.isArchived} manualMarkEmail={isManualMarkEmail} />;
      case 5:
        return <StatusChanged statusMessage={lkStatusMessage} />;
      case 6:
        return <SendMaterialByMail handleSendMaterialCancelClick={handleSendMaterialCancelClick} />;

      default:
        break;
    }
  }

  function showSentAnswer() {
    return (
      props.Case.caseMaterialEmails &&
      props.Case.caseMaterialEmails.length > 0 &&
      props.Case.caseMaterialEmails[0].sentDate &&
      props.Case.caseMaterialEmails[0].sentDate.ticks !== 0
    );
  }

  function showSendAgain() {
    return props.Case.defaultMeasure && props.Case.defaultMeasure.longDescription;
  }

  function showSendManualMark() {
    return props.Case.manualMarkerEmailTemplate;
  }

  function handleGetPdf() {
    if (!isMounted.current) return;
    setDownloadingPdf(true);
    return getFile(props.Case.case.number, "pdf")
      .then(function () {
        if (!isMounted.current) return null;
        toast.success(`PDF för ärende '${props.Case.case.number}' har laddats ner.`);
      })
      .catch((error) => {
        console.error(error);
        toast.error(`Det gick inte att ladda ner PDF för ärende '${props.Case.case.number}'.`);
      })
      .then(() => setDownloadingPdf(false));
  }

  function handleGetDxf() {
    if (!isMounted.current) return;
    setDownloadingDxf(true);
    return getFile(props.Case.case.number, "dxf")
      .then(function () {
        if (!isMounted.current) return null;
        toast.success(`DXF för ärende '${props.Case.case.number}' har laddats ner.`);
      })
      .catch((error) => {
        console.error(error);
        toast.error(`Det gick inte att ladda ner DXF för ärende '${props.Case.case.number}'.`);
      })
      .then(() => setDownloadingDxf(false));
  }

  useEffect(() => {
    if (actStep > 0 && props.backClick) {
      if (actStep - 1 === 0) {
        handleActSelectorCancelClick(); // Cancel and go back to case view.
      } else if (props.sendAgainActive) {
        props.setSendAgainActive(false);
        setActStep(0); // Go from send material again back to case view.
      } else if (props.viewSentAnswerActive) {
        props.setViewSentAnswerActive(false);
        setActStep(0); // Go from view sent material back to case view.
      } else if (props.sendAnswerByMailActive) {
        props.setSendAnswerByMailActive(false);
        setActStep(0); // Go from view user would like answer by mail back to case view.
      } else if (props.sendManualMarkActive) {
        props.setSendManualMarkActive(false);
        setActStep(0); // Go from view user would like answer by mail back to case view.
      } else {
        setActStep(actStep - 1); // Go back one step.
      }
      props.setBackClick(false);
    }
  }, [actStep, props.backClick, handleActSelectorCancelClick]);

  useEffect(() => {
    if (caseArchived && !props.Case.isArchived) {
      navigate("/AktuellaArenden");
    } else if (caseArchived && props.Case.isArchived) {
      navigate("/ArendeHistorik");
    }
    return () => {
      //Cleanup
      isMounted.current = false;
    };
  }, [caseArchived, props.Case.isArchived, navigate]);

  return (
    <div
      className={`case-body-wrapper case-type-${props.Case.overviewFields.find((c) => c.key === "Ärendetyp").value}`}
    >
      {!props.Case.isArchived && actStep !== 5 && <Stepper step={actStep} />}

      {!props.Case.isArchived && actStep < 1 && (
        <div className="buttons-container">
          <div className="side-buttons-wrapper"></div>
          <div className="case-body-buttons-wrapper">
            <SpinnerButton
              onClick={handleSelectActClick}
              loading={loadingSelectActPage}
              buttonClassName="button-primary"
            >
              Välj Åtgärd
            </SpinnerButton>
            <SpinnerButton
              onClick={handleGetPdf}
              loading={downloadingPdf}
              buttonClassName="button-secondary"
              secondaryButton={true}
            >
              Granska PDF
            </SpinnerButton>
            {props.Case.exportDxfOnReply && (
              <SpinnerButton
                onClick={handleGetDxf}
                loading={downloadingDxf}
                buttonClassName="button-secondary"
                secondaryButton={true}
              >
                Granska DXF
              </SpinnerButton>
            )}
          </div>
          <div className="side-buttons-wrapper">
            <div className="case-body-buttons-right-wrapper"></div>
          </div>
        </div>
      )}
      {props.Case.isArchived && actStep !== 2 && actStep !== 3 && actStep !== 4 && (
        <div className="buttons-container">
          <div className="side-buttons-wrapper"></div>
          <div className="case-body-buttons-wrapper">
            {showSentAnswer() && (
              <button className="button-secondary button-sent-answers" onClick={handleViewSentAnswerClick}>
                Se skickade svar ({props.Case.caseMaterialEmails.length})
              </button>
            )}
            {showSendAgain() && (
              <button className="button-secondary" onClick={handleSendAgainClick}>
                Skicka nytt svar
              </button>
            )}
             {showSendManualMark() && (
            <button className="button-secondary" onClick={handleSendManualMark}>
              Skicka för utsättning
            </button>
             )}
          </div>
          <div className="side-buttons-wrapper">
            <div className="case-body-buttons-right-wrapper"></div>
          </div>
        </div>
      )}
      {getStepRelevantComponent()}
    </div>
  );
}

export default CaseBody;
