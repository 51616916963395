export function storeUser(userName, token, expiry, administrator) {
	if (userName.length > 0 && token.length > 0) {
		const user = {
			userName: userName,
			token: token,
			expiry: expiry,
			administrator: administrator
		};
		localStorage.setItem('user', JSON.stringify(user));
	}
}

export function updateUser(token, expiry) {
	const user = getUser();
	if (user !== null){
		removeUser();
		const updatedUser = {
			userName: user.userName,
			token: token,
			expiry: expiry,
			administrator: user.administrator
		};
		localStorage.setItem('user', JSON.stringify(updatedUser));
	}
}

export function removeUser() {
	localStorage.removeItem('user');
}

export function getUser() {
	return JSON.parse(localStorage.getItem('user'));
}

export function isLoggedIn() {
	const user = getUser()
	if (user) {
		var dateNow = new Date();
		var isExpired = user.expiry <  dateNow.getTime();
		return !isExpired;
	}
	return false;
}
