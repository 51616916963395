import * as types from "./actiontypes";
import { showLoading, hideLoading, showMapLoading, hideMapLoading } from "./loadingactions";
import * as api from "api/caseapi";


export function filterAndSearchCurrentCases(currentCasesFilter, page) {

  return function (dispatch) {
    dispatch(showLoading());
    dispatch(showMapLoading());

		var caseListFilter = {
			purpose: currentCasesFilter.caseTypeFilter,
			text: currentCasesFilter.searchString,
			descending: currentCasesFilter.sortDirection === "Äldsta ärenden överst" ? false : true,
			page: page
		};

    return api.getCases(caseListFilter)
			.then(currentCases => {
				let cases = currentCases.caseData;

				if (page === 1) {
					dispatch(loadCurrentCasesSuccess(cases));
					dispatch(setCurrentCaseListTotalCasesCountSuccess(currentCases.totalCasesCount)); 
					dispatch(setCurrentCaseListPageCountSuccess(currentCases.pageCount));
				}
				else {
					dispatch(loadMoreCurrentCasesSuccess(cases)); 
				}
				dispatch(setCurrentCaseListCurrentPage(page));

				dispatch(hideLoading());
				dispatch(hideMapLoading());
			})
			.catch(error => {
				dispatch(hideLoading());
				dispatch(hideMapLoading());
				throw error;
			});
  }
}

export function loadCurrentCasesSuccess(cases) {
  return { type: types.LOAD_CURRENT_CASES_SUCCESS, cases };
}

export function loadMoreCurrentCasesSuccess(cases) {
  return { type: types.LOAD_MORE_CURRENT_CASES_SUCCESS, cases };
}

export function setSearchValueCurrentCasesFilter(searchString) {
  return { type: types.SET_SEARCH_VALUE_CURRENT_CASES_FILTER, searchString };
}

export function setCaseTypeCurrentCasesFilter(caseTypeFilter) {
  return { type: types.SET_CASE_TYPE_CURRENT_CASES_FILTER, caseTypeFilter };
}

export function setSortDirectionCurrentCasesFilter(sortDirection) {
  return { type: types.SET_SORT_DIRECTION_CURRENT_CASES_FILTER, sortDirection };
}

export function setClearCurrentCasesFilter() {
  return { type: types.CLEAR_CURRENT_CASES_FILTER };
}

export function setCurrentCaseListTotalCasesCountSuccess(totalCasesCount) {
  return { type: types.SET_CURRENT_CASE_LIST_TOTAL_CASES_COUNT_SUCCESS, totalCasesCount };
}

export function setCurrentCaseListPageCountSuccess(pageCount) {
  return { type: types.SET_CURRENT_CASE_LIST_PAGE_COUNT_SUCCESS, pageCount };
}

export function setCurrentCaseListCurrentPage(currentPage) {
  return { type: types.SET_CURRENT_CASE_LIST_CURRENT_PAGE, currentPage };
} 