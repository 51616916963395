import React from "react";
import parse from "html-react-parser";
import "./htmlinforow.scss";

type AppProps = {
  infoObjects : InfoObjectHtml[]
};

type InfoObjectHtml = {
  label: string;
  info: string;
}

const HtmlInfoRow = ({ infoObjects }: AppProps): JSX.Element => {
  return (
    <div className={"case-info-row-wrapper"}>
      {infoObjects.map((io, index) => (
        <div
          className="case-info-row-item-wrapper"
          style={{ flexBasis: `${100 / infoObjects.length}%` }}
          key={index}
        >
          <span>{io.label}</span>
          <span>{parse(io.info)}</span>
        </div>
      ))}
    </div>
  );
};

export default HtmlInfoRow;
