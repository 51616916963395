import * as types from '../actions/actiontypes';
import initialState from './initialstate';


export default function coverageAreasReducer(state = initialState.coverageAreasCases, action) {
  switch (action.type) {
    case types.LOAD_COVERAGE_AREAS_CASES_SUCCESS:
      return action.Cases;
    default:
      return state;
  }
}
