import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getUser } from '../utils/auth'

function RequireAdmin({ children }) {
      
    let isAdmin = false;
      const user = getUser();
      if (user) {
          isAdmin = user.administrator;
      }
  
    let location = useLocation();
  
    if (!isAdmin) {
      return <Navigate to={{ pathname: '../common/pagenotfound', state: { from: location } }} />
    }
  
    return children;
  }

  export default RequireAdmin