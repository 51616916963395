import React from 'react';
import Spinner from "../../../../../components/common/spinner";
import './updatecase.scss';


function UpdateCase(props) {

  return (
    <div className="update-case-wrapper">
      <Spinner />
      {props.manualMarkEmail ? (
        <span>Ärendet skickas för utsättning.</span> 
      ) : (
        props.resendCase ? (
          <span>Nytt svar skickas för ärendet.</span>
        ) : (
          <span>Ärendet arkiveras och statusen uppdateras i Ledningskollen.</span>
        )
      )}
    </div>
  );
}

export default UpdateCase;