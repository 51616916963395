import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isLoggedIn } from "../utils/auth";

function RequireAuth({ children }) {
    let location = useLocation();

    if (!isLoggedIn()) {
        return <Navigate to="/" state={{ from: location }} replace={true} />;
    }
    return children;
}

export default RequireAuth;
