export const getEmailReplyDiv = (htmlString: string): string | undefined => {
  let parser = new DOMParser();
  let doc = parser.parseFromString(htmlString, "application/xml");

  if (doc.querySelector('parsererror')) {
    return htmlString; //plain text
  }

  if (doc.querySelector('#emailReply')) {
    return doc.querySelector('#emailReply')?.outerHTML;
  } else if (doc.querySelector('div')) {
    return doc.querySelector('div')?.outerHTML;
  } else {
    return htmlString;
  }

}
