import { combineReducers } from 'redux';
import currentCases from './currentcasesreducer';
import archivedCases from './archivedcasesreducer';
import coverageAreasCases from './coverageareasreducer';
import currentCasesPaging from './currentcasespagingreducer';
import archivedCasesPaging from './archivedcasespagingreducer';
import caseDetails from './casedetailsreducer';
import login from './userreducer';
import credentials from './userreducer';
import user from './userreducer';
import mapTiles from './mapreducer';
import polygonArea from './mapreducer';
import mapMarkers from './mapreducer';
import settings from './settingsreducer';
import logotype from './settingslogotypereducer';
import users from './usersreducer';
import mapSettings from './mapreducer';
import archivedCasesFilter from './archivedcasesfilterreducer';
import currentCasesFilter from './currentcasesfilterreducer';
import { loading, mapLoading } from './loadingreducer';
import status from './statusreducer';


const rootReducer = combineReducers({
  currentCases,
  archivedCases,
  coverageAreasCases,
  currentCasesPaging,
  archivedCasesPaging,
  caseDetails,
  login,
  user,
  credentials,
  mapTiles,
  mapMarkers,
  polygonArea,
  settings,
  logotype,
  users,
  mapSettings,
  archivedCasesFilter,
  currentCasesFilter,
  loading,
  mapLoading,
  status
});

export default rootReducer;
