import * as types from "./actiontypes";
import { showLoading, hideLoading, showMapLoading, hideMapLoading } from "./loadingactions";
import * as api from "api/caseapi";

export function loadCaseDetails(id, isFetching) {
  return function (dispatch) {
    if (!isFetching.current) return null;
    dispatch(showLoading());
    dispatch(showMapLoading());

    return api
      .getCaseDetails(id)
      .then((caseDetails) => {
        dispatch(loadCaseDetailsSuccess(caseDetails));
        dispatch(hideLoading());
        dispatch(hideMapLoading());
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getAttachment(caseNumber, fileName) {
  return api.getAttachment(caseNumber, fileName).then((blob) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  });
}

export function getFile(caseNumber, fileType) {
  return (fileType === "dxf" ? api.getDxf(caseNumber) : api.getPdf(caseNumber)).then((blob) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Underlag ärende " + caseNumber + "." + fileType);

    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  });
}

export function getArchivedFile(caseNumber, caseMaterialEmailId, archivedFileName) {
  return api.getArchivedFile(caseNumber, caseMaterialEmailId, archivedFileName).then((blob) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", archivedFileName);

    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  });
}

export function answerCase(caseAnswer, files) {
  return api.answerCase(caseAnswer, files);
}

export function resendCase(caseAnswer, files) {
  return api.resendCase(caseAnswer, files);
}

export function resendManualMark(caseAnswer, files) {
  return api.resendManualMark(caseAnswer, files);
}

export function checkLKStatus(caseNumber) {
  return api.checkLKStatus(caseNumber);
}

export function loadCaseDetailsSuccess(caseDetails) {
  return { type: types.LOAD_CASE_DETAILS_SUCCESS, caseDetails };
}
