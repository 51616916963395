import { baseGetAll } from "./apiutils";

export async function getMapSettings() {
  try {
    const response = await baseGetAll("/map/settings");
    return Promise.resolve(response);
  } catch {}
  return false;
}

export function getMapMarkers() {
  const mapMarkers = [];
  return Promise.resolve(mapMarkers);
}
