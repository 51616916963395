import React from 'react';
import './inforow.scss'


function LineBreakText(info) {
  return info.text.split('\n').map((str, index) => 
    <span key={index}>
      {str}
    </span>);
}

export default function InfoRow(props) {
  return (
    <div className={'case-info-row-wrapper'} >
      {props.infoObjects.map((io, index) =>
        <div
          className="case-info-row-item-wrapper"
          style={{ flexBasis: `${100 / props.infoObjects.length}%` }}
          key={index}
        >
          <span>{io.label}</span>
          <LineBreakText text={io.info} />
        </div>
      )}
    </div>
  )
}
