import React, { useState, useRef, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { login } from "../../redux/actions/useractions";
import { isLoggedIn } from "../../utils/auth";
import { Container } from "reactstrap";
import TextInput from "../../components/common/textinput";
import SpinnerButton from "../../components/common/spinnerbutton";
import logo from "../../assets/logo.png";
import "./login.scss";
import { UnauthorizedError } from "../../api/responseerrors";

function Login(props) {
  const username = useRef("");
  const password = useRef("");
  const [errors, setErrors] = useState({});
  const [signingIn, setSigningIn] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();

  const handleUsernameChange = (event) => {
    username.current = event.target.value;
    setErrors((prevErrors) => ({
      ...prevErrors,
      username: "",
    }));
  };

  const handlePasswordChange = (event) => {
    password.current = event.target.value;
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: "",
    }));
  };

  const formIsValid = () => {
    const inputErrors = {};

    if (!username.current) inputErrors.username = "Vänligen ange användarnamn.";
    if (!password.current) inputErrors.password = "Vänligen ange lösenord.";

    setErrors(inputErrors);
    // Form is valid if the errors object still has no properties
    return Object.keys(inputErrors).length === 0;
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    if (!formIsValid()) return;
    setSigningIn(true);
  };

  useEffect(() => {
    const actions = props.actions;
    if (signingIn) {
      try {
        actions
          .login(username.current, password.current)
          .then(() => {
            if (location.state?.from) {
              navigate(location.state.from);
            } else {
              navigate("/AktuellaArenden");
            }
          })
          .catch((error) => {
            if (error instanceof UnauthorizedError) {
              setErrors({ unAuthorized: "Användarnamn eller lösenord är felaktigt, var god försök igen." });
            } else {
              setErrors({ unAuthorized: "Något gick fel. Var god försök igen." });
            }
          })
          .then(() => setSigningIn(false));
      } catch (e) {
        setSigningIn(false);
        console.error("Failed to login: " + e.toString());
      }
    }
  }, [props, signingIn, navigate, location.state?.from]);

  return isLoggedIn() ? (
    <Navigate to={{ pathname: "/AktuellaArenden", state: { from: location } }}></Navigate>
  ) : (
    renderLogin()
  );

  function renderLogin() {
    return (
      <Container style={{ paddingTop: "22vh", PaddingLeft: "7vw" }}>
        <form onSubmit={handleSignIn}>
          {errors.unAuthorized && (
            <div className="alert alert-danger" role="alert">
              {errors.unAuthorized}
            </div>
          )}
          <img src={logo} alt="Logo" className="decerno-logo" />
          <div className="incheck-logo">InCheck</div>
          <TextInput
            name="username"
            label="Användarnamn"
            onChange={handleUsernameChange}
            placeholder={""}
            error={errors.username}
            inputClass="login-username"
            wrapperClass="login-field-margin"
            autoFocus={true}
          />
          <TextInput
            name="password"
            label="Lösenord"
            inputType="password"
            onChange={handlePasswordChange}
            placeholder={""}
            error={errors.password}
            inputClass="login-password"
          />
          <SpinnerButton loading={signingIn} buttonClassName="button-primary-large">
            Logga in
          </SpinnerButton>
          <span className="login-help-row">
            Problem att logga in? <a href="mailto:support@decerno.se">Kontakta supporten</a>
          </span>
        </form>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(login, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
