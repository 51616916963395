import React, { Fragment, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import EmailField from "components/common/emailfield";
import TextInput from "components/common/textinput";
import SpinnerButton from "components/common/spinnerbutton";
import * as adminActions from "redux/actions/adminactions";
import { validEmailAddress } from "utils/validation";

function Email(props) {
  const [emailTemplateManualMarkerSubject, setEmailTemplateManualMarkerSubject] = useState("");
  const [emailTemplateManualMarkerText, setEmailTemplateManualMarkerText] = useState("");
  const [emailTemplateAutoSendMaterialSubject, setEmailTemplateAutoSendMaterialSubject] = useState("");
  const [emailTemplateAutoSendMaterialEmailText, setEmailTemplateAutoSendMaterialEmailText] = useState("");
  const [emailTemplateManualAnswerSubject, setEmailTemplateManualAnswerSubject] = useState("");
  const [emailTemplateManualAnswerText, setEmailTemplateManualAnswerText] = useState("");
  const [emailRecipientManualMark, setEmailRecipientManualMark] = useState("");
  const [emailRecipientManualMarkErrorMessage, setEmailRecipientManualMarkErrorMessage] = useState("");
  const [savingSettings, setSavingSettings] = useState(false);
  const { actions } = props;

  useEffect(() => {
    if (props.emailSettings) {
      setEmailTemplateManualMarkerSubject(props.emailSettings.caseAnswerManualMarkEmailSubject);
      setEmailTemplateManualMarkerText(props.emailSettings.caseAnswerManualMarkEmailText);
      setEmailTemplateAutoSendMaterialSubject(props.emailSettings.autoSendMaterialSubject);
      setEmailTemplateAutoSendMaterialEmailText(props.emailSettings.autoSendMaterialEmailText);
      setEmailTemplateManualAnswerSubject(props.emailSettings.caseAnswerInquirerEmailSubject);
      setEmailTemplateManualAnswerText(props.emailSettings.caseAnswerInquirerEmailText);
      setEmailRecipientManualMark(props.emailSettings.emailRecipientManualMark);
      setSavingSettings(false);
    }
  }, [props.emailSettings]);

  function handleEmailTemplateManualMarkerSubjectChange(event) {
    setEmailTemplateManualMarkerSubject(event.target.value);
  }

  function handleEmailTemplateManualMarkerTextChange(event) {
    setEmailTemplateManualMarkerText(event.target.value);
  }

  function handleEmailTemplateAutoSendMaterialSubjectChange(event) {
    setEmailTemplateAutoSendMaterialSubject(event.target.value);
  }

  function handleEmailTemplateAutoSendMaterialEmailTextChange(event) {
    setEmailTemplateAutoSendMaterialEmailText(event.target.value);
  }

  function handleEmailTemplateManualAnswerSubjectChange(event) {
    setEmailTemplateManualAnswerSubject(event.target.value);
  }

  function handleEmailTemplateManualAnswerTextChange(event) {
    setEmailTemplateManualAnswerText(event.target.value);
  }

  function handleEmailRecipientManualMarkChange(event) {
    setEmailRecipientManualMark(event.target.value);
    setEmailRecipientManualMarkErrorMessage("");
  }

  const validateInputFields = () => {
    let result = true;

    if (emailRecipientManualMark !== "" && !validEmailAddress(emailRecipientManualMark)) {
      setEmailRecipientManualMarkErrorMessage("E-postadressen är inte giltig.");
      result = false;
    }

    return result;
  }

  function handleSaveClick() {
    if (validateInputFields()) {
      setSavingSettings(true);
      let emailSettings = { emailSettings: props.emailSettings };
      var updatedSettings = JSON.parse(JSON.stringify(emailSettings));
      updatedSettings.emailSettings.caseAnswerManualMarkEmailSubject = emailTemplateManualMarkerSubject;
      updatedSettings.emailSettings.caseAnswerManualMarkEmailText = emailTemplateManualMarkerText;
      updatedSettings.emailSettings.autoSendMaterialSubject = emailTemplateAutoSendMaterialSubject;
      updatedSettings.emailSettings.autoSendMaterialEmailText = emailTemplateAutoSendMaterialEmailText;
      updatedSettings.emailSettings.caseAnswerInquirerEmailSubject = emailTemplateManualAnswerSubject;
      updatedSettings.emailSettings.caseAnswerInquirerEmailText = emailTemplateManualAnswerText;
      updatedSettings.emailSettings.emailRecipientManualMark = emailRecipientManualMark;
      actions
        .saveSettings(updatedSettings)
        .then(function () {
          toast.success("Inställningarna har sparats.");
        })
        .catch((error) => {
          console.error(error);
          toast.error("Det gick inte att spara inställningarna.");
        });
    } else {
      toast.error("Vänligen fyll i samtliga fält korrekt.");
    }
    setSavingSettings(false)
  }

  if (props.emailSettings) {
    return (
      <Fragment>
        <h4 className="settings-title">E-postinställningar</h4>

        <h6 className="settings-label">E-postmall för utsättare</h6>
        <EmailField
          label="Ämne"
          value={emailTemplateManualMarkerSubject}
          onChange={handleEmailTemplateManualMarkerSubjectChange}
        />
        <div className="settings-seperator" />
        <textarea value={emailTemplateManualMarkerText} onChange={handleEmailTemplateManualMarkerTextChange} />

        <h6 className="settings-label">E-postmall för autosvar</h6>
        <EmailField
          label="Ämne"
          value={emailTemplateAutoSendMaterialSubject}
          onChange={handleEmailTemplateAutoSendMaterialSubjectChange}
        />
        <div className="settings-seperator" />
        <textarea
          value={emailTemplateAutoSendMaterialEmailText}
          onChange={handleEmailTemplateAutoSendMaterialEmailTextChange}
        />

        <h6 className="settings-label">E-postmall för manuella svar</h6>
        <EmailField
          label="Ämne"
          value={emailTemplateManualAnswerSubject}
          onChange={handleEmailTemplateManualAnswerSubjectChange}
        />
        <div className="settings-seperator" />
        <textarea value={emailTemplateManualAnswerText} onChange={handleEmailTemplateManualAnswerTextChange} />

        <TextInput
          name="email"
          label="E-post till utsättare"
          value={emailRecipientManualMark}
          inputType="email"
          onChange={handleEmailRecipientManualMarkChange}
          placeholder={""}
          error={emailRecipientManualMarkErrorMessage}
          wrapperClass="settings-label"
        />

        <SpinnerButton onClick={handleSaveClick} loading={savingSettings} buttonClassName="button-primary-large">
          Spara
        </SpinnerButton>
      </Fragment>
    );
  } else {
    return <span>Det gick inte att hämta inställningar.</span>;
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveSettings: bindActionCreators(adminActions.saveSettings, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Email);
