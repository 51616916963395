import React from 'react';
import { MdRadioButtonUnchecked, MdLens } from "react-icons/md";
import './stepper.scss';


function getSteps() {
  return ['Välj åtgärd', 'Skicka underlag', 'Arkivera ärende'];
}

export default function Stepper(props) {
  return (
    <div className={`case-stepper-wrapper`} >

      <div className="case-stepper-labels-wrapper">
        {getSteps().map(label => (
          <span key={label} className='case-stepper-label'>
            {label}
          </span>
        ))}
      </div>

      <div className="case-stepper-icons-wrapper">
        {props.step > 0 ?
          <MdLens className='case-stepper-icon' />
          :
          <MdRadioButtonUnchecked className='case-stepper-icon' />
        }
        <span className="case-stepper-sperator"></span>
        {props.step > 1 ?
          <MdLens className='case-stepper-icon' />
          :
          <MdRadioButtonUnchecked className='case-stepper-icon' />
        }
        <span className="case-stepper-sperator"></span>
        {props.step > 2 ?
          <MdLens className='case-stepper-icon' />
          :
          <MdRadioButtonUnchecked className='case-stepper-icon' />
        }
      </div>

    </div>
  )
}