import * as types from '../actions/actiontypes';
import initialState from './initialstate';


export default function settingsLogotypeReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_LOGOTYPE_FILE_SUCCESS:
      return action.logotype;
    case types.SAVE_LOGOTYPE_FILE_SUCCESS:
      return action.logotype;
    default:
      return state;
  }
}