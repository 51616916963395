import {getUser, updateUser, isLoggedIn} from '../utils/auth'
import {BadRequestError, UnauthorizedError, ConflictError, NotFoundError} from './responseerrors';


const baseUrl = process.env.REACT_APP_API_URL;

export function getBaseUrl() {
  return baseUrl;
}

export function getHeaders(contentType = "application/json") {
  var user = getUser();
  var token = "";
  if (user) {
    token = user.token;
  } 
  return {
    'Accept': contentType,
    'Content-Type': contentType,
    'Authorization': 'bearer ' + token
  }
}

export function getAuthorizationHeader() {
  var user = getUser();
  var token = "";
  if (user) {
    token = user.token;
  }
    
  return {
    'Authorization': 'bearer ' + token
  }
}

export async function checkIsLoggedIn() {
  if (!isLoggedIn()) {
    var user = getUser();
    if (user) {
      var isRefreshed = await refreshLogin(user.token);
      return isRefreshed;
    }
    return false;
  }
  return true;
}

async function refreshLogin(token) {
  try {
    const response = await basePost({Token: token}, 
      "/auth/refresh", false);

    if (response.success) {
      updateUser(response.token, response.expiry);
      return true;
    }
  }
  catch (error) {
    if (error instanceof UnauthorizedError) {
      window.location = window.location.protocol + "//" + window.location.host;
    }
    throw error;
  }
  return false;
}

export async function handleResponse(response) {
  if (response.ok) return await response.json();

  let message;

  if (response.bodyUsed) {
    const error = await response.json();
    message = error?.message ?? "Unspecified error";
  } else {
    message = response.statusText;
  }

  switch (response.status) {
    case 400:
      throw new BadRequestError(message);
    case 401:
      throw new UnauthorizedError(message);
    case 404:
      throw new NotFoundError(message);
    case 409:
      throw new ConflictError(message);
    default:
        throw new Error("Network response was not ok.");
  }
}

export async function handleResponseFile(response) {
  if (response.ok) return await response.blob();

  const error = await response.json();
  const message = error?.message ?? 'Unspecified error';

  switch (response.status) {
    case 400:
      throw new BadRequestError(message);
    case 401:
      throw new UnauthorizedError(message);
    case 404:
      throw new NotFoundError(message);
    case 409:
      throw new ConflictError(message);
    default:
      throw new Error("Network response was not ok.");
  }
}

// In the future, this would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}

export async function baseGetAll(url, parameters = '') {
  if (await checkIsLoggedIn()) {
    return fetch((`${baseUrl}` + url + parameters), {
      method: 'GET',
      headers: getHeaders()
    })
			.then(handleResponse)
			.catch(handleError);
  }
  return false;
}

export async function baseGet(objektId, url, parameters = '') {
  if (await checkIsLoggedIn()) {
		return fetch((`${baseUrl}` + url + objektId + parameters), {
			method: 'GET',
			headers: getHeaders()
		})
			.then(handleResponse)
			.catch(handleError);
  }
  return false;
}

export async function baseGetFile(
    objektId,
    url,
    parameters = '',
    queryString = '',
    contentType = "application/octet-stream"
) {
    if (await checkIsLoggedIn()) {
        return fetch(`${baseUrl}` + url + objektId + parameters + queryString, {
            method: 'GET',
            headers: getHeaders(contentType),
        })
            .then(handleResponseFile)
            .catch(handleError);
    }
    return false;
}

export async function baseGetCsvFile(
  postBody,
  url
) {
  if (await checkIsLoggedIn()) {
      return fetch(`${baseUrl}` + url, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify(postBody)
      })
          .then(handleResponseFile)
          .catch(handleError);
  }
  return false;
}

export async function baseGetImageFile(url, parameters = '') {
  await checkIsLoggedIn();

  return fetch((`${baseUrl}` + url + parameters), {
    method: 'GET',
    headers: getHeaders("image/*")
  })
    .then(handleResponseFile)
    .catch(handleError);
};

export async function basePost(postBody, url, checkLoggedIn = true) {
  if (checkLoggedIn) {
    if (await checkIsLoggedIn()) {
      return fetch((`${baseUrl}` + url), {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(postBody)
      })
        .then(handleResponse)
        .catch(handleError);
    }
    return false;
  }
  return fetch((`${baseUrl}` + url), {
    method: 'POST',
    credentials: 'include',
    headers: getHeaders(),
    body: JSON.stringify(postBody)
  })
		.then(handleResponse)
		.catch(handleError);
}

export async function basePostImageFile(postBody, url, checkLoggedIn = true) {
  if (checkLoggedIn) {
    await checkIsLoggedIn();
  }

  var user = getUser();
  var token = "";
  if (user) {
    token = user.token;
  } 

  return fetch((`${baseUrl}` + url), {
    method: 'POST',
    headers: { 'Authorization': 'bearer ' + token },
    body: postBody
  })
    .then(handleResponse)
    .catch(handleError);
};

export async function basePostFormData(formData, url, checkLoggedIn = true) {
  if (checkLoggedIn) {
    if (await checkIsLoggedIn()) {
      return fetch((`${baseUrl}` + url), {
        method: 'POST',
        headers: getAuthorizationHeader(),
        body: formData
      })
        .then(handleResponse)
        .catch(handleError);
    }
    return false;
  }
  return fetch((`${baseUrl}` + url), {
    method: 'POST',
    credentials: 'include',
    headers: getHeaders(),
    body: formData
  })
		.then(handleResponse)
		.catch(handleError);
}

export async function basePut(objektId, putBody, url) {
  if (await checkIsLoggedIn()) {
    return fetch((`${baseUrl}` + url + objektId), {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(putBody)
    })
      .then(handleResponse)
      .catch(handleError); 
  }
  return false;
}

export async function baseDel(objektId, url, checkLoggedIn = true) {
  if (checkLoggedIn) {
    if (await checkIsLoggedIn()) {
      return fetch((`${baseUrl}` + url + objektId), {
        method: 'DELETE',
        headers: getHeaders()
      })
				.then(handleResponse)
				.catch(handleError);
    }
    return false;
  }
  return fetch((`${baseUrl}` + url + objektId), {
     method: 'DELETE',
     credentials: 'include',
     headers: getHeaders()
  })
		.then(handleResponse)
		.catch(handleError);
}
