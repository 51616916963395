import * as types from "./actiontypes";
import { showMapLoading, hideMapLoading } from "./loadingactions";
import * as api from "api/mapapi";

export function loadMapSettings() {
	return function (dispatch) {
		dispatch(showMapLoading());

		return api.getMapSettings()
			.then(mapSettings => {
				dispatch(loadMapSettingsSuccess(mapSettings));
				dispatch(hideMapLoading());
			})
			.catch(error => {
				throw error;
			});
	};
}

export function loadMapSettingsSuccess(mapSettings) {
	return { type: types.LOAD_MAP_SETTINGS_SUCCESS, mapSettings }
}