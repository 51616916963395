import * as types from "./actiontypes";


export function showLoading() {
	return { type: types.SHOW_LOADING };
}

export function hideLoading() {
	return { type: types.HIDE_LOADING };
}

export function showMapLoading() {
	return { type: types.SHOW_MAP_LOADING };
}

export function hideMapLoading() {
	return { type: types.HIDE_MAP_LOADING };
}