import React, { useState, useEffect, useRef, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import TextInput from "components/common/textinput";
import Toggler from "components/common/toggler";
import SpinnerButton from "components/common/spinnerbutton";
import * as adminActions from "redux/actions/adminactions";
import { BadRequestError } from "api/responseerrors";

function CaseMaterial(props) {
  const [logotypePreview, setLogotypePreview] = useState(null);
  const [logotypeFile, setLogotypeFile] = useState();
  const [warningText, setWarningText] = useState("");
  const [validDays, setValidDays] = useState(30);
  const [useValidDays, setUseValidDays] = useState(false);
  const [staticConditions, setStaticConditions] = useState("");
  const [conditionsInformation, setConditionsInformation] = useState("");
  const [authorityInformation, setAuthorityInformation] = useState("");
  const [showNameInMapLegend, setShowNameInMapLegend] = useState(false);
  const [includeCableMetadataTables, setIncludeCableMetadataTables] = useState(false);
  const [savingSettings, setSavingSettings] = useState(false);
  //const [bufferSize, setBufferSize] = useState(50);
  const { actions } = props;

  const HandleSelectLogotype = () => {
    const logotypeRef = useRef();

    const showOpenFileDialog = () => {
      logotypeRef.current.click();
    };

    const handleChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setLogotypeFile(file);
      }
    };

    useEffect(() => {
      actions.loadLogotypeFile().catch(() => {
        toast.error(`Logotypen kunde inte hämtas`);
      });
      setSavingSettings(false);
    }, []);

    //TODO: Look into a warning here
    // "React Hook useEffect has unnecessary dependencies: 'logotypeFile' and 'props.logotype'"
    useEffect(() => {
      if (logotypeFile) {
        const objectURL = URL.createObjectURL(logotypeFile);
        setLogotypePreview(objectURL);
        return () => URL.revokeObjectURL(objectURL);
      } else if (props.logotype) {
        const objectURL = URL.createObjectURL(props.logotype);
        setLogotypePreview(objectURL);
        return () => URL.revokeObjectURL(objectURL);
      }
      setSavingSettings(false);
    }, [logotypeFile, props.logotype]);

    return {
      logotypeRef,
      showOpenFileDialog,
      handleChange,
    };
  };

  const { logotypeRef, showOpenFileDialog, handleChange } = HandleSelectLogotype();

  useEffect(() => {
    if (props.caseMaterialSettings) {
      setWarningText(props.caseMaterialSettings.workAreaWarningText);
      setValidDays(props.caseMaterialSettings.caseMaterialValidityDays);
      setUseValidDays(props.caseMaterialSettings.useCaseMaterialValidityDays);
      setStaticConditions(props.caseMaterialSettings.caseMaterialStaticConditions);
      setConditionsInformation(props.caseMaterialSettings.caseMaterialConditionsInformation);
      setAuthorityInformation(props.caseMaterialSettings.caseMaterialAuthorityInformation);
      setShowNameInMapLegend(props.caseMaterialSettings.showNetworkLayerTextsInLegend);
      setIncludeCableMetadataTables(props.caseMaterialSettings.includeCableMetadataTables);
      setSavingSettings(false);
    }
  }, [props.caseMaterialSettings]);

  const handleWarningTextChange = (e) => {
    setWarningText(e.target.value);
  };

  const handleValidDaysChange = (e) => {
    setValidDays(e.target.value);
  };

  const handleUseValidDaysChange = (e) => {
    setUseValidDays(e.target.checked);
  };

  const handleStaticConditionsChange = (e) => {
    setStaticConditions(e.target.value);
  };

  const handleConditionsInformationChange = (e) => {
    setConditionsInformation(e.target.value);
  };

  const handleAuthorityInformationChange = (e) => {
    setAuthorityInformation(e.target.value);
  };

  const handleShowNameInMapLegend = (e) => {
    setShowNameInMapLegend(e.target.checked);
  };

  const handleIncludeCableMetadataTables = (e) => {
    setIncludeCableMetadataTables(e.target.checked);
  };

  // const handleBufferSizeChange = (e) => {
  //   setBufferSize(e.target.value);
  // }

  const handleSaveClick = (e) => {
    setSavingSettings(true);
    let caseMaterialSettings = { caseMaterialSettings: props.caseMaterialSettings };
    var updatedSettings = JSON.parse(JSON.stringify(caseMaterialSettings));
    updatedSettings.caseMaterialSettings.workAreaWarningText = warningText;
    updatedSettings.caseMaterialSettings.caseMaterialValidityDays = validDays;
    updatedSettings.caseMaterialSettings.useCaseMaterialValidityDays = useValidDays;
    updatedSettings.caseMaterialSettings.caseMaterialStaticConditions = staticConditions;
    updatedSettings.caseMaterialSettings.caseMaterialConditionsInformation = conditionsInformation;
    updatedSettings.caseMaterialSettings.caseMaterialAuthorityInformation = authorityInformation;
    updatedSettings.caseMaterialSettings.showNetworkLayerTextsInLegend = showNameInMapLegend;
    updatedSettings.caseMaterialSettings.includeCableMetadataTables = includeCableMetadataTables;
    actions
      .saveSettings(updatedSettings)
      .then(function () {
        if (logotypeFile) {
          actions
            .saveLogotypeFile(logotypeFile)
            .then(() => {
              toast.success("Inställningarna har sparats.");
            })
            .catch((error) => {
              console.error(error);
              let msg = error instanceof BadRequestError ? `\n${error.message}` : "";
              toast.error("Det gick inte att spara logotypen." + msg);
            });
        } else {
          toast.success("Inställningarna har sparats.");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Det gick inte att spara inställningarna.");
      })
      .then(() => setSavingSettings(false));
  };

  if (props.caseMaterialSettings) {
    return (
      <Fragment>
        <h4 className="settings-title">Underlag</h4>

        <h6 className="settings-label">Logotyp</h6>
        <div className="settings-help-text">
          <FaInfoCircle />
          <span>
            Ladda upp en ny logotyp som kommer användas i genererade PDF-underlag.
            <br />
            Bilden måste vara minst 50x10px och maximalt 1024x768px.
            <br />
            Giltiga filformat är .jpg eller .png.
          </span>
        </div>
        <div>
          <button className="button-primary button-align-left" onClick={showOpenFileDialog}>
            Välj fil
          </button>
          <input
            ref={logotypeRef}
            type="file"
            style={{ display: "none" }}
            accept="image/png, image/jpeg"
            onChange={handleChange}
          />
          {logotypePreview && (
            <img src={logotypePreview} alt="Förhandsvisning av vald logotyp" className="logotype-image-preview" />
          )}
        </div>

        <h6 className="settings-label">Varningstext för kartor i underlaget</h6>
        <textarea value={warningText} onChange={handleWarningTextChange} />

        <h6 className="settings-label">Giltlighetstid</h6>
        <div className="settings-toggler-wrapper">
          <Toggler
            label="Av / På"
            checked={useValidDays}
            onChange={handleUseValidDaysChange}
            name="settings-material-validity"
          />
          <TextInput
            name="validDays"
            label="Antal dagar"
            value={validDays.toString()}
            inputType="number"
            onChange={handleValidDaysChange}
            placeholder={""}
            error={""}
            inputClass="settings-numerical-input"
            wrapperClass="settings-valid-days"
          />
        </div>

        <h6 className="settings-label">Villkor och riktlinjer</h6>
        <textarea value={staticConditions} onChange={handleStaticConditionsChange} />

        <h6 className="settings-label">Följande villkor gäller för mottagen information</h6>
        <div className="settings-help-text">
          <FaInfoCircle />
          <span>Separera villkor med | för att skapa en punktlista.</span>
        </div>
        <textarea value={conditionsInformation} onChange={handleConditionsInformationChange} />

        <h6 className="settings-label">Om mottagaren är en myndighet</h6>
        <textarea value={authorityInformation} onChange={handleAuthorityInformationChange} />

        {/* TODO: Same setting on the buffer settings page. */}
        {/* <TextInput
          name="buffer-size"
          label='Buffertstorlek i meter för maskning av ledningsnät'
          value={bufferSize}
          onChange={handleBufferSizeChange}
          placeholder={''}
          error={''}
          inputClass='settings-input'
          wrapperClass='settings-label'
        /> */}

        <div className="settings-label">
          <Toggler
            label="Inkludera namn och beskrivning för ledningsnät i kartlegend"
            checked={showNameInMapLegend}
            onChange={handleShowNameInMapLegend}
            name="settings-material-include-name"
          />
        </div>
        {/* To be used in the future... */}
        {/* <div className="settings-label">
          <Toggler
            label="Inkludera tabeller med kablarnas metadata"
            checked={includeCableMetadataTables}
            onChange={handleIncludeCableMetadataTables}
            name="settings-material-include-name"
          />
        </div> */}

        <SpinnerButton onClick={handleSaveClick} loading={savingSettings} buttonClassName="button-primary-large">
          Spara
        </SpinnerButton>
      </Fragment>
    );
  } else {
    return <span>Det gick inte att hämta inställningar.</span>;
  }
}

function mapStateToProps(state) {
  return {
    logotype: state.logotype,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveSettings: bindActionCreators(adminActions.saveSettings, dispatch),
      saveLogotypeFile: bindActionCreators(adminActions.saveLogotypeFile, dispatch),
      loadLogotypeFile: bindActionCreators(adminActions.loadLogotypeFile, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseMaterial);
