import * as types from '../actions/actiontypes';
import initialState from './initialstate';


export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN:
      return action.login;
    case types.LOGOUT:
        return action.logout;
    case types.SET_USER_CREDENTIALS:
      return action.credentials;
    case types.GET_USER_CREDENTIALS:
      return action.credentials;
    default:
      return state;
  }
}

