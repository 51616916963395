import React, { Fragment, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Toggler from "components/common/toggler";
import TextInput from "components/common/textinput";
import SpinnerButton from "components/common/spinnerbutton";
import * as adminActions from "redux/actions/adminactions";

function AutoAnswer(props) {
  const [autoSendMaterialForLedningsanvisning, setAutoSendMaterialForLedningsanvisning] = useState(false);
  const [autoSendMaterialForProjektering, setAutoSendMaterialForProjektering] = useState(false);
  const [maxArea, setMaxArea] = useState(100);
  const [maxNumberGeometries, setMaxNumberGeometries] = useState(100);
  const [maxBoundingBoxSide, setMaxBoundingBoxSide] = useState(100);
  const [savingSettings, setSavingSettings] = useState(false);
  const { actions } = props;

  useEffect(() => {
    if (props.autoAnswerSettings) {
      setAutoSendMaterialForLedningsanvisning(props.autoAnswerSettings.autoSendMaterialForLedningsanvisning);
      setAutoSendMaterialForProjektering(props.autoAnswerSettings.autoSendMaterialForProjektering);
      setMaxArea(props.autoAnswerSettings.autoSendMaterialMaxArea);
      setMaxNumberGeometries(props.autoAnswerSettings.autoSendMaterialMaxGeometries);
      setMaxBoundingBoxSide(props.autoAnswerSettings.autoSendMaterialMaxBoundingBoxSide);
      setSavingSettings(false);
    }
  }, [props.autoAnswerSettings]);

  function handleAutoSendMaterialForLedningsanvisningChange(event) {
    setAutoSendMaterialForLedningsanvisning(event.target.checked);
  }

  function handleAutoSendMaterialForProjekteringChange(event) {
    setAutoSendMaterialForProjektering(event.target.checked);
  }

  function handleMaxAreaChange(event) {
    setMaxArea(event.target.value);
  }

  function handleMaxNumberGeometriesChange(event) {
    setMaxNumberGeometries(event.target.value);
  }

  function handleMaxBoundingBoxSideChange(event) {
    setMaxBoundingBoxSide(event.target.value);
  }

  function handleSaveClick() {
    setSavingSettings(true);
    let autoAnswerSettings = { autoAnswerSettings: props.autoAnswerSettings };
    var updatedSettings = JSON.parse(JSON.stringify(autoAnswerSettings));
    updatedSettings.autoAnswerSettings.autoSendMaterialForLedningsanvisning = autoSendMaterialForLedningsanvisning;
    updatedSettings.autoAnswerSettings.autoSendMaterialForProjektering = autoSendMaterialForProjektering;
    updatedSettings.autoAnswerSettings.autoSendMaterialMaxArea = maxArea;
    updatedSettings.autoAnswerSettings.autoSendMaterialMaxGeometries = maxNumberGeometries;
    updatedSettings.autoAnswerSettings.autoSendMaterialMaxBoundingBoxSide = maxBoundingBoxSide;
    actions
      .saveSettings(updatedSettings)
      .then(function () {
        toast.success("Inställningarna har sparats.");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Det gick inte att spara inställningarna.");
      })
      .then(() => setSavingSettings(false));
  }

  if (props.autoAnswerSettings) {
    return (
      <Fragment>
        <h4 className="settings-title">Autosvar</h4>

        <div className="settings-label">
          <Toggler
            label="Skicka underlag automatiskt för Ledningsanvisningsärenden"
            checked={autoSendMaterialForLedningsanvisning}
            onChange={handleAutoSendMaterialForLedningsanvisningChange}
          />
        </div>

        <div className="settings-label">
          <Toggler
            label="Skicka underlag automatiskt för Projekteringsärenden"
            checked={autoSendMaterialForProjektering}
            onChange={handleAutoSendMaterialForProjekteringChange}
          />
        </div>

        <TextInput
          name="bufferSizeMap"
          label="Maximal area i kvadratmeter för varje enskilt grävområde som tillhör ett ärende som kan automatbesvaras"
          value={maxArea.toString()}
          inputType="number"
          onChange={handleMaxAreaChange}
          placeholder={""}
          error={""}
          inputClass="settings-numerical-input"
          wrapperClass="settings-label"
        />

        <TextInput
          name="bufferSizeMap"
          label="Maximalt antal geometrier för autosvar"
          value={maxNumberGeometries.toString()}
          inputType="number"
          onChange={handleMaxNumberGeometriesChange}
          placeholder={""}
          error={""}
          inputClass="settings-numerical-input"
          wrapperClass="settings-label"
        />

        <TextInput
          name="bufferSizeMap"
          label="Maximal längd i meter för autosvar på en sida på den geometriska ruta som täcker ärendets samtliga grävområden"
          value={maxBoundingBoxSide.toString()}
          inputType="number"
          onChange={handleMaxBoundingBoxSideChange}
          placeholder={""}
          error={""}
          inputClass="settings-numerical-input"
          wrapperClass="settings-label"
        />

        <SpinnerButton onClick={handleSaveClick} loading={savingSettings} buttonClassName="button-primary-large">
          Spara
        </SpinnerButton>
      </Fragment>
    );
  } else {
    return <span>Det gick inte att hämta inställningar.</span>;
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveSettings: bindActionCreators(adminActions.saveSettings, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoAnswer);
