import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import './typefilter.scss';


export default function TypeFilter(props) {

  document.addEventListener('click', function(e) {
    if (e.target.closest(".side-dropdown-content") || e.target.closest(".side-dropdown")) {
      return;
    }
    else {
      try {
        document.getElementsByClassName('side-dropdown-content')[0].style.display = 'none';
      }
      catch (e) {
      }
    } 
  })

  // const filter = (e) => {
    
  //   e.currentTarget.style.display = 'none';
  //   props.handleTypeChange(e);
  // }

  const toggleFilterMenu = (e) => {
    let target = e.currentTarget.querySelector('.side-dropdown-content');
    
    if (e.target.className === 'side-dropdown-item' || e.target.className === 'side-dropdown-item-span') {
      target.style.display = 'none';
      props.handleTypeChange(e);
    }
    else {
      if (target.style.display === 'none' || target.style.display === '') {
        target.style.display = 'block';
      }
      else if (target.style.display === 'block') {
        target.style.display = 'none';
      }
    }
  }

  return (
    <div className="side-dropdown-wrapper">
      <div className="side-dropdown" onClick={e => toggleFilterMenu(e)}>
        <div className="side-dropdown-header"><span>{props.CaseTypeValue}</span></div>
        <FiChevronDown className='side-dropdown-icon' />
        <div className="side-dropdown-content"> 
          <div className='side-dropdown-item'><span className='side-dropdown-item-span'>Alla</span></div>
          <div className='side-dropdown-item'><span className='side-dropdown-item-span'>Ledningsanvisning</span></div>
          <div className='side-dropdown-item'><span className='side-dropdown-item-span'>Projektering</span></div>
          <div className='side-dropdown-item'><span className='side-dropdown-item-span'>Samhällsplanering</span></div>
          {/* <div className='side-dropdown-item'><span className='side-dropdown-item-span'>Samordning-Bevakning</span></div> */}
        </div>
      </div>
    </div>
  )
}