import * as types from "./actiontypes";
import { showLoading, hideLoading } from "./loadingactions";
import * as api from "api/caseapi";


export function loadEventFeed(isFetching) {
	return function (dispatch) {
		if (!isFetching.current) return null;
		dispatch(showLoading());

		return api.getEventFeed()
			.then(eventFeed => {
				if (!isFetching.current) return null;
				dispatch(loadEventFeedSuccess(eventFeed));
				dispatch(hideLoading());
			})
			.catch(error => {
				dispatch(hideLoading());
				throw error;
			});
	};
}

export function loadEventFeedSuccess(eventFeed) {
	return { type: types.LOAD_EVENT_FEED_SUCCESS, eventFeed };
}