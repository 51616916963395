import React from 'react';
import { FiSearch } from 'react-icons/fi';
import './searchfilter.scss';


export default function SearchFilter(props) {
  return (
    <div className="side-search-wrapper">
      <FiSearch className='side-search-icon' />
      <input type='search' placeholder={props.placeholder} value={props.searchString} className='side-search' onChange={props.handleSearchChange} />
    </div>
  )
}