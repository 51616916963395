export const validEmailAddress = (emailAddress) => {
  let validEmailRegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
  return validEmailRegEx.test(emailAddress);
};

export const validPassword = (password) => {
  var validPasswordRegEx = /^(?=.*\d)(?=.*[!"@#£¤$%&/{()=}?+*'^<>-_])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return validPasswordRegEx.test(password);
}

export const validHTML = (htmlString) => {
  let parser = new DOMParser();
  let doc = parser.parseFromString(htmlString, "text/html");
  const errorNode = doc.querySelector('parsererror');
  return !errorNode;
}