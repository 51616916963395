import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Table, Container, Row } from 'reactstrap';
import { Button } from 'react-bootstrap';
import Spinner from "components/common/spinner";
import * as statusActions from 'redux/actions/statusactions';
import './status.scss';


export function FormatEventReason(props) {  
  const caseNumberPlaceholder = "{caseNumber}";
  let index = -1;
  let reasonPart1 = "";
  let reasonPart2 = "";

  if (props.reason) {
    index = props.reason.indexOf("{caseNumber}");
    if (index !== -1) {
      reasonPart1 = props.reason.substring(0, index)
      reasonPart2 = props.reason.substring(index + caseNumberPlaceholder.length, props.reason.length)
    }
  }

  return (
    <div>
      {props.reason && index !== -1 ? (
        <span>
          {reasonPart1}
          <Button 
            variant="link"
            className="button-link event-feed-link"
            href="#" onClick={() => props.onLinkClick(props.caseNumber)}>
            {props.caseNumber}
          </Button>
          {reasonPart2}
        </span>
      ) : ( 
        <span>{props.reason}</span>
      )}
    </div>
  )
}

function Status(props) {

  const { actions } = props;
  const [calcHeight, setCalcHeight] = useState(() => () => handleResize());
  let navigate = useNavigate();
  let isFetching = useRef(true);

  useEffect(() => {
    actions.loadEventFeed(isFetching)
      .catch(error => {
        console.log(error);
        toast.error('Det gick inte att hämta händelseloggen.');
        isFetching.current = false;
      })

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      //cleanup
      isFetching.current = false;
      window.removeEventListener("resize", handleResize);
    }
  }, [actions])

  function handleResize() {
    if (!isFetching.current) return null;
    const nav = document.getElementsByClassName('flex-container')[0];
    if (!nav) return;
    const h = nav.getBoundingClientRect().height;
    const wh = window.innerHeight;
    setCalcHeight((wh - h - 100) + 'px'); // Remove 100 extra pixels for h4 and margins.
  }

  function handleLinkClick(caseNumber) {
    navigate({pathname: '/arenden/' + caseNumber});
  }

  const loading = useSelector((state) => state.loading);
  if (loading) {
    return <Spinner />
  }

  return (
    <Container fluid>
      <Row style={{ height:'100%'}}>
        <div className="activity-log-wrapper">
          <div className="side-title">
            Händelselogg
          </div>
          <div className="activity-log-container" style={{ height: calcHeight }}>
            <Table hover>
              <thead>
                <tr>
                  <th scope="col">Tidpunkt</th>
                  <th scope="col">Händelse</th>
                </tr>
              </thead>
              <tbody>
                {props.eventFeed?.map((event, index) =>
                  <tr className="table-row" key={index}>
                    <td>
                      {new Intl.DateTimeFormat("sv-SE", 
                        {year: 'numeric', month: 'numeric', day: 'numeric',
                        hour: 'numeric', minute: 'numeric', second: 'numeric'
                        }).format(new Date(event.dateTime))
                      }
                    </td>
                    <td>
                      <FormatEventReason 
                        reason={event.attributes.Reason}
                        caseNumber={event.attributes.CaseNumber}
                        onLinkClick={handleLinkClick}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Row>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    eventFeed: state.status.eventFeed
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadEventFeed: bindActionCreators(statusActions.loadEventFeed, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Status);


// TODO: Complete layout of the status page

// import React from 'react';
// import { Container, Row, Col, Progress } from 'reactstrap';
// import {  MdWarning} from 'react-icons/md';
// import { FaQuestionCircle, FaThumbsUp } from 'react-icons/fa';



// export default function Status(){

//   //const [lkConnection, getLkConnection] = useState(null);

//   return(
//     <Container fluid style={{background:'#4F4D4D', color:'white', height:'92vh'}}>
//       <Row style={{borderBottom:'10px solid white', height:'50%'}}>
//         <Col style={{border:'1px solid white'}}>
//           <h3>Uppkoppling mot ledningskollen</h3>
//           <br/>
//           <FaThumbsUp style={{color:'green', height:'5em', width:'5em'}}/>
//           <br/>
//           <h2>OK</h2>
//         </Col>
//         <Col style={{border:'1px solid white'}}>
//           <h3>Automatisering </h3>
//           <Row>
//             <Col>Ledningsanvisning</Col>
//             <Col>92 %</Col>
//           </Row>
//           <Row>
//             <Col>Projektering</Col>
//             <Col>71 %</Col>
//           </Row>
//           <Row>
//             <Col>Samordningsärenden</Col>
//             <Col> N/A</Col>
//           </Row>
//           <Row>
//             <Col>Samhällsplanering</Col>
//             <Col>N/A</Col>
//           </Row>
//           <h4>Nuvarande automatisering de senaste 30 dagarna</h4>
//           <br/>
//           <h2> 80 %</h2>
//           <br/>
//           <div className="text-center">80%</div>
//             <Progress animated color="success" value="80" />
//         </Col>
//         <Col style={{border:'1px solid white'}}>
//           <h3>Status i ledningskollen</h3>
//           <Row>
//             <Col>Ärenden manuell hantering</Col>
//             <Col> 8 st</Col>
//           </Row>
//           <Row>
//             <Col>Sena ärenden</Col>
//             <Col><MdWarning style={{color: 'yellow', width:'1em', height:'1em', margin:'2px'}}/> 2 st</Col>
//           </Row>
//           <Row>
//             <Col>Senaste hämtning</Col>
//             <Col>idag kl 13:30</Col>
//           </Row>
//         <p>Snitt antal ärenden per månad:</p>
//         <h1>328 st</h1>
//         <br/>
//         <p>Antal ärenden de senaste 12 månaderna:</p>
//         <h1>4233 st</h1>
//         </Col>
//         <Col style={{border:'1px solid white'}}>
//           <p>Buffertzon i ledningsägarmodulen:</p>
//           <h1>20 meter</h1>
//           <br/>
//           <p>Maskning av ledningsnät i underlag: </p>
//           <h1>10 meter</h1>
//         </Col>
//       </Row>
//       <Row style={{ height:'50%'}}>
//         <Col sm ="5" style={{border:'1px solid white'}}>
//           <h2>Aktivitetslog:</h2>
//           <br/>
//           <ul>
//             <li>Testing...</li>
//             <li>Testing...</li>
//             <li>Testing...</li>
//             <li>Testing...</li>
//             <li>Testing...</li>
//             <li>Testing...</li>
//           </ul>
//         </Col>
//         <Col sm="5" style={{border:'1px solid white'}}>
//           <h2>Ärendehantering i realtid</h2>
//           <h4>Senaste 5 besvarade ärendena:</h4>
//          <Row>
//            <Col></Col>
//            <Col></Col>
//          </Row>
//          <Row>
//            <Col></Col>
//            <Col></Col>
//          </Row>
//          <Row>
//            <Col></Col>
//            <Col></Col>
//          </Row>
//           <h4>Inloggade användare just nu: </h4>
//           <Row>
//            <Col>Henrik Karlsson</Col>
//          </Row>
//          <Row>
//            <Col>Jörgen Nordman</Col>
//          </Row>
//          <Row>
//            <Col>Per Fröjd</Col>
//          </Row>
//           </Col>
//         <Col sm="2" style={{border:'1px solid white'}}>
//         <FaQuestionCircle style={{width:'5em', height:'5em', margin:'30%', marginLeft:'35%'}} />
//         </Col>
//       </Row>
//     </Container>
//   );
// }