import * as types from '../actions/actiontypes';
import initialState from './initialstate';


export default function mapReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_MAP_SETTINGS_SUCCESS:
      return action.mapSettings;
    case types.LOAD_MAP_MARKERS_SUCCESS:
        return action.markers;
    case types.LOAD_MAP_POLYGON_SUCCESS:
      return action.polygons;
    case types.LOAD_MAPTILES_SUCCESS:
      return action.mapTiles;
    default:
      return state;
  }
}
