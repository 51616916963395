import React from 'react';
import './sendmaterialbymail.scss';


export default function SendMaterialByMail(props) {

  return (
    <div className='case-acts-send-material-wrapper'>
      <div className='case-acts-send-material-content'>

        <div>
          <p className='case-acts-send-material-header'>Skicka svar via post</p>
          <p className='case-acts-send-material-information'>Frågaren har valt att få svar på sitt ärende via post. Vänligen besvara detta ärende i Ledningskollen. 
            Där kan du också se frågarens postadress samt ladda ner och skriva ut filer som bifogas till ditt svar.</p>
        </div>

        <div className='case-acts-send-material-buttons-wrapper'>
          <button className='button-primary button-row-margin'
            onClick={props.handleSendMaterialCancelClick}>
            Stäng
          </button>
        </div>
      </div>
    </div>
  );
}