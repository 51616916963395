import * as L from 'leaflet';
import 'leaflet-wms-header';
import { getBaseUrl, getHeaders } from '../../../../api/apiutils.js'


export const getBaseLayers = (mapSettings) => {
	var baseLayers = [];

	mapSettings.mapLayerGroups.forEach(mapLayer => {
		var tileLayer = getLayer('/map/wmstile', mapLayer.tileLayerGroup);
		tileLayer.attribution = mapSettings.mapMaterialCopyrightText;
		var baseLayer = {title: mapLayer.title, layer: tileLayer}
		baseLayers.push(baseLayer);
	});

	if(mapSettings.extraMapLayerGroups) {
		mapSettings.extraMapLayerGroups.forEach(extraMapLayer => {
			var extraTileLayer = getLayer('/map/wmstile', extraMapLayer.tileLayerGroup);
			extraTileLayer.attribution = mapSettings.mapMaterialCopyrightText;
			var extraBaseLayer = {title: extraMapLayer.title, layer: extraTileLayer}
			baseLayers.push(extraBaseLayer);
		});
	}

	return baseLayers;
}

export const getNetworkLayerGroups = (mapSettings) => {
	var networkLayerGroups = [];
	mapSettings.networkLayerGroups.forEach(networkLayerGroup => {
		var networkLayers = [];
		networkLayerGroup.layerGroupSettings.forEach(lgs => {
			var layer = getLayer('/map/wms', lgs.name)
			networkLayers.push(layer);
		});
		var layerGroup = {
			name: networkLayerGroup.name,
			layers: new L.layerGroup(networkLayers)
		}
		networkLayerGroups.push(layerGroup);
	});
	return networkLayerGroups;
}

const getLayer = (url, layerName) => {
	var headers = getHeaders()
	var layer = L.TileLayer.wmsHeader(getBaseUrl() + url, 
		{
			layers: layerName,
			transparent: true,
			format: 'image/png',
			continuousWorld: true
		},
		[
			{ header: 'Authorization', value: headers['Authorization'] },
			{ header: 'content-type', value: headers['Content-Type'] }
		],
		null
	);
	return layer;
} 
