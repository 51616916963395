import * as types from '../actions/actiontypes';
import initialState from './initialstate';


export default function archivedCasesReducer(state = initialState.archivedCases, action) {
  switch (action.type) {
    case types.LOAD_ARCHIVED_CASES_SUCCESS:
      return action.cases;
    case types.LOAD_MORE_ARCHIVED_CASES_SUCCESS:
      return state.concat(action.cases);
    case types.FILTER_ARCHIVED_CASES_SUCCESS:
      return {
        ...state
      }
    case types.SEARCH_IN_ARCHIVED_CASES:
      return {
        ...state
      }
    default:
      return state;
  }
}
