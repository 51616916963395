import React, { useState, useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { Container, Row } from 'reactstrap';
import * as archivedCasesActions from '../../redux/actions/archivedcasesactions';
import { exportArchivedCases } from "redux/actions/archivedcasesactions";
import MapContainer from '../../components/map/mapcontainer/mapcontainer';
import SideInfo from './sideinfo/sideinfo';
import ScrollableLeftPanel from '../../components/common/scrollableleftpanel';


function ArchivedCases(props) {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  const placeholder = 'Sök bland ärendehistorik...';
  const location = useLocation();
  const [exporting, setExporting] = useState(false);

  const { actions } = props;

  const handleStartDateChange = (date) => {
    actions.setStartDateArchivedCasesFilter(date);
  };

  const handleEndDateChange = (date) => {
    actions.setEndDateArchivedCasesFilter(date);
  };

  const handleSearchChange = (event) => {
    actions.setSearchValueArchivedCasesFilter(event.target.value);
  }

  const handleTypeChange = (event) => {
    actions.setCaseTypeArchivedCasesFilter(event.target.innerText);
  }

  const handleSortChange = (event) => {
    actions.setSortDirectionArchivedCasesFilter(event.target.innerText);
  }

  const handleClearFilterClick = () => {
    actions.setClearArchivedCasesFilter();
  }

  const handleRefreshClick = () => {
    actions.filterAndSearchArchivedCases(props.archivedCasesFilter, 1)
      .then(function () {
        toast.success('Listan med ärendehistorik har uppdaterats.');
      })
      .catch(error => {
        console.log(error);
        toast.error('Det gick inte att hämta ärendehistoriken.');
    })
  }

  const handleExportClick = () => {
    setExporting(true);

    return exportArchivedCases(props.archivedCasesFilter)
      .then(function () {
        toast.success('Ärendehistoriken har exporterats.');
      })
      .catch((error) => {
        console.log(error);
        toast.error('Det gick inte att exportera ärendehistoriken.');
      })
      .then(() => { setExporting(false)});
  };

  const handleLoadMoreCasesClick = () => {
    actions.filterAndSearchArchivedCases(props.archivedCasesFilter, props.archivedCasesPaging.currentPage + 1)
      .catch(error => {
        console.log(error);
        toast.error('Det gick inte att hämta ärendehistoriken.');
    })
  }

  const filterAndSearchArchivedCases = useCallback(() => {
    actions.filterAndSearchArchivedCases(props.archivedCasesFilter, 1)
			.catch(error => {
				console.log(error);
				toast.error('Det gick inte att hämta ärendehistoriken.');
			})
  }, [props.archivedCasesFilter])

  useEffect(() => {
    // Case list should only be reloaded if NOT returning from case details page.
    if (location.state && location.state.previousPage === "caseDetailsPage") {
      location.state = null;
    }
    else {
      filterAndSearchArchivedCases();
    }
	}, [filterAndSearchArchivedCases])

  return (
    <Container fluid>
      <Row>
        <ScrollableLeftPanel>
          <SideInfo
            archivedCases={props.archivedCases}
            handleLoadMoreCasesClick={handleLoadMoreCasesClick}
            searchValue={props.archivedCasesFilter.searchString}
            placeholder={placeholder}
            handleSearchChange={handleSearchChange}
            CaseTypeValue={props.archivedCasesFilter.caseTypeFilter}
            handleTypeChange={handleTypeChange}
            SortDirectionValue={props.archivedCasesFilter.sortDirection}
            handleSortDirectionChange={handleSortChange}
            startDate={props.archivedCasesFilter.startDateFilter}
            handleStartDateChange={handleStartDateChange}
            endDate={props.archivedCasesFilter.endDateFilter}
            handleEndDateChange={handleEndDateChange}
            handleClearFilterClick={handleClearFilterClick}
            handleRefreshClick={handleRefreshClick}
            handleExportClick={handleExportClick}
            exporting={exporting}
          />
        </ScrollableLeftPanel>
        <MapContainer 
          markers={props.archivedCases}
        />
      </Row>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    archivedCases: state.archivedCases,
    archivedCasesFilter: state.archivedCasesFilter,
    archivedCasesPaging: state.archivedCasesPaging
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      filterAndSearchArchivedCases: bindActionCreators(archivedCasesActions.filterAndSearchArchivedCases, dispatch),
      setStartDateArchivedCasesFilter: bindActionCreators(archivedCasesActions.setStartDateArchivedCasesFilter, dispatch),
      setEndDateArchivedCasesFilter: bindActionCreators(archivedCasesActions.setEndDateArchivedCasesFilter, dispatch),
      setSearchValueArchivedCasesFilter: bindActionCreators(archivedCasesActions.setSearchValueArchivedCasesFilter, dispatch),
      setCaseTypeArchivedCasesFilter: bindActionCreators(archivedCasesActions.setCaseTypeArchivedCasesFilter, dispatch),
      setSortDirectionArchivedCasesFilter: bindActionCreators(archivedCasesActions.setSortDirectionArchivedCasesFilter, dispatch),
      setClearArchivedCasesFilter: bindActionCreators(archivedCasesActions.setClearArchivedCasesFilter, dispatch),
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchivedCases);
