import React from "react";
import InfoRow from "../caseinfo/inforow/inforow";
import moment from "moment";
import "./archivedcaseinfo.scss";

export default function ArchivedCaseInfo(props) {
  const hasLatestInCheckAnswer = props.latestInCheckAnswer !== null && 
                                 props.latestInCheckAnswer.answererName !== null && 
                                 props.latestInCheckAnswer.answererLogin !== null;

  return (
    <div className={"case-archived-info-wrapper"}>
      <div className={"case-archived-info-case-answer-item-wrapper"}>
        <InfoRow
          infoObjects={[
            {
              label: "Åtgärdskommentar i Ledningskollen",
              info: `${props.caseAnswerInLK.answerComment ? props.caseAnswerInLK.answerComment : "-"}`,
            },
          ]}
        />
      </div>
      <div className={"case-archived-info-item-wrapper"}>
        <InfoRow
          infoObjects={[
            {
              label: "Tidpunkt för senaste svar",
              info: props.latestInCheckAnswer !== null ? `${moment(props.latestInCheckAnswer.sentDate).format("YYYY-MM-DD HH:mm")}` : `${moment(props.caseAnswerInLK.answerTime).format("YYYY-MM-DD HH:mm")}`,
            },
          ]}
        />
      </div>
      <div className={"case-archived-info-item-wrapper"}>
        <InfoRow
          infoObjects={[
            {
              label: "Senast besvarat av",
              info: (hasLatestInCheckAnswer) 
                ? props.latestInCheckAnswer.answererName + " (" + props.latestInCheckAnswer.answererLogin + ")" 
                : props.caseAnswerInLK.answererName + " (" + props.caseAnswerInLK.answererLogin + ")",
            },
          ]}
        />
      </div>
    </div>
  );
}
