import { getFileExtension } from "./filenameutil";

class AttachmentValidation {
  constructor(attachmentSettings) {
    this.totalFileSizeMb = attachmentSettings.totalFileSizeMb;
    this.maxFileSizeMb = attachmentSettings.maxFileSizeMb;
    this.maxNumberOfFiles = attachmentSettings.maxNumberOfFiles;
    this.allowedFileTypes = attachmentSettings.allowedFileTypes;
  }

  validNumberOfFiles(manuallyAttachedFiles) {
    let result = [];
    if (manuallyAttachedFiles.length > this.maxNumberOfFiles) {
      result.push(`Max ${this.maxNumberOfFiles} filer kan bifogas till svaret.`);
    }
    return result;
  }

  validTotalSize(manuallyAttachedFiles) {
    let result = [];
    const totalSizeMb =
      manuallyAttachedFiles.reduce((prev, cur) => {
        return prev + cur.size;
      }, 0) /
      (1024 * 1024);
    if (totalSizeMb > this.totalFileSizeMb) {
      result.push(`Max ${this.totalFileSizeMb} MB kan bifogas totalt.`);
    }
    return result;
  }

  validFileSize(manuallyAttachedFiles) {
    let result = [];
    manuallyAttachedFiles.forEach((file) => {
      if (!this.hasValidFileSize(file.size)) {
        result.push(`Filen '${file.name}' överskrider maxstorleken ${this.maxFileSizeMb} MB.`);
      }
    });
    return result;
  }

  validFileType(manuallyAttachedFiles) {
    let result = [];
    manuallyAttachedFiles.forEach((file) => {
      if (!this.isValidFileExtension(file.name)) {
        result.push(`Filen '${file.name}' har en otillåten filtyp.`);
      }
    });
    return result;
  }

  validateFiles(manuallyAttachedFiles) {
    let result = [];
    result = [...result, ...this.validFileSize(manuallyAttachedFiles)];
    result = [...result, ...this.validFileType(manuallyAttachedFiles)];
    result = [...result, ...this.validTotalSize(manuallyAttachedFiles)];
    result = [...result, ...this.validNumberOfFiles(manuallyAttachedFiles)];

    return result;
  }

  checkDuplicates(manualAttachedFiles, newAttachments) {
    let result = [];
    newAttachments.forEach((file) => {
      if (manualAttachedFiles.some((item) => file.name === item.name)) {
        result.push(`Filen '${file.name}' har redan bifogats.`);
      }
    });
    return result;
  }

  fileValidationInfo() {
    let fileValidationInfo = {
      header: `Egna bifogade filer:`,
      validationInfo: [
          `Max ${this.maxNumberOfFiles} bifogade filer.`,
          `Totalt ${this.totalFileSizeMb} MB kan bifogas.`,
          `Maxstorlek per fil är ${this.maxFileSizeMb} MB.`,
          `Tillåtna filtyper ${this.allowedFileTypes.replace(/,/g, ', ')}.` // Add a space between filetypes.
        ]
    };
    return fileValidationInfo;
  }

  hasValidFileSize(fileSize) {
    return fileSize / (1024 * 1024) < this.maxFileSizeMb;
  }

  isValidFileExtension(fileName) {
    const fileExtension = getFileExtension(fileName)?.toLowerCase();
    return fileExtension !== "" && this.allowedFileTypes.toLowerCase().includes(fileExtension);
  }
}

export default AttachmentValidation;
