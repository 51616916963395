import React from 'react';
import { FaSpinner } from "react-icons/fa";
import './spinner.scss';


export default function Spinner(props) {
	return (
		<div className={`${props.containerClassName ? props.containerClassName : 'spinner-container'}`}>
			<FaSpinner 
				size="4em"
				className={`fa-spin fa-spinner ${props.spinnerClassName ? props.spinnerClassName : ''}`}
			/>
		</div>
	);
}