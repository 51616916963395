import React, { useState, useEffect } from 'react';
import './scrollableleftpanel.scss';


export default function ScrollableLeftPanel(props) {
  const [calcHeight, setCalcHeight] = useState(() => () => handleResize());

  function handleResize() {
    const nav = document.getElementsByClassName('flex-container')[0];
    if (!nav) return;
    const h = nav.getBoundingClientRect().height;
    const wh = window.innerHeight;
    setCalcHeight((wh - h) + 'px');
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      //Cleanup
      window.removeEventListener("resize", handleResize);
    }
  }, [])
  
  return (
    <div
      className='col-sm-6 scrollable-left-panel'
      style={{ height: calcHeight, overflowY: 'auto' }}
    >
      {props.children}
    </div>
  );
}

