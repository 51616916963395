import React from "react";
import PropTypes from "prop-types";
import "./textinput.scss";

const TextInput = ({
  name,
  label,
  onChange,
  onClick,
  placeholder,
  value,
  error,
  inputType,
  disabled,
  wrapperClass,
  inputClass,
  labelClass,
  autoFocus,
  onBlur,
  autocomplete
}) => {
  return (
    <div className={wrapperClass || "form-group"}>
      {label && (
        <label htmlFor={name} className={labelClass || "input-label"}>
          {label}
        </label>
      )}
      <div>
        <input
          type={inputType || "text"}
          name={name}
          className={`${error && "invalid-input-border"} ${inputClass ? inputClass : "input"}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
          autoFocus={autoFocus}
          onBlur={onBlur}
          autoComplete={autocomplete}
        />
        {error && <div className="invalid-input">{error}</div>}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  wrapperClass: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string
};

TextInput.defaultProps = {
  inputType: "text",
  disabled: false,
  autocomplete: "on"
};

export default TextInput;
