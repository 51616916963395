import moment from "moment";
import * as types from "./actiontypes";
import { showLoading, hideLoading, showMapLoading, hideMapLoading } from "./loadingactions";
import * as api from "api/caseapi";


// const searchOptions = {
//   shouldSort: true,
//   threshold: 0.6,
//   location: 0,
//   distance: 100,
//   maxPatternLength: 32,
//   minMatchCharLength: 1,
//   keys: [
//     "number",
//     "type",
//     "orderer",
//     "status"
//   ]
// };

export function filterAndSearchArchivedCases(archivedCasesFilter, page) {

  return function (dispatch) {
    dispatch(showLoading());
    dispatch(showMapLoading());

    var caseListFilter = {
			purpose: archivedCasesFilter.caseTypeFilter,
			text: archivedCasesFilter.searchString,
      fromDate: moment(archivedCasesFilter.startDateFilter).format("YYYY-MM-DD"),
      toDate: moment(archivedCasesFilter.endDateFilter).format("YYYY-MM-DD"),
			descending: archivedCasesFilter.sortDirection === "Äldsta ärenden överst" ? false : true,
			page: page
		};

    return api.getArchivedCases(caseListFilter)
      .then(archivedCases => {
        let cases = archivedCases.caseData;

        if (page === 1) {
          dispatch(loadArchivedCasesSuccess(cases));
          dispatch(setArchivedCaseListTotalCasesCountSuccess(archivedCases.totalCasesCount)); 
          dispatch(setArchivedCaseListPageCountSuccess(archivedCases.pageCount));
        }
        else {
          dispatch(loadMoreArchivedCasesSuccess(cases)); 
        }
        dispatch(setArchivedCaseListCurrentPage(page));

        dispatch(hideLoading());
        dispatch(hideMapLoading());
      })			
      .catch(error => {
				dispatch(hideLoading());
        dispatch(hideMapLoading());
				throw error;
			});
  }
}

export function exportArchivedCases(archivedCasesFilter) {

  var caseListFilter = {
    purpose: archivedCasesFilter.caseTypeFilter,
    text: archivedCasesFilter.searchString,
    fromDate: moment(archivedCasesFilter.startDateFilter).format("YYYY-MM-DD"),
    toDate: moment(archivedCasesFilter.endDateFilter).format("YYYY-MM-DD"),
    descending: archivedCasesFilter.sortDirection === "Äldsta ärenden överst" ? false : true,
  };

  return api.getArchivedCasesExport(caseListFilter)
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        "Ärendehistorik " + moment().format("YYYY-MM-DD") + ".csv"
      );

      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
}

export function loadArchivedCasesSuccess(cases) {
  return { type: types.LOAD_ARCHIVED_CASES_SUCCESS, cases };
}

export function loadMoreArchivedCasesSuccess(cases) {
  return { type: types.LOAD_MORE_ARCHIVED_CASES_SUCCESS, cases };
}

export function setStartDateArchivedCasesFilter(startDateFilter) {
  return { type: types.SET_START_DATE_ARCHIVED_CASES_FILTER, startDateFilter };
}

export function setEndDateArchivedCasesFilter(endDateFilter) {
  return { type: types.SET_END_DATE_ARCHIVED_CASES_FILTER, endDateFilter };
}

export function setSearchValueArchivedCasesFilter(searchString) {
  return { type: types.SET_SEARCH_VALUE_ARCHIVED_CASES_FILTER, searchString };
}

export function setCaseTypeArchivedCasesFilter(caseTypeFilter) {
  return { type: types.SET_CASE_TYPE_ARCHIVED_CASES_FILTER, caseTypeFilter };
}

export function setSortDirectionArchivedCasesFilter(sortDirection) {
  return { type: types.SET_SORT_DIRECTION_ARCHIVED_CASES_FILTER, sortDirection };
}

export function setClearArchivedCasesFilter() {
  return { type: types.CLEAR_ARCHIVED_CASES_FILTER };
}

export function setArchivedCaseListTotalCasesCountSuccess(totalCasesCount) {
  return { type: types.SET_ARCHIVED_CASE_LIST_TOTAL_CASES_COUNT_SUCCESS, totalCasesCount };
}

export function setArchivedCaseListPageCountSuccess(pageCount) {
  return { type: types.SET_ARCHIVED_CASE_LIST_PAGE_COUNT_SUCCESS, pageCount };
}

export function setArchivedCaseListCurrentPage(currentPage) {
  return { type: types.SET_ARCHIVED_CASE_LIST_CURRENT_PAGE, currentPage };
} 