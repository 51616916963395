import React, { Fragment } from 'react';
import CaseCard from '../../../components/common/casecard';
import SearchFilter from '../../../components/common/searchfilter';
import TypeFilter from '../../../components/common/typefilter';
import './sideinfo.scss';


export default function SideInfo(props) {
  let cases;

	if (props.coverageAreasCases.length) {
		cases =props.coverageAreasCases.map(ce => <CaseCard Case={ce} />);
	}
	else {
		cases = <div className='empty-case-list'><span>Det finns inga bevakningsområden.</span></div>
	}

  return (
    <Fragment>
      <div className='side-header'>
        <div className='side-title'>
          Bevakningsområden
        </div>
        <SearchFilter
          placeholder={props.placeholder}
          handleSearchChange={props.handleSearchChange}
        />
        <TypeFilter
          handleTypeChange={props.handleTypeChange}
          CaseTypeValue={props.CaseTypeValue}
        />
      </div>
      <div className="case-card-list-container">
        {cases}
      </div>
    </Fragment>
  );
}
