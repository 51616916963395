import React from 'react';
import { createRoot } from 'react-dom/client';
import {Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from './redux/config/configurestore';
import initialState from './redux/reducers/initialstate';
import Login from './pages/login/login';
import * as serviceWorker from './serviceworker';
import App from './app';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';


const store = configureStore(initialState);
const root = createRoot(document.getElementById('root'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/" element={<Login/>} />
      <Route path="/*" element={<App/>} />
    </Route>
  )
);

root.render(
  <ReduxProvider store={store}>
    <RouterProvider router={router} />
  </ReduxProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
