import React from "react";
import { RiAlarmWarningFill } from 'react-icons/ri'
import './pagenotfound.scss';


export default function PageNotFound() {
	return (
		<div className="page-not-found-container">
			<RiAlarmWarningFill />
			<h1 className="page-not-found-header">Den här sidan finns tyvärr inte.</h1>
		</div>
	)
}
