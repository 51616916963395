import React from "react";
import moment from "moment";
import InfoRow from "../caseinfo/inforow/inforow";
import FileInfoRow from "../caseinfo/fileinforow/fileinforow";
import "./viewsentmaterialemails.scss";
import { CaseMaterialEmail, CaseMaterialEmailAttachment, InCheckMeasureType } from "types/casematerialemail";
import HtmlInfoRow from "../caseinfo/htmlinforow/htmlinforow";
import {getEmailReplyDiv} from "utils/domutil";

type AppProps = {
  caseMaterialEmails: CaseMaterialEmail[];
};

const ViewSentMaterialEmails = ({ caseMaterialEmails }: AppProps) => {
  const getAttachmentFileNames = (attachments: CaseMaterialEmailAttachment[], isManualAttachments: boolean) => {
    let filteredAttachments = attachments
      .filter((f) => {
        return f.isManuallyAttached === isManualAttachments;
      })
      .map((f) => {
        return new File([], f.fileName);
      });
    return filteredAttachments;
  };

  return (
    <div className="view-sent-material-wrapper">
      <div className="view-sent-material-content">
        <span className="view-sent-material-header">Skickade svar ({caseMaterialEmails.length})</span>

        {caseMaterialEmails.map((email, index) => (
          <div key={index} className="sent-material-info-wrapper">
            <InfoRow
              infoObjects={[
                { label: "Tidpunkt för svar", info: `${moment(email.sentDate).format("YYYY-MM-DD HH:mm")}` },
                { label: "Skickat av", info: (email.answererName !== null && email.answererLogin !== null) ? email.answererName + " (" + email.answererLogin + ")" : "-"},
                { label: "", info: "" },
                {
                  label: "Åtgärd i InCheck",
                  info:
                    email.measureType === InCheckMeasureType.ResendManualMark
                      ? "Skickat för utsättning"
                      : "Skickat svar",
                },
              ]}
            />
            <InfoRow infoObjects={[{ label: "Till", info: `${email.rcptTo}` }]} />
            <InfoRow infoObjects={[{ label: "Cc", info: `${email.cc !== null ? email.cc : "-"}` }]} />
            <InfoRow infoObjects={[{ label: "Från", info: `${email.mailFrom}` }]} />
            <InfoRow infoObjects={[{ label: "Ämne", info: `${email.mailSubject}` }]} />
            <HtmlInfoRow infoObjects={[{ label: "Meddelande", info: `${getEmailReplyDiv(email.mailBody)}` }]} />

            <div className={"view-sent-material-file-wrapper"}>
              <div className={"view-sent-material-files"}>
                <FileInfoRow
                  caseNumber={email.caseNumber}
                  files={getAttachmentFileNames(email.caseMaterialEmailAttachments, false)}
                  isArchived={true}
                  caseMaterialEmailId={email.id}
                />
              </div>
              <div className={"view-sent-material-files"}>
                <FileInfoRow
                  caseNumber={email.caseNumber}
                  label="Egna bifogade filer"
                  files={getAttachmentFileNames(email.caseMaterialEmailAttachments, true)}
                  isArchived={true}
                  caseMaterialEmailId={email.id}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewSentMaterialEmails;
