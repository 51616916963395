/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CRS } from "proj4leaflet";
import "react-leaflet-markercluster/dist/styles.min.css";
import { FaHome } from "react-icons/fa";
import "leaflet.fullscreen";
import "leaflet.fullscreen/Control.FullScreen.css";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import "leaflet.markercluster";
import L from 'leaflet';
import { Transformation, marker, geoJson, geoJSON, DomUtil, Map, control } from "leaflet";
import "./mapcontainer.scss";
//import { searchControl, results } from '../search/search';
import { markerCaseTypes } from "../../../assets/icons/icons";
import { getBaseLayers, getNetworkLayerGroups } from "../maplayer/wmslayers/wmslayers";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as MapActions from "../../../redux/actions/mapactions";
import Spinner from "../../../components/common/spinner";
import "leaflet/dist/leaflet.css";
import "../../../components/map/maplayer/layertree/control.layertree";
import "../../../components/map/maplayer/layertree/layertree.scss";
import { checkIsLoggedIn } from "../../../api/apiutils";

function MapContainer(props) {
  let map = useRef(null);

  const [currentMap, setMap] = useState(null);
  const [casePolygons, setCasePolygons] = useState(null);
  const [markerCluster, setMarkerCluster] = useState(null);
  const [networkMask, setNetworkMask] = useState(null);
  const [mapInitialView, setInitialView] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  let navigate = useNavigate();

  const restoreInitialExtent = () => {
    if (mapInitialView) {
      map.current.setView(mapInitialView.center, mapInitialView.zoom, {
        animate: true,
      });
    }
  };

  //TODO What are the bounds if no cases?
  const getCrs = () => {
    const srsName = props.mapSettings.srsName;
    const srsProj4Definition = props.mapSettings.srsProj4Definition;
    const resolutions = props.mapSettings.reversedResolutions;
    const crs = new CRS(srsName, srsProj4Definition, {
      resolutions: resolutions,
      transformation: new Transformation(
        props.mapSettings.srsTransformation[0],
        props.mapSettings.srsTransformation[1],
        props.mapSettings.srsTransformation[2],
        props.mapSettings.srsTransformation[3]
      ),
    });
    return crs;
  };

  function clearMarkerCluster() {
    if (markerCluster !== null) {
      markerCluster.removeFrom(map.current);
      setMarkerCluster(null);
    }
  }

  function clearPolygons() {
    if (casePolygons !== null) {
      casePolygons.forEach((casePolygon) => {
        casePolygon.removeFrom(map.current);
      });
      setCasePolygons(null);
    }
  }

  function clearNetworkMask() {
    if (networkMask !== null) {
      networkMask.removeFrom(map.current);
      setNetworkMask(null);
    }
  }

  //TODO fix and use this
  // const addMarkersWithinBoundingBox = (data, bounds, caseType) => {
  //     let visibleMarkers = [];
  //     map.current.eachLayer(function (layer) {
  //         if (layer instanceof L.Marker && map.current.getBounds().contains(layer)) {
  //             visibleMarkers.push(layer);
  //         }
  //     })
  //     addMarkerCluster(visibleMarkers, markerCaseTypes[caseType], map.current).addTo(map.current);
  // }

  const addMarkerCluster = (caseMarkers) => {
    let cluster = L.markerClusterGroup();
    let markers = [];
    try {
      markers = caseMarkers.map((data) => {
        let addcord = [data["centreCoordinateLat"], data["centreCoordinateLng"]];
        let caseMarker = new marker(addcord, { icon: markerCaseTypes[data["caseType"]] });
        caseMarker.on("click dbclick", function () {
          onClick(data["number"]);
        });
        return caseMarker;
      });
      cluster.addLayers(markers);
      const bounds = cluster.getBounds();
      if (bounds.isValid()) {
        map.current.fitBounds(bounds);
      }
      cluster.addTo(map.current);
      setMarkerCluster(cluster);
    } catch (e) {
      console.error("Failed to add markerclusterGroup: " + e.toString());
    }
  };

  const addCasePolygonGroup = (caseGeometries, workAreaColor, fitToBounds) => {
    try {
      const casePolygons = [];
      caseGeometries.features.forEach((geometry) => {
        casePolygons.push(geoJSON(geometry, { style: { color: "#" + workAreaColor } }));
      });
      casePolygons.map((p) => p.addTo(map.current));
      if (fitToBounds) {
        const bounds = casePolygons.map((p) => p.getBounds());
        map.current.fitBounds(bounds);
      }
      setCasePolygons(casePolygons);
    } catch (e) {
      console.error("Failed to add GeoJson layer: " + e.toString());
    }
  };

  const addCaseNetworkMask = (networkMask, controlLayers) => {
    try {
      var maskLayer = geoJson(networkMask, {
        style: function () {
          return {
            color: "#696969",
            opacity: 0.8,
            dashArray: "5 10",
            weight: 3,
            fillColor: "green",
            fillOpacity: 0.08,
          };
        },
      });

      controlLayers.addOverlay(maskLayer, "Mask för ledningsnät i underlag");
      maskLayer.addTo(map.current);
      const bounds = maskLayer.getBounds();
      map.current.fitBounds(bounds);
      maskLayer.bringToBack();
      setNetworkMask(maskLayer);
    } catch (e) {
      console.error("Failed to add GeoJson network mask: " + e.toString());
    }
  };

  function onClick(caseNumber) {
    navigate("/arenden/" + caseNumber);
  }

  const mapContainerExists = () => {
    var container = DomUtil.get("map");
    if (container != null) {
      return container._leaflet_id !== undefined;
    }
    return false;
  };

  const createMap = (addNetwork, setDefaultCenter = false) => {
    if (currentMap == null && !mapContainerExists()) {
      let crs = getCrs();
      let maxZoom = crs.options.resolutions.length - 1;
      let baseLayers = getBaseLayers(props.mapSettings);
      if (setDefaultCenter) {
        map.current = new Map("map", {
          crs: crs,
          center: props.mapSettings.initialMapCenter,
          zoomControl: false,
          zoom: props.mapSettings.initialMapZoomLevel,
          minZoom: 0,
          maxZoom: maxZoom,
          layers: baseLayers[0].layer,
          attributionControl: false
        });
      } else {
        map.current = new Map("map", {
          crs: crs,
          zoomControl: false,
          minZoom: 0,
          maxZoom: maxZoom,
          layers: baseLayers[0].layer,
          attributionControl: false
        });
      }

      let controlLayers = control.layers(null, null, { collapsed: true });
      baseLayers.forEach((baseLayer) => {
        controlLayers.addBaseLayer(baseLayer.layer, baseLayer.title);
      });

      if (addNetwork) {
        let networkLayerGroups = getNetworkLayerGroups(props.mapSettings);
        networkLayerGroups.forEach((networkLayerGroup) => {
          controlLayers.addOverlay(networkLayerGroup.layers, networkLayerGroup.name);
          networkLayerGroup.layers.addTo(map.current);
        });
      }
      controlLayers.addTo(map.current);

      control.zoom({ position: "bottomright" }).addTo(map.current);
      control.scale({ position: "bottomleft" }).addTo(map.current);
      control.fullscreen({ position: "topleft", title: "Helskärmsläge" }).addTo(map.current);

      //TODO Add feature layers
      // // Customs Controls added to map.
      // layerTree.addTo(map.current);
      // searchControl.addTo(map.current);
      // results.addTo(map.current);

      map.current.on('movestart zoomstart', async () => {
        await checkIsLoggedIn();
    });

      map.current.on("dragend zoom", () => {
        //addMarkersWithinBoundingBox();
      });
      
      setMap(map);
      setTimeout(() => {
        map.current.invalidateSize();
      }, 200);
      return controlLayers;
    }
  };

  const isMapSettingsValid = () => {
    return props.mapSettings && props.mapSettings.srsName;
  };

  useEffect(() => {
    const setLoggedIn = async () => {
      setIsLoggedIn(await checkIsLoggedIn());
    };

    setLoggedIn();
  }, [isLoggedIn]);

  useEffect(() => {
    if (isMapSettingsValid() && isLoggedIn) {
      if (props.markers) {
        if (props.markers.length > 0) {
          createMap(false);
          clearMarkerCluster();
          addMarkerCluster(props.markers, props.markerCaseTypes, map.current);
          setInitialView({
            center: map.current.getCenter(),
            zoom: map.current.getZoom(),
          });
        } else if (props.markers.length === 0) {
          // Set initial map.
          createMap(false, true);
          clearMarkerCluster();
          setInitialView({
            center: map.current.getCenter(),
            zoom: map.current.getZoom(),
          });
        }
      }
    }
  }, [props.markers, props.mapSettings, isLoggedIn]);

  useEffect(() => {
    if (isMapSettingsValid() && isLoggedIn) {
      if (props.caseDetails) {
        let controlLayers = createMap(true);
        clearPolygons();
        addCasePolygonGroup(
          props.caseDetails.geometries,
          props.caseDetails.workAreaGeometryColor,
          !props.caseDetails.networkMask
        );
        if (props.caseDetails.networkMask && controlLayers) {
          clearNetworkMask();
          addCaseNetworkMask(props.caseDetails.networkMask, controlLayers);
        }

        setInitialView({
          center: map.current.getCenter(),
          zoom: map.current.getZoom(),
        });
      }
    }
  }, [props.caseDetails, props.mapSettings, isLoggedIn]);

  useEffect(() => {
    const { actions } = props;
    if (!isMapSettingsValid() && isLoggedIn) {
      actions.loadMapSettings();
    }
  }, [props.mapSettings, isLoggedIn]);

  const mapLoading = useSelector((state) => state.mapLoading);
  const showRestoreExtentBtn = map && map.current;

  return (
    <Col sm="6" style={{ paddingLeft: 0, paddingRight: 0 }}>
      {mapLoading && <Spinner containerClassName="map-spinner-container" />}
      <div id="map">
        {showRestoreExtentBtn && (
          <div id="extent-button" className="leaflet-bar leaflet-control">
            <Button variant="link" href="#" onClick={restoreInitialExtent}>
              <FaHome />
            </Button>
          </div>
        )}
      </div>
    </Col>
  );
}

const mapStateToProps = (state) => ({
  mapSettings: state.mapSettings,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadMapSettings: bindActionCreators(MapActions.loadMapSettings, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
