import * as types from "./actiontypes";
import { showLoading, hideLoading } from "./loadingactions";
import * as api from "api/adminapi";


export function loadSettings() {
  return function (dispatch) {
    dispatch(showLoading());

    return api.getSettings()
      .then(settings => {
        dispatch(loadSettingsSuccess(settings));
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch(hideLoading());
        throw error;
      });
  };
}

export function saveSettings(settings) {
  return function (dispatch) {
    return api.saveSettings(settings)
      .then(() => {
        dispatch(saveSettingsSuccess(settings));
      })
      .catch(error => {
        throw error;
      });
  };
}

async function readFileDataAsBase64(file) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
          resolve(event.target.result);
      };

      reader.onerror = (err) => {
          reject(err);
      };

      reader.readAsDataURL(file);
  });
}

export function saveLogotypeFile(logotype) {
  return function (dispatch) {
    return api.saveLogotypeFile(logotype)
      .then(() => {
        // Convert file to blob and save in state.
        return new Promise((resolve, reject) => {
          readFileDataAsBase64(logotype)
            .then(buffer => {
              const blob = new Blob([new Uint8Array(buffer)], {type: logotype.type })
              dispatch(saveLogotypeFileSuccess(blob));
            })
            .catch(error => {
              throw error;
            });
        })
      })
      .catch(error => {
        throw error;
      });
  }
}

export function loadLogotypeFile() {
  return function (dispatch) {
    return api.getLogotypeFile()
      .then(logotype => {
        //dispatch logotype from server or null if not yet set
        dispatch(loadLogotypeFileSuccess(logotype.size === 0 ? null : logotype));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadUsers() {
  return function (dispatch) {
    dispatch(showLoading());

    return api.getUsers()
      .then(users => {
        dispatch(loadUsersSuccess(users));
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch(hideLoading());
        throw error;
      });
  };
}

export function createUser(user) {
  return function (dispatch) {
    dispatch(showLoading());

    return api.createUser(user)
      .then(() => {
        dispatch(createUserSuccess(user));
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch(hideLoading());
        throw error;
      });
  };
}

export function editUser(user) {
  return function (dispatch) {
    dispatch(showLoading());

    return api.editUser(user)
      .then(() => {
        dispatch(editUserSuccess(user));
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch(hideLoading());
        throw error;
      });
  };
}

export function deleteUser(userId) {
  return function (dispatch) {
    dispatch(showLoading());

    return api.deleteUser(userId)
      .then(() => {
        dispatch(deleteUserSuccess(userId));
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch(hideLoading());
        throw error;
      });
  };
}


export function loadSettingsSuccess(settings) {
  return { type: types.LOAD_SETTINGS_SUCCESS, settings };
}

export function saveSettingsSuccess(settings) {
  return { type: types.SAVE_SETTINGS_SUCCESS, settings };
}

export function loadLogotypeFileSuccess(logotype) {
  return { type: types.LOAD_LOGOTYPE_FILE_SUCCESS, logotype };
}

export function saveLogotypeFileSuccess(logotype) {
  return { type: types.SAVE_LOGOTYPE_FILE_SUCCESS, logotype };
}

export function loadUsersSuccess(users) {
  return { type: types.LOAD_USERS_SUCCESS, users };
}

export function createUserSuccess(user) {
  return { type: types.CREATE_USER_SUCCESS, user };
}

export function editUserSuccess(user) {
  return { type: types.EDIT_USER_SUCCESS, user };
}

export function deleteUserSuccess(userId) {
  return { type: types.DELETE_USER_SUCCESS, userId };
}