import React, { useState, useEffect, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import TextInput from "components/common/textinput";
import SpinnerButton from "components/common/spinnerbutton";
import * as adminActions from "redux/actions/adminactions";

function ContactInfo(props) {
  const [contactInformation, setContactInformation] = useState("");
  const [savingSettings, setSavingSettings] = useState(false);
  const { actions } = props;

  useEffect(() => {
    if (props.contactInformationSettings) {
      setContactInformation(props.contactInformationSettings.caseMaterialContactInformation);
      setSavingSettings(false);
    }
  }, [props.contactInformationSettings]);

  function handleContactInformationChange(event) {
    setContactInformation(event.target.value);
  }

  function handleSaveClick() {
    setSavingSettings(true);
    let contactInformationSettings = { contactInformationSettings: props.contactInformationSettings };
    var updatedSettings = JSON.parse(JSON.stringify(contactInformationSettings));
    updatedSettings.contactInformationSettings.caseMaterialContactInformation = contactInformation;
    actions
      .saveSettings(updatedSettings)
      .then(function () {
        toast.success("Inställningarna har sparats.");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Det gick inte att spara inställningarna.");
      })
      .then(() => setSavingSettings(false));
  }

  if (props.contactInformationSettings) {
    return (
      <Fragment>
        <h4 className="settings-title">Kontaktinformation</h4>

        <TextInput
          name="contactInformation"
          label="Kontaktinformation"
          value={contactInformation}
          onChange={handleContactInformationChange}
          placeholder={""}
          error={""}
          wrapperClass="settings-label"
        />

        <SpinnerButton onClick={handleSaveClick} loading={savingSettings} buttonClassName="button-primary-large">
          Spara
        </SpinnerButton>
      </Fragment>
    );
  } else {
    return <span>Det gick inte att hämta inställningar.</span>;
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveSettings: bindActionCreators(adminActions.saveSettings, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
