import * as types from '../actions/actiontypes';


export default function usersReducer(state = null, action) {
  let newState = null;

  switch (action.type) {
    case types.LOAD_USERS_SUCCESS:
      return action.users;
    case types.CREATE_USER_SUCCESS:
      return [...state, action.user];
    case types.EDIT_USER_SUCCESS:
      const index = state.findIndex(user => user.user.id === action.user.user.id);
      newState = [...state];
      newState[index] = action.user;
      return newState;
    case types.DELETE_USER_SUCCESS:
      newState = state.filter(user => user.user.id !== action.userId);
      return newState;
    default:
      return state;
  }
}
