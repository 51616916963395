import * as types from '../actions/actiontypes';
import initialState from './initialstate';


export default function archivedCasesFilterReducer(state = initialState.archivedCasesFilter, action) {
	switch (action.type) {
		case types.SET_START_DATE_ARCHIVED_CASES_FILTER:
			return {
				...state,
				startDateFilter: action.startDateFilter
			}
		case types.SET_END_DATE_ARCHIVED_CASES_FILTER:
			return {
				...state,
				endDateFilter: action.endDateFilter
			}
		case types.SET_SEARCH_VALUE_ARCHIVED_CASES_FILTER:
			return {
				...state,
				searchString: action.searchString
			}
		case types.SET_CASE_TYPE_ARCHIVED_CASES_FILTER:
			return {
				...state,
				caseTypeFilter: action.caseTypeFilter
			}
		case types.SET_SORT_DIRECTION_ARCHIVED_CASES_FILTER:
			return {
				...state,
				sortDirection: action.sortDirection
			}
		case types.CLEAR_ARCHIVED_CASES_FILTER:
			return {
				...state,
				searchString: initialState.archivedCasesFilter.searchString,
				caseTypeFilter: initialState.archivedCasesFilter.caseTypeFilter,
				startDateFilter: initialState.archivedCasesFilter.startDateFilter,
				endDateFilter: initialState.archivedCasesFilter.endDateFilter
			}
		default:
			return state;
	}
} 