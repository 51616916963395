import * as types from '../actions/actiontypes';
import initialState from './initialstate';


export default function currentCasesFilterReducer(state = initialState.currentCasesFilter, action) {
	switch (action.type) {
		case types.SET_SEARCH_VALUE_CURRENT_CASES_FILTER:
			return {
				...state,
				searchString: action.searchString
			}
		case types.SET_CASE_TYPE_CURRENT_CASES_FILTER:
			return {
				...state,
				caseTypeFilter: action.caseTypeFilter
			}
		case types.SET_SORT_DIRECTION_CURRENT_CASES_FILTER:
			return {
				...state,
				sortDirection: action.sortDirection
			}
		case types.CLEAR_CURRENT_CASES_FILTER:
			return {
				...state,
				searchString: initialState.currentCasesFilter.searchString,
				caseTypeFilter: initialState.currentCasesFilter.caseTypeFilter
			}
		default:
			return state;
	}
} 