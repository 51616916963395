import { baseGetAll, basePost, basePostImageFile, baseDel, baseGetImageFile } from "./apiutils";

export async function getSettings() {
  const response = await baseGetAll("/admin/settings");
  return Promise.resolve(response);
}

export async function saveSettings(settings) {
  const response = await basePost(settings, "/admin/settings", true);
  return Promise.resolve(response);
}

export async function saveLogotypeFile(logotype) {
  let formData = new FormData();
  formData.append("uploadFile", logotype);
  const response = await basePostImageFile(formData, "/admin/logotype", true);
  return Promise.resolve(response);
}

export async function getLogotypeFile() {
  const response = await baseGetImageFile("/admin/logotype");
  return Promise.resolve(response);
}

export async function getUsers() {
  const response = await baseGetAll("/admin/users");
  return Promise.resolve(response);
}

export async function createUser(user) {
  const response = await basePost(user, "/admin/user", true);
  return Promise.resolve(response);
}

export async function editUser(user) {
  const response = await basePost(user, "/admin/edituser", true);
  return Promise.resolve(response);
}

export async function deleteUser(userId) {
  const response = await baseDel(userId, "/admin/user/");
  return Promise.resolve(response);
}
