import React from 'react';
import './geometryrow.scss'


export default function GeometryRow(props) {
  return (
    <div className={'case-info-row-wrapper'} >
      {props.geometries.map(g =>
        <div
          className="case-info-row-item-wrapper"
          style={{ flexBasis: `${100 / props.geometries.length}%` }}
          key={g.value}
        >
          <span>{g.key}</span>
          <span>{g.value}</span>
        </div>
      )}
    </div>
  )
}
