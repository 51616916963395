import React, { useState, useEffect, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import TextInput from "components/common/textinput";
import SpinnerButton from "components/common/spinnerbutton";
import * as adminActions from "redux/actions/adminactions";

function Buffer(props) {
  const [materialBuffer, setMaterialBuffer] = useState(100);
  const [networkMaskingBuffer, setNetworkMaskingBuffer] = useState(100);
  const [savingSettings, setSavingSettings] = useState(false);
  const { actions } = props;

  useEffect(() => {
    if (props.bufferSettings) {
      setMaterialBuffer(props.bufferSettings.materialBuffer);
      setNetworkMaskingBuffer(props.bufferSettings.networkMaskingBuffer);
      setSavingSettings(false);
    }
  }, [props.bufferSettings]);

  function handleMaterialBufferChange(event) {
    setMaterialBuffer(event.target.value);
  }

  function handleNetworkMaskingBufferChange(event) {
    setNetworkMaskingBuffer(event.target.value);
  }

  function handleSaveClick() {
    setSavingSettings(true);
    let bufferSettings = { bufferSettings: props.bufferSettings };
    var updatedSettings = JSON.parse(JSON.stringify(bufferSettings));
    updatedSettings.bufferSettings.materialBuffer = materialBuffer;
    updatedSettings.bufferSettings.networkMaskingBuffer = networkMaskingBuffer;
    actions
      .saveSettings(updatedSettings)
      .then(function () {
        toast.success("Inställningarna har sparats.");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Det gick inte att spara inställningarna.");
      })
      .then(() => setSavingSettings(false));
  }

  if (props.bufferSettings) {
    return (
      <Fragment>
        <h4 className="settings-title">Buffertinställningar</h4>

        <TextInput
          name="bufferSizeMap"
          label="Buffertstorlek i meter för kartutsnitt och GIS-filer (bör vara större än Ledningsägarmodulens buffertstorlek)"
          value={materialBuffer.toString()}
          inputType="number"
          onChange={handleMaterialBufferChange}
          placeholder={""}
          error={""}
          inputClass="settings-numerical-input"
          wrapperClass="settings-label"
        />

        <TextInput
          name="bufferSizeMask"
          label="Buffertstorlek i meter för maskning av ledningsnät"
          value={networkMaskingBuffer.toString()}
          inputType="number"
          onChange={handleNetworkMaskingBufferChange}
          placeholder={""}
          error={""}
          inputClass="settings-numerical-input"
          wrapperClass="settings-label"
        />

        <SpinnerButton onClick={handleSaveClick} loading={savingSettings} buttonClassName="button-primary-large">
          Spara
        </SpinnerButton>
      </Fragment>
    );
  } else {
    return <span>Det gick inte att hämta inställningar.</span>;
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveSettings: bindActionCreators(adminActions.saveSettings, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Buffer);
