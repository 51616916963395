import React, { useState, Fragment, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import TextInput from "components/common/textinput";
import Toggler from "components/common/toggler";
import SpinnerButton from "components/common/spinnerbutton";
import * as adminActions from "redux/actions/adminactions";
import { ConflictError, NotFoundError } from "api/responseerrors";
import { validEmailAddress, validPassword } from "utils/validation";
import { UserModel } from "types/usermodel";

type AppProps = {
  actions: any;
  userInfo?: UserModel | null;
  onUpdateUsers: () => void;
};

const CreateUser = ({ actions, userInfo, onUpdateUsers }: AppProps) => {
  const defaultPassword = "********";
  const [name, setName] = useState(userInfo ? userInfo.user.name ?? "" : "");
  const [username, setUsername] = useState(userInfo ? userInfo.user.username ?? "" : "");
  const [email, setEmail] = useState(userInfo ? userInfo.user.email ?? "" : "");
  const [password, setPassword] = useState(userInfo ? defaultPassword : "");
  const [repeatPassword, setRepeatPassword] = useState(userInfo ? defaultPassword : "");
  const [administrator, setAdministrator] = useState(userInfo ? userInfo.isAdministrator : false);
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] = useState("");
  const [savingUser, setSavingUser] = useState(false);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    if (event.target.value !== "") {
      setNameErrorMessage("");
    }
  };

  const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    if (event.target.value !== "") {
      setUsernameErrorMessage("");
    }
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (event.target.value !== "") {
      setEmailErrorMessage("");
    }
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (event.target.value !== "") {
      setPasswordErrorMessage("");
    }
  };

  const onPasswordFieldFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.value = "";
  };

  const onPasswordFieldFocusOut = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      event.target.value = password;
    }
  };

  const onRepeatPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(event.target.value);
    if (event.target.value !== "") {
      setRepeatPasswordErrorMessage("");
    }
  };

  const onRepeatPasswordFieldFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.value = "";
  };

  const onRepeatPasswordFieldFocusOut = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      event.target.value = repeatPassword;
    }
  };

  const onAdministratorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdministrator(event.target.checked);
  };

  const clearInputFields = () => {
    setName("");
    setUsername("");
    setEmail("");
    setPassword("");
    setRepeatPassword("");
    setAdministrator(false);
  };

  const validateInputFields = () => {
    let isValid = true;

    if (name === "") {
      setNameErrorMessage("Namn måste anges.");
      isValid = false;
    }

    if (username === "") {
      setUsernameErrorMessage("Användarnamn måste anges.");
      isValid = false;
    }

    if (email === "") {
      setEmailErrorMessage("E-postadress måste anges.");
      isValid = false;
    } else if (!validEmailAddress(email)) {
      setEmailErrorMessage("E-postadressen är inte giltig.");
      isValid = false;
    }

    // Valid password:
    // - Min 8 characters
    // - Min 1 symbol
    // - Upper and lower case letters
    // - Min 1 number
    if (password === "") {
      setPasswordErrorMessage("Lösenord måste anges.");
      isValid = false;
    } else if (password !== defaultPassword && !validPassword(password)) {
      // If password === defaultPassword, then the password wasn't changed and we don't need to test it.
      setPasswordErrorMessage("Lösenordet är inte giltig.");
      isValid = false;
    }
    if (repeatPassword === "") {
      setRepeatPasswordErrorMessage("Lösenord måste anges.");
      isValid = false;
    }
    if (password !== repeatPassword) {
      setPasswordErrorMessage("Lösenorden stämmer inte överens.");
      setRepeatPasswordErrorMessage("Lösenorden stämmer inte överens.");
      isValid = false;
    }

    return isValid;
  };

  const handleSaveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (validateInputFields()) {
      setSavingUser(true);

      const newUserInfo: UserModel = {
        user: {
          id: userInfo ? userInfo.user.id : -1,
          username: username,
          passwordHash: password !== defaultPassword ? password : null, // Password will be hashed server side.
          name: name,
          email: email,
          roles: userInfo ? userInfo.user.roles : new Array(0),
        },
        isAdministrator: administrator,
      };

      if (userInfo) {
        actions
          .editUser(newUserInfo)
          .then(() => {
            onUpdateUsers(); // Callback function to settings to redirect to the user list after creating user.
            toast.success(`Användaren '${username}' har uppdaterats.`);
          })
          .catch((error: Error) => {
            console.error(error);
            if (error instanceof NotFoundError) {
              toast.error(`Användaren '${username}' saknas.`);
            } else {
              toast.error(`Det gick inte att uppdatera användaren '${username}'`);
            }
          });
      } else {
        actions
          .createUser(newUserInfo)
          .then(() => {
            onUpdateUsers(); // Callback function to settings to redirect to the user list after creating user.
            toast.success(`Användaren '${username}' har skapats.`);
          })
          .catch((error: Error) => {
            console.error(error);

            if (error instanceof ConflictError) {
              toast.error(`Användarnamnet '${username}' är upptaget.`);
            } else {
              toast.error(`Det gick inte att skapa användaren '${username}'`);
            }
          });
      }
    } else {
      toast.error("Vänligen fyll i samtliga fält korrekt.");
    }
    setSavingUser(false);
  };

  useEffect(() => {
    if (!userInfo) {
      clearInputFields();
    }
    setSavingUser(false);
  }, [userInfo]);

  return (
    <Fragment>
      <h4 className="settings-title">{userInfo ? "Redigera användare" : "Lägg till ny användare"}</h4>
      <TextInput
        name="username"
        label="Användarnamn"
        value={username}
        onChange={onUsernameChange}
        placeholder={""}
        error={usernameErrorMessage}
        wrapperClass="settings-label"
        disabled={userInfo ? true : false}
        autocomplete="off"
      />
      <TextInput
        name="name"
        label="Namn"
        value={name}
        onChange={onNameChange}
        placeholder={""}
        error={nameErrorMessage}
        wrapperClass="settings-label"
        autocomplete="off"
      />
      <TextInput
        name="email"
        label="E-post"
        value={email}
        onChange={onEmailChange}
        placeholder={""}
        error={emailErrorMessage}
        wrapperClass="settings-label"
        autocomplete="off"
      />
      <div className="settings-help-text password-help-text">
        <FaInfoCircle />
        <div>
          <span className="settings-help-text-header">Giltigt lösenord:</span>
          <ul>
            <li>Minst 8 tecken</li>
            <li>Minst 1 specialtecken</li>
            <li>Gemener och versaler</li>
            <li>Minst en siffra</li>
          </ul>
        </div>
      </div>
      <TextInput
        name="password"
        inputType="password"
        label="Lösenord"
        value={password}
        onChange={onPasswordChange}
        onClick={onPasswordFieldFocus}
        onBlur={onPasswordFieldFocusOut}
        placeholder={""}
        error={passwordErrorMessage}
        wrapperClass="settings-label create-user-password-field"
        autocomplete="new-password"
      />
      <TextInput
        name="repeatPassword"
        inputType="password"
        label="Upprepa lösenord"
        value={repeatPassword}
        onChange={onRepeatPasswordChange}
        onClick={onRepeatPasswordFieldFocus}
        onBlur={onRepeatPasswordFieldFocusOut}
        placeholder={""}
        error={repeatPasswordErrorMessage}
        wrapperClass="settings-label"
        autocomplete="new-password"
      />
      <div className="settings-label">
        <Toggler label="Administratör" checked={administrator} onChange={onAdministratorChange} />
      </div>
      <SpinnerButton onClick={handleSaveClick} loading={savingUser} buttonClassName="button-primary-large">
        {userInfo ? "Spara" : "Skapa användare"}
      </SpinnerButton>
    </Fragment>
  );
};

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: {
      createUser: bindActionCreators(adminActions.createUser, dispatch),
      editUser: bindActionCreators(adminActions.editUser, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
