import React, { useEffect, useRef, useState } from "react";
import parse from 'html-react-parser';
import { MdAttachFile } from "react-icons/md";
import { FaFile, FaInfoCircle } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "./sendmaterialbyemail.scss";
import EmailField from "components/common/emailfield";
import { getFile } from "redux/actions/caseactions";
import AttachmentValidation from "utils/attachmentvalidation";
import { getFileExtension, getFileNameWithoutExtension } from "utils/filenameutil";
import { validEmailAddress, validHTML } from "utils/validation";
import { getEmailReplyDiv } from "utils/domutil";

export default function SendMaterialByEmail(props) {
  const fileInput = useRef(null);
  const [fileValidationInfoHeader, setFileValidationInfoHeader] = useState("");
  const [fileValidationInfo, setFileValidationInfo] = useState("");
  const [emailToErrorMessage, setEmailToErrorMessage] = useState("");
  const [emailCcErrorMessage, setEmailCcErrorMessage] = useState("");

  useEffect(() => {
    const validationInfo = new AttachmentValidation(props.attachmentSettings);
    let validationInfoIndex = 0;
    setFileValidationInfoHeader(validationInfo.fileValidationInfo().header);
    const validationInfoList = validationInfo
      .fileValidationInfo().validationInfo
      .map((str) => <li key={validationInfoIndex++}>{str}</li>);
    setFileValidationInfo(validationInfoList);
  }, []);

  const clearFileInput = () => {
    fileInput.current.value = null;
    props.handleManualAttachmentsChanged([]);
  };

  const attachFiles = (e) => {
    if (e.target.files.length === 0) return; //user Canceled dialog

    const manuallyAttachedFiles = Array.from(e.target.files);
    props.handleManualAttachmentsChanged(manuallyAttachedFiles);
  };

  const handleAttachmentClick = (fileName) => {
    const downloadFile = getFile(getFileNameWithoutExtension(fileName), getFileExtension(fileName));

    downloadFile.catch(() => {
      toast.error(`Filen ${fileName} kunde inte hämtas`);
    });
  };

  const handleSendFilesChanged = (e) => {
    props.handleSendFilesChanged[Number(e.target.id)](e.target.checked);
  };

  const checkShouldSelectFile = (fileName, isPrivateUser, isManualMarkEmail) => {
    var shouldSelectFile =
      fileName.includes(".pdf") || (fileName.includes(".dxf") && (!isPrivateUser || isManualMarkEmail));
    return shouldSelectFile;
  };

  const handleEmailToChange = (e) => {
    setEmailToErrorMessage("");
    props.handleEmailToChange(e);
  }

  const handleEmailCcChange = (e) => {
    setEmailCcErrorMessage("");
    props.handleEmailCcChange(e);
  }

  const validateInputFields = (e) => {
    let isValid = true;

    if (props.emailTo === "") {
      setEmailToErrorMessage("Mottagarens e-postadress måste anges.");
      isValid = false;
    } else if (!validEmailAddress(props.emailTo)) {
      setEmailToErrorMessage("Mottagarens e-postadress är inte giltig.");
      isValid = false;
    }

    if (props.emailCc && !validEmailAddress(props.emailCc)) {
      setEmailCcErrorMessage("Mottagarens e-postadress är inte giltig.");
      isValid = false;
    }

    if (isValid) {
      props.handleSendMaterialNextClick(e);
    }
  };

  const renderEmailMessage = (emailMessage, emailMessageIsHtml) => {
    if (emailMessageIsHtml) {
      let isValidHtml = validHTML(emailMessage)
      let className = "case-acts-send-material-text case-acts-send-material-html";
      if (!isValidHtml) {
        className += " case-acts-send-material-html-invalid-input-border"
      }

      return (
        <div className={className}>
          {validHTML(emailMessage) ? (
            parse(getEmailReplyDiv(emailMessage))
          ) : (
            <span className="case-acts-send-material-html-invalid-input">Ogiltig HTML</span>
          )}
        </div>
      );
    }

    return (
      <textarea
        className="case-acts-send-material-text"
        value={props.emailMessage}
        onChange={props.handleEmailMessageChange}
      />
    );
  };

  return (
    <div className="case-acts-send-material-wrapper">
      <div className="case-acts-send-material-content">
        {props.isManualMarkEmail && props.isArchived ? (
          <div className="info-box-wrapper info-box-wrapper-width">
            <p className="info-box-header">Skicka arkiverat ärende för utsättning</p>
            <p>
              Notera att när ett arkiverat ärende (besvarat i Ledningskollen) skickats för utsättning kommer inte statusen i Ledningskollen uppdateras.
            </p>
          </div>
        ) : null }
        <span className="case-acts-send-material-header">
          {props.isArchived ? (props.isManualMarkEmail ? "Skicka för utsättning" : "Skicka nytt svar") : "Skicka svar"}
        </span>

        {props.attachedFiles.length === 0 && <span className="case-acts-send-material-no-attachments">-</span>}

        <EmailField 
          label="Till" 
          value={props.emailTo} 
          error={emailToErrorMessage}
          onChange={handleEmailToChange} 
        />
        <EmailField
          label="Cc"
          value={props.emailCc ?? ""}
          error={emailCcErrorMessage}
          onChange={handleEmailCcChange}
        />
        <EmailField 
          label="Från" 
          value={props.emailFrom} 
          disabled={true} 
        />
        <EmailField
          label="Ämne"
          value={props.emailSubject}
          onChange={props.handleEmailSubjectChange}
        />

        <span className="case-acts-send-material-check-label">Bifogade filer</span>
        {props.attachedFiles.map((f, index) => (
          <div className="case-acts-send-material-check-field-wrapper" key={index}>
            <input
              type="checkbox"
              id={index}
              name={f.name}
              value={f.name}
              defaultChecked={checkShouldSelectFile(f.name, props.isPrivateUser, props.isManualMarkEmail)}
              onChange={handleSendFilesChanged}
            />
            <FaFile />
            <Button
              variant="link"
              className="button-link case-file-info-item"
              href="#"
              onClick={() => handleAttachmentClick(f.name)}
            >
              {f.name}
            </Button>
          </div>
        ))}
        {props.attachedFiles.length === 0 && <span className="case-acts-send-material-no-attachments">-</span>}

        <span className="case-acts-send-material-check-label">Egna bifogade filer</span>
        {props.manualAttachedFiles.map((f, index) => (
          <div className="case-acts-send-material-check-field-wrapper" key={index}>
            <FaFile />
            <Button
              variant="link"
              className={
                props.isArchived
                  ? props.readOnly
                    ? "button-link case-file-info-item"
                    : "button-link-disabled case-file-info-item"
                  : "button-link-disabled case-file-info-item"
              }
              href="#"
              onClick={() => handleAttachmentClick(f.name)}
            >
              {f.name}
            </Button>
          </div>
        ))}
        {props.manualAttachedFiles.length === 0 && <span className="case-acts-send-material-no-attachments">-</span>}
        <div className="file-validation-info">
          <FaInfoCircle />
          <div>
            <span className="file-validation-info-header">{fileValidationInfoHeader}</span>
            <ul>{fileValidationInfo}</ul>
          </div>
        </div>

        <input
          type="file"
          id="file"
          ref={fileInput}
          style={{ display: "none" }}
          accept={props.attachmentSettings.allowedFileTypes}
          onChange={(e) => attachFiles(e)}
          onClick={(e) => {
            e.target.value = null;
          }}
          multiple
        />
        <div className="case-acts-send-material-buttons-wrapper">
          <button
            className="button-secondary case-acts-send-material-clear-button button-row-margin"
            onClick={(e) => fileInput.current && clearFileInput()}
          >
            Rensa filer
          </button>
          <button
            className="button-primary case-acts-send-material-attach-button button-row-margin"
            onClick={(e) => fileInput.current && fileInput.current.click()}
          >
            <MdAttachFile />
            Bifoga filer
          </button>
        </div>

        <span className="case-acts-send-material-label">Meddelande</span>
        {renderEmailMessage(props.emailMessage, props.emailMessageIsHtml)}

        <div className="case-acts-send-material-buttons-wrapper">
          <button className="button-secondary button-row-margin" onClick={props.handleSendMaterialCancelClick}>
            Avbryt
          </button>
          <button className="button-primary button-row-margin" onClick={validateInputFields}>
            Skicka
          </button>
        </div>
      </div>
    </div>
  );
}
