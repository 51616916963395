import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FaQuestionCircle, FaCheck } from "react-icons/fa";
import LoginLK from './loginlk/loginlk';
import AutoAnswer from './autoanswer/autoanswer';
import CaseMaterial from './casematerial/casematerial';
import GDPR from './gdpr/gdpr';
import Email from './email/email';
import ContactInfo from './contactinfo/contactinfo';
//import CoverageArea from './coveragearea/coveragearea';
import Buffer from './buffer/buffer';
import Users from './users/users';
import CreateUser from './createuser/createuser';
import Spinner from 'components/common/spinner';
import * as adminActions from 'redux/actions/adminactions';
import './settings.scss';
import { SettingsType } from 'types/settings';
import { UserModel } from 'types/usermodel';

type AppProps = {
  actions: any, 
  settings: SettingsType
}

const Settings = ({actions, settings} : AppProps) => {
  const [currentSettings, setCurrentSettings] = useState('');
  const [editUser, setEditUser] = useState<UserModel | null>(null);
  const [calculatedHeight, setCalculatedHeight] = useState('');

  useEffect(() => {
    
    actions.loadSettings()
      .then(setCurrentSettings('loginlk'))
      .catch((error: Error) => {
        console.error(error);
        toast.error('Det gick inte att hämta inställningar.');
    });

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      //Cleanup
      window.removeEventListener("resize", handleResize);
    }
  }, [actions])

  function handleResize(): number | undefined {
    const nav = document.getElementsByClassName('flex-container')[0];
    if (!nav) return;
    const h = nav.getBoundingClientRect().height;
    const wh = window.innerHeight;
    setCalculatedHeight((wh - h) + 'px');
  }

  function handleEditUserClick(user:UserModel) {
    setEditUser(user);
    setCurrentSettings('edituser');
  }

  function handleUpdateUsersList() {
    setCurrentSettings('users');
  }
  
  function getRelevantComponent() {
    switch (currentSettings) {
      case 'loginlk':
        return <LoginLK ledningskollenSettings={settings.ledningskollenSettings} />
      case 'autoanswer':
        return <AutoAnswer autoAnswerSettings={settings.autoAnswerSettings} />
      case 'casematerial':
        return <CaseMaterial caseMaterialSettings={settings.caseMaterialSettings} />
      case 'gdpr':
        return <GDPR gdprSettings={settings.gdprSettings} />
      case 'email':
        return <Email emailSettings={settings.emailSettings} />
      case 'contact':
        return <ContactInfo contactInformationSettings={settings.contactInformationSettings} />
      // case 'coveragearea':
      //   return <CoverageArea coverageAreasSettings={settings.coverageAreasSettings} />
      case 'buffer':
        return <Buffer bufferSettings={settings.bufferSettings} />
      case 'users':
        return <Users onEditUser={handleEditUserClick} />
      case 'createuser':
        return <CreateUser onUpdateUsers={handleUpdateUsersList} />
      case 'edituser':
        return <CreateUser userInfo={editUser} onUpdateUsers={handleUpdateUsersList} />
      case '':
        return <Spinner />
      default:
        break;
    }
  }

  return (
    <div className='settings-wrapper'>
      <div className='settings-left-column-wrapper'>
        <span className='label'>Systeminställningar</span>
        <div
          className={currentSettings === 'loginlk' ? 'item active' : 'item'}
          onClick={e => setCurrentSettings('loginlk')}>
          <FaCheck />
          <span>Inloggning Ledningskollen</span>
        </div>

        <div
          className={currentSettings === 'autoanswer' ? 'item active' : 'item'}
          onClick={e => setCurrentSettings('autoanswer')}>
          <FaCheck />
          <span>Autosvar</span>
        </div>

        <div
          className={currentSettings === 'casematerial' ? 'item active' : 'item'}
          onClick={e => setCurrentSettings('casematerial')}>
          <FaCheck />
          <span>Underlag</span>
        </div>

        <div
          className={currentSettings === 'gdpr' ? 'item active' : 'item'}
          onClick={e => setCurrentSettings('gdpr')}>
          <FaCheck />
          <span>GDPR</span>
        </div>

        <div
          className={currentSettings === 'email' ? 'item active' : 'item'}
          onClick={e => setCurrentSettings('email')}>
          <FaCheck />
          <span>E-postinställningar</span>
        </div>

        <div
          className={currentSettings === 'contact' ? 'item active' : 'item'}
          onClick={e => setCurrentSettings('contact')}>
          <FaCheck />
          <span>Kontaktinformation</span>
        </div>

        {/* <div
          className={currentSettings === 'coveragearea' ? 'item active' : 'item'}
          onClick={e => setCurrentSettings('coveragearea')}>
          <FaCheck />
          <span>Bevakningsområden</span>
        </div> */}

        <div
          className={currentSettings === 'buffer' ? 'item active' : 'item'}
          onClick={e => setCurrentSettings('buffer')}>
          <FaCheck />
          <span>Buffertinställningar</span>
        </div>

        <span className='label'>Användaradministration</span>

        <span
          className={currentSettings === 'users' ? 'item active' : 'item'}
          onClick={e => setCurrentSettings('users')}
        >
          Användare
        </span>

        <span
          className={currentSettings === 'createuser' ? 'item active' : 'item'}
          onClick={e => setCurrentSettings('createuser')}
        >
          Lägg till ny användare
        </span>

      </div>

      <div
        className='settings-middle-column-wrapper'
        // ref={wrapperEl}
        style={{ height: calculatedHeight }}
      >
        <div className='settings-middle-column-content'>
          {getRelevantComponent()}
        </div>
      </div>

      <div className='settings-right-column-wrapper'>
        <FaQuestionCircle className='settings-question-mark' />
        <p className='settings-contact-info'>
          Här hittar du alla inställningar till InCheck. Om du behöver mer hjälp vänligen kontakta Decerno Support
        </p>
        <span className='settings-contact-info'>
          <a href="mailto:support@decerno.se">support@decerno.se</a>
        </span>
      </div>
    </div >
  );

};

function mapStateToProps(state: any) {
  return {
    settings: state.settings
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: {
      loadSettings: bindActionCreators(adminActions.loadSettings, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
