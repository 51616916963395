export type CaseMaterialEmail = {
  id: number;
  rcptTo: string;
  cc: string;
  caseNumber: string;
  mailFrom: string;
  mailFromDisplay: string;
  mailSubject: string;
  mailBody: string;
  mailBodyIsHtml: boolean;
  measureType: InCheckMeasureType;
  attachment: string | null;
  attachmentFilename: string | null;
  caseEmailReceiverType: CaseEmailReceiverType;
  sentDate: Date;
  fileArchive: string;
  caseMaterialEmailAttachments: CaseMaterialEmailAttachment[];
  answererName: string;
  answererLogin: string;
  //answerer: User;
};

export const enum CaseEmailReceiverType {
  MANUAL_MARK_RESOURCE = 0,
  INQUIRER = 1,
  OVERRIDE_MANUAL_MARK_RESOURCE = 2,
  OVERRIDE_INQUIRER = 3,
}

export const enum InCheckMeasureType {
  Resend = "ResendEmail",
  ResendManualMark = "ResendManualMarkEmail"
}

export type CaseMaterialEmailAttachment = {
  id: number;
  caseMaterialEmailId: number;
  fileName: string;
  fileType: string;
  file: number[];
  isManuallyAttached: boolean;
};