import React from 'react'
import './emailfield.scss';

type AppProps = {
  label: string,
  value: string,
  placeholder: string,
  width: number,
  disabled: boolean,
  error: string,
  onChange(): void
}

const EmailField = (props: AppProps) => {
  return (
    <div style={{ width: props.width || "100%" }}>
      <div className="email-field-wrapper">
        <div className="email-field-icon-wrapper">
          <span className="email-field-icon">{props.label}</span>
        </div>
        <input
          type="text"
          placeholder={props.placeholder}
          className="email-field"
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      </div>
      {props.error && <div className="invalid-input">{props.error}</div>}
    </div>
  );
};

export default EmailField;
