import { basePost, baseDel } from "./apiutils";
import { storeUser, removeUser } from "utils/auth";

export async function logIn(username, password) {
  const response = await basePost({ Username: username, Password: password }, "/auth/login", false);

  if (response.success) {
    storeUser(username, response.token, response.expiry, response.administrator);
    return true;
  }
  return false;
}

export async function logOut() {
  const response = await baseDel("", "/auth/logout", false);
  return Promise.resolve(response).then(removeUser());
}
